import { useField } from 'formik';

import FormField from '../FormField';
import Label from '../Label';
import Input from '../Input';
import ErrorText from '../ErrorText';

function InputField(props) {
  const { label, noMargin, ...inputProps } = props;
  const [field, meta] = useField(props);
  const hasError = Boolean(meta.touched && meta.error);

  return (
    <FormField noMargin={noMargin}>
      {label && (
        <Label htmlFor={inputProps.id || inputProps.name}>{label}</Label>
      )}
      <Input {...field} {...inputProps} error={hasError} />
      {hasError ? <ErrorText>{meta.error}</ErrorText> : null}
    </FormField>
  );
}

export default InputField;
