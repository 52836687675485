import { useState, useEffect, useCallback } from 'react';
import { Formik, Form, useField, FieldArray } from 'formik';
import * as Yup from 'yup';
import YupCustom from '../../utils/validations';

import PageHeader from '../common/PageHeader';
import Tab from '../common/Tab';
import Select from '../common/Select';
import Input from '../common/Input';
import InputField from '../common/InputField';
import DateField from '../common/DateField';
import ToggleField from '../common/ToggleField';
import Checkbox from '../common/Checkbox';
import ErrorText from '../common/ErrorText';
import Button from '../common/Button';
import IconButton from '../common/IconButton';
import ConfirmDialog from '../common/ConfirmDialog';
import Modal from '../common/Modal';
import ModalContent from '../common/ModalContent';
import ModalActions from '../common/ModalActions';
import Label from '../common/Label';
import Alert from '../common/Alert';
import Snackbar from '../common/Snackbar';
import { List, ListItem, ListItemText, ListItemActions } from '../common/List';

import EditIcon from '../../icons/Edit';
import AddIcon from '../../icons/Add';
import TrashIcon from '../../icons/Trash';

import { parseRequestError } from '../../utils/errors';

import * as apiClient from '../../apiClient';

const EVENT_TYPES = {
  instant: 1,
  audiopack: 5,
  content: 10,
  shift: [2, 3],
  streaming: 4,
};

const WEEK_DAYS = {
  1: 'Dom',
  2: 'Seg',
  3: 'Ter',
  4: 'Qua',
  5: 'Qui',
  6: 'Sex',
  7: 'Sáb',
};

const tabs = [
  { label: 'Chamadas Instantâneas', type: 'instant' },
  { label: 'AudioPack', type: 'audiopack' },
  { label: 'Conteúdos', type: 'content' },
  { label: 'Expediente', type: 'shift' },
  { label: 'Streaming', type: 'streaming' },
];

function getSelectedOptionLabel(options, value) {
  const selectedValue =
    options.find((opt) => opt.id.toString() === value) || {};
  return selectedValue.name || '';
}

function Events() {
  const [activeType, setActiveType] = useState(tabs[0].type);
  const [options, setOptions] = useState([]);
  const [events, setEvents] = useState([]);

  const [isScheduleOpen, setIsScheduleOpen] = useState(false);
  const [instantId, setInstantId] = useState('');
  const [audioPackId, setAudioPackId] = useState('');
  const [contentId, setContentId] = useState('');
  const [shiftId, setShiftId] = useState('');
  const [streamingUrl, setStreamingUrl] = useState('');

  const [isEdit, setIsEdit] = useState(false);
  const [eventTitle, setEventTitle] = useState();
  const [eventFieldTitle, setEventFieldTitle] = useState();
  const [eventFormInitialValues, setEventFormInitialValues] = useState({});

  const [submitSuccess, setSubmitSuccess] = useState(null);

  const handleClickTab = (tabId) => () => {
    setActiveType(tabId);
  };

  const handleChangeInstant = (e) => {
    const value = e.target.value;
    setInstantId(value || '');
  };

  const handleChangeAudioPack = (e) => {
    const value = e.target.value;
    setAudioPackId(value || '');
  };

  const handleChangeContent = (e) => {
    const value = e.target.value;
    setContentId(value || '');
  };

  const handleChangeShift = (e) => {
    const value = e.target.value;
    setShiftId(value || '');
  };

  const handleChangeStreamingUrl = (e) => {
    const value = e.target.value;
    setStreamingUrl(value);
  };

  const handleClickSchedule = () => {
    setIsScheduleOpen(true);
    setIsEdit(false);

    const activeEventType = EVENT_TYPES[activeType];
    let eventType = '';
    if (Array.isArray(activeEventType)) {
      eventType = shiftId;
    } else {
      eventType = activeEventType;
    }

    const initialValues = {
      type: eventType,
      times: [''],
      weekDays: ['1', '2', '3', '4', '5', '6', '7'],
      waitCurrent: false,
    };

    if (activeType === 'instant') {
      initialValues.audioId = instantId;
      setEventFieldTitle('Áudio');
      setEventTitle(getSelectedOptionLabel(options, instantId));
    } else if (activeType === 'audiopack') {
      initialValues.audioId = audioPackId;
      setEventFieldTitle('Conteúdo');
      setEventTitle(getSelectedOptionLabel(options, audioPackId));
    } else if (activeType === 'content') {
      initialValues.audioId = contentId;
      setEventFieldTitle('Conteúdo');
      setEventTitle(getSelectedOptionLabel(options, contentId));
    } else if (activeType === 'streaming') {
      initialValues.urlStreaming = streamingUrl;
      setEventFieldTitle('IP Streaming');
      setEventTitle(streamingUrl);
    } else if (activeType === 'shift') {
      setEventFieldTitle('Ação');
      setEventTitle(getSelectedOptionLabel(options, shiftId));
    }

    setEventFormInitialValues(initialValues);
  };

  const handleCloseEventModal = () => {
    setIsScheduleOpen(false);
  };

  const handleCloseSnackbar = () => {
    setSubmitSuccess(null);
  };

  const loadInstantOptions = async () => {
    const response = await apiClient.getInstantFiles();
    setOptions(
      response.items.map((file) => ({ id: file.id, name: file.file })),
    );
  };

  const loadAudioPackOptions = async () => {
    const response = await apiClient.getContentFolders({ type: 'audiopack' });
    setOptions(
      response.items.map((folder) => ({ id: folder.id, name: folder.name })),
    );
  };

  const loadContentOptions = async () => {
    const response = await apiClient.getContentFolders({ type: 'content' });
    setOptions(
      response.items.map((folder) => ({ id: folder.id, name: folder.name })),
    );
  };

  const loadEvents = useCallback(async () => {
    const eventType = EVENT_TYPES[activeType];
    const params = {};
    if (Array.isArray(eventType)) {
      params.types = eventType.join(',');
    } else {
      params.type = eventType;
    }

    const response = await apiClient.getEvents(params);
    setEvents(response.items);
  }, [activeType]);

  const handleEventSave = (hasError) => {
    setSubmitSuccess(
      hasError
        ? 'Não foi possível salvar o evento.'
        : 'Evento salvo com sucesso.',
    );
    loadEvents();
  };

  const handleRemoveEvent = (eventId) => async () => {
    try {
      await apiClient.deleteEvent(eventId);
      setSubmitSuccess('Evento removido com sucesso.');
    } catch (error) {
      setSubmitSuccess('Não foi possível remover o evento.');
    }

    loadEvents();
  };

  const handleEditEvent = (event) => () => {
    setIsScheduleOpen(true);
    setIsEdit(true);

    const initialValues = {
      id: event.id,
      type: event.type,
      times: [event.time],
      dateStart: event.dateStart,
      dateEnd: event.dateEnd,
      weekDays: event.weekDays.map((weekDay) => weekDay.toString()),
      waitCurrent: event.waitCurrent,
      status: event.status,
    };

    if (activeType === 'instant') {
      setEventFieldTitle('Áudio');
    } else if (activeType === 'audiopack') {
      setEventFieldTitle('Conteúdo');
    } else if (activeType === 'content') {
      setEventFieldTitle('Conteúdo');
    } else if (activeType === 'streaming') {
      setEventFieldTitle('IP Streaming');
      initialValues.urlStreaming = event.streamingUrl;
      initialValues.duration = event.duration;
    } else if (activeType === 'shift') {
      setEventFieldTitle('Ação');
    }

    setEventTitle(event.title);
    setEventFormInitialValues(initialValues);
  };

  useEffect(() => {
    if (activeType === 'instant') {
      loadInstantOptions();
    } else if (activeType === 'audiopack') {
      loadAudioPackOptions();
    } else if (activeType === 'content') {
      loadContentOptions();
    } else if (activeType === 'shift') {
      setOptions([
        { id: '2', name: 'Play automático' },
        { id: '3', name: 'Stop automático' },
      ]);
    }
    loadEvents();
  }, [activeType, loadEvents]);

  return (
    <div>
      <Snackbar open={Boolean(submitSuccess)} onClose={handleCloseSnackbar}>
        <Alert type="success" className="shadow-md">
          {submitSuccess}
        </Alert>
      </Snackbar>
      <PageHeader title="Eventos" />
      <div className="h-full p-6 md:px-10 md:pb-10 md:pt-0">
        <nav>
          {tabs.map((tab) => (
            <Tab
              key={tab.type}
              active={activeType === tab.type}
              onClick={handleClickTab(tab.type)}
            >
              {tab.label}
            </Tab>
          ))}
        </nav>
        {activeType === 'instant' && (
          <section className="py-6">
            <div className="flex justify-between">
              <Select onChange={handleChangeInstant} value={instantId}>
                <option value="" disabled>
                  Selecione uma chamada instantânea...
                </option>
                {options.map((option) => (
                  <option key={`instant_${option.id}`} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Select>
              <Button
                color="primary"
                className="ml-5"
                disabled={!instantId}
                onClick={handleClickSchedule}
              >
                Agendar
              </Button>
            </div>
          </section>
        )}
        {activeType === 'audiopack' && (
          <section className="py-6">
            <div className="flex justify-between">
              <Select onChange={handleChangeAudioPack} value={audioPackId}>
                <option value="" disabled>
                  Selecione uma categoria de AudioPack...
                </option>
                {options.map((option) => (
                  <option key={`audiopack_${option.id}`} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Select>
              <Button
                color="primary"
                className="ml-5"
                disabled={!audioPackId}
                onClick={handleClickSchedule}
              >
                Agendar
              </Button>
            </div>
          </section>
        )}
        {activeType === 'content' && (
          <section className="py-6">
            <div className="flex justify-between">
              <Select onChange={handleChangeContent} value={contentId}>
                <option value="" disabled>
                  Selecione uma pasta de conteúdos...
                </option>
                {options.map((option) => (
                  <option key={`content_${option.id}`} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Select>
              <Button
                color="primary"
                className="ml-5"
                disabled={!contentId}
                onClick={handleClickSchedule}
              >
                Agendar
              </Button>
            </div>
          </section>
        )}
        {activeType === 'shift' && (
          <section className="py-6">
            <div className="flex justify-between">
              <Select onChange={handleChangeShift} value={shiftId}>
                <option value="" disabled>
                  Selecione a ação que deseja agendar...
                </option>
                {options.map((option) => (
                  <option key={`shift_${option.id}`} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Select>
              <Button
                color="primary"
                className="ml-5"
                disabled={!shiftId}
                onClick={handleClickSchedule}
              >
                Agendar
              </Button>
            </div>
          </section>
        )}
        {activeType === 'streaming' && (
          <section className="py-6">
            <div className="flex justify-between">
              <Input
                name="streamingUrl"
                placeholder="IP Streaming. Exemplo: http://IPServidorStreaming:8080"
                onChange={handleChangeStreamingUrl}
                value={streamingUrl}
              />
              <Button
                color="primary"
                className="ml-5"
                disabled={!streamingUrl}
                onClick={handleClickSchedule}
              >
                Agendar
              </Button>
            </div>
          </section>
        )}

        <div className="flex flex-col justify-between pb-5 mt-5 border-b-2 md:flex-row md:items-center border-grayAlt">
          <div className="flex-1 mb-5 md:mb-0">
            <h2 className="text-2xl font-semibold">Eventos agendados</h2>
            <p className="text-gray-400">{events.length} agendados</p>
          </div>
        </div>

        <EventModal
          title={eventTitle}
          titleField={eventFieldTitle}
          open={isScheduleOpen}
          onClose={handleCloseEventModal}
          onSubmit={handleEventSave}
          initialValues={eventFormInitialValues}
          isEdit={isEdit}
        />

        <div className="flex-1 overflow-y-auto">
          {events.length === 0 ? (
            <p className="py-4">Nenhum evento encontrado.</p>
          ) : (
            <List>
              {events.map((event) => {
                return (
                  <ListItem key={`event-${event.id}`} title={event.title}>
                    <div className="flex flex-col flex-1 md:flex-row">
                      <ListItemText className="font-semibold">
                        {event.title}
                      </ListItemText>
                      <ListItemText className="md:px-2">
                        <div>{event.time}</div>
                        <div>
                          (
                          {event.dateStart && event.dateEnd ? (
                            <span>
                              {event.dateStart} até {event.dateEnd}
                            </span>
                          ) : (
                            <span>indefinido</span>
                          )}
                          )
                        </div>
                        <div className="lowercase">
                          {event.weekDays
                            .map((weekDay) => WEEK_DAYS[weekDay])
                            .join(', ')}
                        </div>
                      </ListItemText>
                    </div>
                    <ListItemActions>
                      <IconButton
                        onClick={handleEditEvent(event)}
                        title="Editar evento"
                      >
                        <EditIcon />
                      </IconButton>
                      <ConfirmDialog
                        title="Remover evento"
                        message={
                          <p>
                            Tem certeza que deseja remover o evento{' '}
                            <strong>
                              {event.title} ({event.time}h)
                            </strong>
                            ?
                          </p>
                        }
                        onConfirm={handleRemoveEvent(event.id)}
                      >
                        <IconButton title="Remover evento">
                          <TrashIcon />
                        </IconButton>
                      </ConfirmDialog>
                    </ListItemActions>
                  </ListItem>
                );
              })}
            </List>
          )}
        </div>
      </div>
    </div>
  );
}

function EventModal({
  title,
  titleField,
  open,
  onClose,
  onSubmit,
  initialValues,
  isEdit,
}) {
  const [submitError, setSubmitError] = useState(null);

  const handleSubmit = async (values, { setErrors }) => {
    setSubmitError(null);
    let hasError = false;

    const parsedValues = { ...values };
    parsedValues.weekDays = parsedValues.weekDays
      ? parsedValues.weekDays.map((weekDay) => parseInt(weekDay, 10))
      : parsedValues.weekDays;

    parsedValues.audioId = parsedValues.audioId
      ? parseInt(parsedValues.audioId, 10)
      : parsedValues.audioId;

    parsedValues.type = parsedValues.type
      ? parseInt(parsedValues.type, 10)
      : parsedValues.type;

    parsedValues.duration = parsedValues.duration
      ? parseInt(parsedValues.duration, 10)
      : parsedValues.duration;

    if (isEdit) {
      parsedValues.time = parsedValues.times[0];
      delete parsedValues.times;
    }

    try {
      if (isEdit) {
        const { id: eventId, ...data } = parsedValues;
        await apiClient.updateEvent(eventId, data);
      } else {
        await apiClient.createEvents(parsedValues);
      }

      onClose();
    } catch (error) {
      hasError = true;
      const [errorMessage, errorDetails] = parseRequestError(error);
      if (errorDetails) {
        setErrors(errorDetails);
      } else {
        setSubmitError(errorMessage);
      }
    }

    onSubmit(hasError);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          type: Yup.number()
            .oneOf([1, 2, 3, 4, 5, 10], 'Tipo inválido.')
            .required('Informe o tipo de evento.'),
          weekDays: Yup.array()
            .of(Yup.number().integer().min(1).max(7))
            .default([1, 2, 3, 4, 5, 6, 7]),
          dateStart: YupCustom.date(),
          dateEnd: YupCustom.date().when('dateStart', {
            is: (val) => val,
            then: YupCustom.date()
              .required('Informe a data de fim.')
              .min(
                Yup.ref('dateStart'),
                'Data de fim deve ser maior que data de início.',
              ),
          }),
          times: Yup.array()
            .required('Informe ao menos um horário.')
            .of(YupCustom.time())
            .min(1, 'Informe ao menos um horário.')
            .when('type', {
              is: (val) => [2, 3, 4].includes(val),
              then: Yup.array().max(
                1,
                'Selecione apenas um período de duração.',
              ),
            }),
          waitCurrent: Yup.bool(),
          status: Yup.bool(),
          audioId: Yup.number()
            .integer()
            .when('type', {
              is: (val) => [1, 5, 10].includes(val) && !isEdit,
              then: Yup.number().required('Informe a mídia.'),
            })
            .min(0),
          urlStreaming: Yup.string()
            .url('URL de streaming inválida.')
            .when('type', {
              is: 4 && !isEdit,
              then: Yup.string().required('Informe a URL de streaming.'),
            }),
          duration: Yup.number()
            .integer()
            .when('type', {
              is: 4,
              then: Yup.number().required(
                'Informe o período de duração em segundos.',
              ),
            })
            .min(0, 'Informe o período de duração em segundos.'),
        })}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form noValidate autoComplete="off">
            <ModalContent>
              <div>
                <h2 className="mb-6 text-2xl font-bold">Agendar evento</h2>
                <h3 className="py-3 my-3 text-lg border-b border-gray-600">
                  {titleField}: <strong>{title}</strong>
                </h3>
              </div>
              <WeekDaysField name="weekDays" />

              {!['2', '3'].includes(values.type.toString()) && (
                <div>
                  <Label className="py-3 my-3 border-b border-gray-600">
                    Período
                  </Label>
                  <div className="items-start md:flex">
                    <DateField
                      name="dateStart"
                      placeholder="De"
                      className="md:w-52"
                      noMargin
                      // disabledDays={disabledDays}
                    />
                    <div className="px-3 pt-4">até</div>
                    <DateField
                      name="dateEnd"
                      placeholder="Até"
                      className="md:w-52"
                      noMargin
                      // disabledDays={disabledDays}
                    />
                  </div>
                </div>
              )}

              <TimesField
                name="times"
                times={values.times}
                multiple={
                  !['2', '3', '4'].includes(values.type.toString()) && !isEdit
                }
              />

              {values.type.toString() === '4' && (
                <div>
                  <Label className="py-3 my-3 border-b border-gray-600">
                    Duração
                  </Label>
                  <div className="md:w-52">
                    <InputField
                      name="duration"
                      type="number"
                      placeholder="Segundos"
                    />
                  </div>
                </div>
              )}

              {!['2', '3'].includes(values.type.toString()) && (
                <div className="py-6 pb-5 my-3 border-t border-gray-600">
                  <ToggleField
                    name="waitCurrent"
                    label="Aguardar o áudio em andamento"
                    noMargin
                  />
                </div>
              )}

              {isEdit && (
                <div className="py-6 my-3 border-t border-gray-600">
                  <ToggleField name="status" label="Evento ativo" noMargin />
                </div>
              )}

              <div className="mb-5">
                {submitError ? <Alert type="error">{submitError}</Alert> : null}
              </div>
            </ModalContent>
            <ModalActions>
              <Button onClick={onClose}>Cancelar</Button>
              <Button color="primary" type="submit">
                {isEdit ? 'Alterar' : 'Agendar'}
              </Button>
            </ModalActions>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

function WeekDaysField({ name }) {
  const [field, meta] = useField({ name, type: 'checkbox' });
  const fieldValue = field.value || [];
  const hasError = Boolean(meta.touched && meta.error);

  return (
    <div className="flex" role="group">
      {Object.entries(WEEK_DAYS).map(([weekDayValue, weekDayLabel]) => (
        <div key={weekDayLabel} className="flex-1 text-center">
          <label
            className="font-semibold cursor-pointer"
            title={weekDayLabel}
            alt={weekDayLabel}
          >
            <span className="block mb-2">{weekDayLabel}</span>
            <Checkbox
              {...field}
              value={weekDayValue}
              checked={fieldValue.includes(weekDayValue)}
            />
          </label>
        </div>
      ))}
      {hasError ? <ErrorText>{meta.error}</ErrorText> : null}
    </div>
  );
}

function TimesField({ name, times, multiple }) {
  const field = useField({ name });
  const meta = field[1];
  const hasError = Boolean(
    meta.touched && meta.error && typeof meta.error === 'string',
  );

  return (
    <FieldArray name={name}>
      {(arrayHelpers) => (
        <div>
          <div className="flex py-3 my-3 border-b border-gray-600">
            <Label>Horários</Label>
            {multiple && (
              <button
                type="button"
                className="w-5 ml-3 focus:outline-none"
                onClick={() => arrayHelpers.insert(times.length, '')}
              >
                <AddIcon />
              </button>
            )}
          </div>
          {hasError ? <ErrorText>{meta.error}</ErrorText> : null}
          <div className="md:w-72">
            {times &&
              times.map((_, index) => (
                <div className="flex my-2" key={`time-${index}`}>
                  <div className="flex-1">
                    <InputField
                      key={`time-${index}`}
                      name={`${name}.${index}`}
                      mask="99:99"
                      className="text-center"
                      noMargin
                    />
                  </div>
                  {multiple && (
                    <div className="pt-4 pl-3 md:w-20">
                      <button
                        type="button"
                        className="w-4 focus:outline-none"
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      )}
    </FieldArray>
  );
}

export default Events;
