import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from 'react-router-dom';
import HomePage from './pages/Home';
import SignInPage from './pages/SignIn';
import SignUpPage from './pages/SignUp';
import PlayerPage from './pages/Player';
import RemoteControlPage from './pages/RemoteControl';
import ContactPage from './pages/ContactPage';
import SongRequestPage from './pages/SongRequest';
import NotFoundPage from './pages/NotFound';
import ErrorFallbackPage from './pages/ErrorFallback';
import { useAuth, ProvideAuth } from '../hooks/useAuth';
import useQueryString from '../hooks/useQueryString';

function PrivateRoute({ children, ...rest }) {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const { user, quickAccess } = useAuth();
  const query = useQueryString();
  const quickAccessCode = query.get('r');

  useEffect(() => {
    (async () => {
      if (!quickAccessCode) {
        setLoading(false);
      } else {
        await quickAccess(quickAccessCode);
        setLoading(false);
        query.delete('r');
        history.replace({
          search: query.toString(),
        });
      }
    })();
  }, [quickAccess, quickAccessCode, history, query]);

  if (loading) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallbackPage}>
      <ProvideAuth>
        <Router>
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route path="/login">
              <SignInPage />
            </Route>
            <Route path="/contato">
              <ContactPage />
            </Route>
            <PrivateRoute path="/player">
              <PlayerPage />
            </PrivateRoute>
            <PrivateRoute path="/controle">
              <RemoteControlPage />
            </PrivateRoute>
            <PrivateRoute path="/pedidomusical">
              <SongRequestPage />
            </PrivateRoute>

            <Route path="/:urlPerson/login">
              <SignInPage />
            </Route>
            <Route path="/:urlPerson/cadastro">
              <SignUpPage />
            </Route>
            <Route path="/:urlPerson">
              <HomePage />
            </Route>

            <Route path="*">
              <NotFoundPage />
            </Route>
          </Switch>
        </Router>
      </ProvideAuth>
    </ErrorBoundary>
  );
}

export default App;
