import { useState, useEffect, useContext, useMemo } from 'react';

import PageHeader from '../common/PageHeader';
import Button from '../common/Button';
import IconButton from '../common/IconButton';
import Toggle from '../common/Toggle';
import { List, ListItem, ListItemActions, ListItemText } from '../common/List';
import SongSearchDialog from '../common/SongSearchDialog';

import TrashIcon from '../../icons/Trash';
import StarIcon from '../../icons/Star';

import RadioContext from '../RadioContext';

import { getSongMeta } from '../../utils/songFile';

import * as apiClient from '../../apiClient';

function FavoriteSongsCall() {
  const [favorites, setFavorites] = useState([]);
  const [isAddFavOpen, setIsAddFavOpen] = useState(false);
  const {
    settings: { favoritesEnabled, favoritesOnlyEnabled },
    updateSettings,
  } = useContext(RadioContext);

  const loadFavorites = async () => {
    const response = await apiClient.getFavorites();
    setFavorites(response.items);
  };

  useEffect(() => {
    loadFavorites();
  }, []);

  const handleRemoveFav = (favId) => async () => {
    await apiClient.removeFavorite(favId);
    setFavorites((favs) => favs.filter((fav) => fav.id !== favId));
  };

  const handleAddFav = (song) => async () => {
    const newFavorite = await apiClient.addFavorite({
      songId: song.id,
      type: song.type,
    });
    setFavorites((favs) => [...favs, newFavorite]);
  };

  const handleToggleEnableFavorites = () => {
    updateSettings({ favoritesEnabled: !favoritesEnabled });
  };

  const handleToggleEnableFavoritesOnly = () => {
    updateSettings({ favoritesOnlyEnabled: !favoritesOnlyEnabled });
  };

  const handleOpenAddFav = () => {
    setIsAddFavOpen(true);
  };

  const handleCloseAddFav = () => {
    setIsAddFavOpen(false);
    loadFavorites();
  };

  const favoriteSongsMap = useMemo(() => {
    const favMap = new Map();
    favorites.forEach((fav) => {
      favMap.set(fav.songId, fav.id);
    });
    return favMap;
  }, [favorites]);

  return (
    <div>
      <PageHeader title="Músicas Favoritas" />
      <div className="p-6 md:px-10 md:pb-10 md:pt-0">
        <SongSearchDialog
          open={isAddFavOpen}
          onClose={handleCloseAddFav}
          renderActions={(song) =>
            favoriteSongsMap.has(song.id) ? (
              <IconButton
                title="Remover música dos favoritos"
                color="primary"
                onClick={handleRemoveFav(favoriteSongsMap.get(song.id))}
              >
                <StarIcon />
              </IconButton>
            ) : (
              <IconButton
                title="Adicionar música aos favoritos"
                onClick={handleAddFav(song)}
              >
                <StarIcon />
              </IconButton>
            )
          }
        />

        <div className="flex flex-col justify-between pb-5 border-b-2 md:flex-row md:items-center border-grayAlt">
          <div className="flex-1 mb-5 md:mb-0">
            <h2 className="text-2xl font-semibold">Suas músicas favoritas</h2>
            <p className="text-gray-400">{favorites.length} adicionadas</p>
          </div>

          <div className="flex items-center justify-between md:justify-end">
            <label className="flex items-center h-full mr-6 cursor-pointer">
              <Toggle
                className="block"
                checked={favoritesEnabled}
                onChange={handleToggleEnableFavorites}
              />
              <span className="ml-2 text-lg">Ativado</span>
            </label>
            <label className="flex items-center h-full mr-6 cursor-pointer">
              <Toggle
                className="block"
                checked={favoritesOnlyEnabled}
                onChange={handleToggleEnableFavoritesOnly}
              />
              <span className="ml-2 text-lg">Tocar apenas favoritas</span>
            </label>

            <Button color="primary" onClick={handleOpenAddFav}>
              Adicionar
            </Button>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto">
          {favorites.length === 0 ? (
            <p className="py-4">Nenhuma música favorita encontrada.</p>
          ) : (
            <List>
              {favorites.map((favorite) => {
                const { category, title, artist } = getSongMeta(favorite);
                const secondaryText = artist
                  ? `${artist} • ${category}`
                  : category;

                return (
                  <ListItem
                    key={`favorite-${favorite.id}`}
                    title={favorite.fileName}
                  >
                    <ListItemText primary={title} secondary={secondaryText} />
                    <ListItemActions>
                      <IconButton onClick={handleRemoveFav(favorite.id)}>
                        <TrashIcon />
                      </IconButton>
                    </ListItemActions>
                  </ListItem>
                );
              })}
            </List>
          )}
        </div>
      </div>
    </div>
  );
}

export default FavoriteSongsCall;
