import React, { useState } from 'react';

import IconButton from '../IconButton';
import SongSearchDialog from '../SongSearchDialog';
import LikeIcon from '../../../icons/Like';
import LikeFillIcon from '../../../icons/LikeFill';

import * as apiClient from '../../../apiClient';

function SongRequest({ children: childrenProp, onClick }) {
  const [isSongRequestOpen, setIsSongRequestOpen] = useState(false);
  const [songRequests, setSongRequests] = useState([]);

  const handleOpenSongRequest = (e) => {
    setSongRequests([]);
    setIsSongRequestOpen(true);

    if (onClick) {
      onClick(e);
    }
  };

  const handleCloseSongRequest = async () => {
    setIsSongRequestOpen(false);
    setSongRequests([]);

    await Promise.all(
      songRequests.map((request) => apiClient.requestSong(request)),
    );
  };

  const handleAddSongRequest = (song) => () => {
    setSongRequests((requests) => [
      ...requests,
      { songId: song.id, collection: song.collection },
    ]);
  };

  const handleRemoveSongRequest = (song) => () => {
    setSongRequests((requests) =>
      requests.filter(({ songId }) => songId !== song.id),
    );
  };

  const children = React.Children.map(childrenProp, (child) => {
    return React.cloneElement(child, {
      onClick: handleOpenSongRequest,
    });
  });

  return (
    <>
      <SongSearchDialog
        open={isSongRequestOpen}
        onClose={handleCloseSongRequest}
        renderActions={(song) => {
          return songRequests.find(({ songId }) => songId === song.id) ? (
            <IconButton
              title="Remover pedido"
              onClick={handleRemoveSongRequest(song)}
              color="primary"
            >
              <LikeFillIcon />
            </IconButton>
          ) : (
            <IconButton
              title="Pedir música"
              onClick={handleAddSongRequest(song)}
            >
              <LikeIcon />
            </IconButton>
          );
        }}
      />
      {children}
    </>
  );
}

export default SongRequest;
