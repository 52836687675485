import BackIcon from '../../../icons/ChevronLeft';
import { useHistory } from 'react-router-dom';

function PageHeader({ title, children, onClickBack }) {
  const history = useHistory();

  const handleBack = () => {
    if (onClickBack) {
      return onClickBack;
    }

    history.goBack();
  };

  return (
    <div className="h-16 bg-black md:h-auto md:py-5 md:bg-transparent">
      {children ? (
        children
      ) : (
        <div className="flex items-center h-full px-4 py-3">
          <button className="focus:outline-none md:hidden" onClick={handleBack}>
            <BackIcon className="w-3 mr-6" />
          </button>
          <div className="text-xl font-normal md:font-bold md:text-4xl md:px-6">
            {title}
          </div>
        </div>
      )}
    </div>
  );
}

export default PageHeader;
