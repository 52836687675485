import clsx from 'clsx';
import PropTypes from 'prop-types';

function Button(props) {
  const {
    children,
    type = 'button',
    fullWidth = false,
    size = 'medium',
    color = 'default',
    className,
    onClick,
    disabled = false,
  } = props;

  return (
    <button
      type={type}
      onClick={onClick}
      className={clsx(
        'inline-flex justify-center items-center font-medium rounded-md shadow-sm focus:outline-none',
        {
          'w-full': fullWidth,
          'px-3 py-1 text-sm': size === 'small',
          'px-4 py-2 text-base': size === 'medium',
          'px-5 py-3 text-lg': size === 'large',
          'bg-transparent text-white border-2 border-transparent hover:bg-gray-2':
            !disabled && color === 'default',
          'bg-black text-white border-2 border-black hover:bg-gray-800 hover:border-gray-800':
            !disabled && color === 'secondary',
          'bg-primary text-white border-2 border-primary hover:bg-primary-hover hover:border-primary-hover':
            !disabled && color === 'primary',
          'bg-transparent text-white border-2 border-white hover:bg-gray-50 hover:bg-opacity-30':
            !disabled && color === 'transparent',
          'bg-gray-400 text-gray-600 cursor-default': disabled,
        },
        className,
      )}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'transparent']),
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Button;
