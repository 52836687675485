import React from 'react';
import clsx from 'clsx';

function IconButton({ children: childrenProp, color = 'default', ...rest }) {
  const children = React.Children.map(childrenProp, (child) => {
    return React.cloneElement(child, {
      className: clsx(child.props.className, 'w-5 h-5'),
    });
  });

  return (
    <button
      {...rest}
      className={clsx(
        'p-3 inline-flex justify-center items-center transition-colors outline-none focus:outline-none',
        {
          'text-gray-400 hover:text-gray-500': color === 'default',
          'text-primary hover:text-primary-hover': color === 'primary',
        },
      )}
    >
      {children}
    </button>
  );
}

export default IconButton;
