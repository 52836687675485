import { useContext } from 'react';
import clsx from 'clsx';

import {
  FaFacebookF,
  FaInstagram,
  FaYoutube,
  FaWhatsapp,
} from 'react-icons/fa';
import { PiBrowsersBold } from 'react-icons/pi';

import PageHeader from '../common/PageHeader';

import RadioContext from '../RadioContext';

import bgPlayerDefault from '../../images/bg-player-default.jpg';

function getUserWebSiteUrl(website) {
  if (website.startsWith('http')) {
    return website;
  }
  return `https://${website}`;
}

function About() {
  const { settings } = useContext(RadioContext);
  return (
    <div>
      <PageHeader title="Sobre" />
      <div className="p-6 md:px-10 md:pb-8 md:pt-0">
        <div className="mb-8 md:mb-14">
          <div
            className="flex items-center justify-center w-full h-40 md:h-80 bg-center bg-no-repeat bg-cover bg-clip-border relative"
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.2) 100%), url(${
                settings.bgPlayer || bgPlayerDefault
              })`,
            }}
          >
            {settings.logo && (
              <img
                src={settings.logo}
                alt="Logo da rádio"
                className="w-16 md:w-48 rounded-full absolute bottom-0 left-0 -mb-8 ml-4 md:-mb-24 md:ml-12"
              />
            )}
          </div>
          <div
            className={clsx(
              'py-6 flex justify-between flex-col md:flex-row',
              settings.logo && 'mt-8 md:pl-64 md:mt-0',
            )}
          >
            <div>
              <h2 className="text-2xl font-bold">{settings.companyName}</h2>
              <p>
                Licenciado para{' '}
                <span className="font-bold">{settings.name}</span>
              </p>
            </div>
            <div className="flex items-center justify-center pt-8 md:pt-0 md:justify-end">
              {settings.facebook && (
                <SocialLink href={`https://facebook.com/${settings.facebook}`}>
                  <FaFacebookF size={28} />
                </SocialLink>
              )}
              {settings.instagram && (
                <SocialLink
                  href={`https://instagram.com/${settings.instagram}`}
                >
                  <FaInstagram size={28} />
                </SocialLink>
              )}
              {settings.youtube && (
                <SocialLink href={`https://youtube.com/${settings.youtube}`}>
                  <FaYoutube size={28} />
                </SocialLink>
              )}
              {settings.website && (
                <SocialLink href={getUserWebSiteUrl(settings.website)}>
                  <PiBrowsersBold size={28} />
                </SocialLink>
              )}
              {settings.whatsapp && (
                <SocialLink
                  href={`http://api.whatsapp.com/send?1=pt_BR&phone=${settings.whatsapp}`}
                >
                  <FaWhatsapp size={28} />
                </SocialLink>
              )}
            </div>
          </div>
        </div>
        <div className="text-xl">{settings.about}</div>
      </div>
    </div>
  );
}

function SocialLink({ href, children }) {
  return (
    <div className="px-3">
      <a
        href={href}
        target="_blank"
        rel="noreferrer noopener"
        className="text-gray-400 hover:text-primary transition-colors duration-300"
      >
        {children}
      </a>
    </div>
  );
}

export default About;
