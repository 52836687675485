import React, { useState } from 'react';

import Modal from '../Modal';
import ModalContent from '../ModalContent';
import ModalActions from '../ModalActions';
import Button from '../Button';

function ConfirmDialog({ title, message, onConfirm, children: childrenProp }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    if (onConfirm) onConfirm();
  };

  const children = React.Children.map(childrenProp, (child) => {
    return React.cloneElement(child, { onClick: handleOpen });
  });
  return (
    <>
      {children}
      <Modal open={open} onClose={handleClose}>
        <ModalContent>
          <div>
            {title && <h2 className="mb-6 text-2xl font-bold">{title}</h2>}
            <h3>{message}</h3>
          </div>
        </ModalContent>
        <ModalActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button color="primary" onClick={handleConfirm}>
            Ok
          </Button>
        </ModalActions>
      </Modal>
    </>
  );
}

export default ConfirmDialog;
