import { useState, useEffect, useRef } from 'react';
import checkOnline from '../utils/checkOnline';

function useOnline(timeout = 30000) {
  const [online, setOnline] = useState(navigator.onLine);
  const onlineRef = useRef(navigator.onLine);

  useEffect(() => {
    const checkOnlineStatus = async () => {
      const onlineStatus = await checkOnline();
      setOnline(onlineStatus);
      onlineRef.current = onlineStatus;
    };
    checkOnlineStatus();
    const checkOnlineInterval = setInterval(checkOnlineStatus, timeout);

    const handleOffline = () => {
      setOnline(false);
      onlineRef.current = false;
    };
    const handleOnline = () => {
      setOnline(true);
      onlineRef.current = true;
    };

    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    return () => {
      clearInterval(checkOnlineInterval);
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, [timeout]);

  return { online, onlineRef };
}

export default useOnline;
