function NotFoundPage() {
  return (
    <div className="flex items-center justify-center h-full px-4 py-12 sm:px-6 lg:px-8">
      <div className="text-4xl text-center">
        <div className="mb-4 font-bold">:/</div>
        <p>Conteúdo não encontrado</p>
      </div>
    </div>
  );
}

export default NotFoundPage;
