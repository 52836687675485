import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';

import MessagesBadge from './MessagesBadge';
import SongRequest from './SongRequest';
import ScreenLayer from '../ScreenLayer';
import RadioContext from '../../RadioContext';
import { removeTrailingSlash } from '../../../utils/url';

import logoImg from '../../../images/logo.svg';

function isItemActive(activeItem, item) {
  return activeItem && activeItem.id === item.id;
}

const MenuItem = ({ children, href, url, active, ...props }) => {
  const className = clsx(
    'outline-none focus:outline-none hover:text-primary-hover',
    active && 'text-primary font-bold',
    props.className,
  );

  if (url) {
    return (
      <a
        href={url}
        // eslint-disable-next-line
        target="_blank"
        rel="noopener"
        {...props}
        className={className}
      >
        {children}
      </a>
    );
  }

  return href ? (
    <Link to={href} {...props} className={className}>
      {children}
    </Link>
  ) : (
    <button {...props} className={className}>
      {children}
    </button>
  );
};

function MenuItemRoot({ href, url, icon, title, active, id, onClick }) {
  const menuIcon = icon
    ? React.cloneElement(icon, {
        size: '1em',
      })
    : null;

  const menuItem = (
    <MenuItem
      href={href}
      url={url}
      className="flex items-center justify-center flex-1 w-full h-full md:px-2 md:py-3"
      onClick={onClick}
      alt={title}
      title={title}
      active={active}
    >
      <div className="relative flex flex-col items-center justify-center w-full h-full text-center">
        {menuIcon}
        <span className="hidden w-full mt-2 text-xs font-light text-center md:block">
          {title}
        </span>
        {id === 'mensagens' && <MessagesBadge />}
      </div>
    </MenuItem>
  );

  if (id === 'pedido-musical') {
    return <SongRequest>{menuItem}</SongRequest>;
  }

  return menuItem;
}

function MainMenu() {
  const { menuItems, findMenuItemByPath } = useContext(RadioContext);
  const { url } = useRouteMatch();
  const location = useLocation();
  const rootUrl = removeTrailingSlash(url);
  const [activeItemRoot, setActiveItemRoot] = useState();
  const [activeItemChild, setActiveItemChild] = useState();
  const [isSubMenuOpenMobile, setIsSubMenuOpenMobile] = useState(false);
  const [activeItemMobile, setActiveItemMobile] = useState();

  const handleClickMain = (item) => () => {
    if (isItemActive(activeItemRoot, item) && activeItemRoot.children) {
      setActiveItemRoot();
    } else {
      setActiveItemRoot(item);
    }
  };

  const handleClickMainMobile = (item) => () => {
    setIsSubMenuOpenMobile(
      Boolean(item.children && !isItemActive(activeItemMobile, item)),
    );
    setActiveItemMobile(isItemActive(activeItemMobile, item) ? null : item);
  };

  const handleClickChild = (item) => () => {
    setActiveItemChild(item);
  };

  const handleClickChildMobile = (item) => () => {
    handleClickChild(item);
    setIsSubMenuOpenMobile(false);
  };

  const handleClickLogo = () => {
    setActiveItemRoot();
    setActiveItemChild();
  };

  useEffect(() => {
    if (activeItemRoot) {
      return;
    }

    const activePath = location.pathname.replace(rootUrl, '');
    if (activePath) {
      const menuItem = findMenuItemByPath(activePath);
      if (menuItem) {
        setActiveItemRoot(menuItem.root);
        setActiveItemMobile(menuItem.root);
        setActiveItemChild(menuItem.item);
      }
    }
  }, [location.pathname, rootUrl, activeItemRoot, findMenuItemByPath]);

  const subMenuTransitions = useTransition(
    activeItemRoot &&
      activeItemRoot.children &&
      activeItemRoot.children.length > 0
      ? activeItemRoot
      : null,
    {
      from: { opacity: 0, width: 0 },
      enter: { opacity: 1, width: 240 },
      leave: { opacity: 0, width: 0 },
    },
  );

  return (
    <nav className="fixed bottom-0 left-0 w-full h-16 overflow-y-auto bg-black z-100 md:z-90 md:relative md:h-full">
      <div className="flex h-full">
        <div className="w-full h-full md:w-24">
          <div className="justify-center hidden pt-6 mb-8 md:flex">
            <Link to={rootUrl} onClick={handleClickLogo}>
              <img src={logoImg} alt="RadioSrv" width="28" height="34" />
            </Link>
          </div>

          {/* Menu desktop */}
          <div className="hidden h-full md:flex md:flex-col md:h-auto">
            {menuItems.map((item) => {
              const href = item.path ? `${rootUrl}${item.path}` : null;
              return (
                <MenuItemRoot
                  key={item.id}
                  id={item.id}
                  icon={item.icon}
                  href={href}
                  url={item.url}
                  title={item.title}
                  onClick={handleClickMain(item)}
                  active={isItemActive(activeItemRoot, item)}
                />
              );
            })}
          </div>

          {/* Menu mobile */}
          <div className="flex h-full md:hidden md:flex-col md:h-auto">
            {menuItems.map((item) => {
              const href = item.path ? `${rootUrl}${item.path}` : null;
              return (
                <MenuItemRoot
                  key={item.id}
                  id={item.id}
                  icon={item.icon}
                  href={href}
                  url={item.url}
                  title={item.title}
                  onClick={handleClickMainMobile(item)}
                  active={isItemActive(activeItemMobile, item)}
                />
              );
            })}
          </div>
        </div>

        {/* Desktop sub-menu  */}
        {subMenuTransitions(
          (styles, item) =>
            item && (
              <animated.div
                className="hidden bg-blackSecondary md:block"
                style={styles}
              >
                <div className="px-6 truncate py-7">
                  <h2 className="mb-10 text-3xl font-bold">{item.title}</h2>
                  <div className="flex flex-col">
                    {item.children &&
                      item.children.map((child) => {
                        const href = child.path
                          ? `${rootUrl}${child.path}`
                          : null;
                        return (
                          <MenuItem
                            key={child.id}
                            href={href}
                            url={child.url}
                            className="mb-4 font-light text-left"
                            active={isItemActive(activeItemChild, child)}
                            onClick={handleClickChild(child)}
                          >
                            {child.title}
                          </MenuItem>
                        );
                      })}
                  </div>
                </div>
              </animated.div>
            ),
        )}

        {/* Mobile sub-menu  */}
        <ScreenLayer
          open={isSubMenuOpenMobile}
          title={activeItemMobile ? activeItemMobile.title : null}
          slideDirection="left"
          onClose={() => setIsSubMenuOpenMobile(false)}
        >
          <div className="flex flex-col px-5 pt-6">
            {activeItemMobile &&
              activeItemMobile.children &&
              activeItemMobile.children.map((item) => {
                const href = item.path ? `${rootUrl}${item.path}` : null;
                return (
                  <MenuItem
                    key={item.id}
                    href={href}
                    url={item.url}
                    className="mb-6 font-light text-left"
                    active={isItemActive(activeItemChild, item)}
                    onClick={handleClickChildMobile(item)}
                  >
                    {item.title}
                  </MenuItem>
                );
              })}
          </div>
        </ScreenLayer>
      </div>
    </nav>
  );
}

export default MainMenu;
