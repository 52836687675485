import clsx from 'clsx';

function Toggle(props) {
  const { id, name, onChange, checked } = props;

  return (
    <div
      className={clsx(
        'relative cursor-pointer border rounded-full',
        checked ? 'border-primary' : 'border-gray-300',
      )}
    >
      <input
        type="checkbox"
        id={id || name}
        name={name}
        onChange={onChange}
        className="hidden"
        checked={checked}
      />
      <div
        className={
          'w-12 h-6 bg-transparent shadow-inner transition-colors ease-in-out duration-500'
        }
      ></div>
      <div
        className={clsx(
          'absolute w-6 h-6 bg-white rounded-full shadow top-0 left-0 transform transition ease-in-out duration-500',
          checked ? 'bg-primary translate-x-full' : 'bg-gray-300',
        )}
      ></div>
    </div>
  );
}

export default Toggle;
