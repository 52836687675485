import React from 'react';
import clsx from 'clsx';

function Select(props) {
  const {
    id,
    name,
    children: childrenProp,
    value,
    onChange,
    error = false,
    multiple = false,
  } = props;

  const children = React.Children.map(childrenProp, (child) => {
    const className = multiple ? 'p-3' : '';
    return React.cloneElement(child, { className });
  });

  return (
    <div className="relative w-full">
      <select
        id={id || name}
        name={name}
        onChange={onChange}
        value={value}
        multiple={multiple}
        className={clsx(
          'w-full appearance-none border-2 rounded-md focus:ring-indigo-500 focus:outline-none focus:border-gray-500 dark:bg-black dark:text-white dark:border-gray-400 dark:focus:border-gray-200',
          { 'border-red-600 hover:border-red-600 focus:border-red-600': error },
          { 'p-3': !multiple },
        )}
      >
        {children}
      </select>
      {!multiple && (
        <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
          <svg
            className="w-5 h-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </span>
      )}
    </div>
  );
}

export default Select;
