import { createContext, useContext, useEffect, useState } from 'react';

import { usePlayer } from './Player.hooks';

const playerContextInitial = {
  player: null,
  playerState: null,
  online: true,
  clearSession: () => {},
  reConnect: () => {},
  signout: () => {},
  setVolume: () => {},
  toggleMuteVolume: () => {},
  toggleMic: () => {},
  togglePlayPause: () => {},
  playMain: () => {},
  skipPrev: () => {},
  skipNext: () => {},
  stopSecondary: () => {},
};

const audioPositionContextInitial = {
  position: 0,
  duration: 0,
  seek: () => {},
};

const PlayerContext = createContext(playerContextInitial);

const AudioPositionContext = createContext(audioPositionContextInitial);

export function PlayerContextProvider({ children }) {
  const { state: playerState, ...rest } = usePlayer();

  return (
    <PlayerContext.Provider
      value={{
        playerState,
        ...rest,
      }}
    >
      <AudioPositionContext.Provider value={audioPositionContextInitial}>
        {children}
      </AudioPositionContext.Provider>
    </PlayerContext.Provider>
  );
}

export function useAudioPlayer() {
  const context = useContext(PlayerContext);
  if (context === undefined) {
    throw new Error(
      'useAudioPlayer must be used within a PlayerContextProvider',
    );
  }
  return context;
}

export function useAudioPosition() {
  const { player } = useAudioPlayer();
  const [position, setPosition] = useState(0);
  const [duration, setDuration] = useState(0);
  const context = useContext(AudioPositionContext);

  useEffect(() => {
    if (!player) {
      return;
    }

    player.on('player:step_main', ({ duration, position }) => {
      setPosition(position);
      setDuration(duration);
    });
  }, [player]);

  const seek = (position) => {
    player.seekMainAudio(position);
    setPosition(position);
  };

  if (context === undefined) {
    throw new Error(
      'useAudioPosition must be used within a PlayerContextProvider',
    );
  }

  return {
    duration,
    position,
    seek,
  };
}

export default PlayerContext;
