import { useEffect, useState } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Alert from '../common/Alert';
import Button from '../common/Button';
import InputField from '../common/InputField';
import TextAreaField from '../common/TextAreaField';
import Snackbar from '../common/Snackbar';

import { parseRequestError } from '../../utils/errors';

import * as apiClient from '../../apiClient';

function ContactPage() {
  const location = useLocation();
  const [submitError, setSubmitError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(null);

  useEffect(() => {
    document.title = 'RadioSrv - Fale consoco';
  }, []);

  const handleCloseSnackbar = () => {
    setSubmitSuccess(null);
  };

  const handleSubmit = async (values, { setErrors, resetForm }) => {
    setSubmitError(null);

    try {
      await apiClient.sendContactMessage({
        ...values,
        radioId: location.state.radioId,
      });
      resetForm();
      setSubmitSuccess(
        'Obrigado! Sua mensagem foi recebida e em breve retornaremos o contato.',
      );
    } catch (error) {
      const [errorMessage, errorDetails] = parseRequestError(error);
      if (errorDetails) {
        setErrors(errorDetails);
      } else {
        setSubmitError(errorMessage);
      }
    }
  };

  if (!location.state || !location.state.radioId) {
    return (
      <Redirect
        to={{
          pathname: '/login',
        }}
      />
    );
  }

  return (
    <div className="flex items-center justify-center md:mt-28">
      <Snackbar open={Boolean(submitSuccess)} onClose={handleCloseSnackbar}>
        <Alert type="success" className="shadow-md">
          {submitSuccess}
        </Alert>
      </Snackbar>
      <div className="px-4 py-6">
        <div className="md:max-w-lg space-y-6">
          <div className="text-center">
            <h1 className="text-4xl font-bold">Gostou desta rádio?</h1>
            <p className="mt-5">
              Entre em contato e saiba como ter uma rádio personalizada em seu
              estabelecimento.
            </p>
          </div>
          <Formik
            initialValues={{ name: '', email: '', phone: '', message: '' }}
            validationSchema={Yup.object({
              name: Yup.string().required('Informe o nome para contato.'),
              email: Yup.string()
                .email('E-mail inválido.')
                .required('Informe o seu email.'),
              phone: Yup.string().required('Informe o seu telefone.'),
              message: Yup.string().required('Adicione uma mensagem.'),
            })}
            onSubmit={handleSubmit}
          >
            <Form noValidate autoComplete="off">
              <InputField name="name" placeholder="Nome" />
              <InputField name="email" type="email" placeholder="E-mail" />
              <InputField name="phone" placeholder="Telefone" />
              <TextAreaField
                name="message"
                placeholder="Digite sua mensagem aqui..."
                rows={10}
              />
              <div className="mb-5">
                {submitError ? <Alert type="error">{submitError}</Alert> : null}
              </div>
              <div className="mb-5 text-center">
                <Button type="submit" size="large" color="primary" fullWidth>
                  Enviar
                </Button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
