import { useContext } from 'react';

import HomeHeader from '../../common/HomeHeader';
import Clock from '../../common/Clock';

import RadioContext from '../../RadioContext';

import bgPlayerDefault from '../../../images/bg-player-default.jpg';

function Home() {
  const { settings } = useContext(RadioContext);

  return (
    <div
      className="w-full h-full bg-center bg-no-repeat bg-cover bg-clip-border"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.6) 100%), url(${
          settings.bgPlayer || bgPlayerDefault
        })`,
      }}
    >
      <HomeHeader />
      <div className="absolute bottom-0 left-0 z-10 w-full px-6 py-5 md:px-10 md:py-6">
        <Clock />
      </div>
    </div>
  );
}

export default Home;
