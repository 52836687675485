import { io } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import { Howl } from 'howler';

import { SOCKET_IO_URL, SOCKET_IO_PATH, SIGN_AUDIO_URL } from '../../../conf';
import CircuitBreaker, { circuitBreakerStates } from '../../../circuitBreaker';
import * as apiClient from '../../../apiClient';
import * as logger from '../../../utils/logger';
import { getSongMeta } from '../../../utils/songFile';

// Check playing simultaneous idea
// const playingSimultaneously = [];
// Howler._howls.forEach((howl) => {
//     howl._sounds.forEach((sound) => {
//     if (!sound._paused) {
//         playingSimultaneously.push({ soundId: sound._id, src: howl._src });
//     }
//     });
// });
// if (playingSimultaneously.length > 1) {
//     Howler.stop(playingSimultaneously[0].soundId);
// }

// Retry idea
// function handleError(audio, mediaType) {
//   const { fallbackSrc = [] } = audio;
//   retry((nextValue) => {
//     this.startMainAudio({ ...audio, mp3: nextValue }, mediaType);
//   }, fallbackSrc);
// }

/**
 * Calculate the volume prortionally to the player (global) volume.
 * @param {number} audioVolume - Volume setup for the audio 0-100
 * @param {number} playerVolume - Current player volume 0-1
 * @returns {number} - The final audio volume.
 */
function calculateVolume(audioVolume, playerVolume) {
  if (audioVolume != null) {
    return (audioVolume * playerVolume) / 100;
  }
  return playerVolume;
}

/**
 * Check if the audio is a streaming.
 * @param audio - the audio file.
 * @returns {bool} - if the audio is streaming.
 */
export function isStreaming(audio) {
  return parseInt(audio.time || 0, 10) > 0;
}

/**
 * Get fade transition duration in seconds.
 * @param audio - the audio file.
 * @returns {number} - the transition duration in seconds.
 */
function getFadeDuration(audio) {
  return parseInt(audio.transitionDuration || 0, 10);
}

export class Player {
  constructor(accessToken) {
    this._accessToken = accessToken;
    this._sessionKey = uuidv4();
    this._socketId = null;
    this._events = {
      'socket:connect_error': [],
      'player:init': [],
      'player:disconnect': [],
      'player:refresh': [],
      'player:load_main': [],
      'player:play_main': [],
      'player:pause_main': [],
      'player:play_blocked_main': [],
      'player:step_main': [],
      'player:play_secondary': [],
      'player:recovery_mode': [],
      'player:fading_main': [],
      'message:new': [],
    };
    this._mediaTypes = [];
    this.online = true;
    this.playerStarted = false;
    this.isPaused = false;

    this.volume = Number(window.localStorage.getItem('volume') || 1);

    this.playlist = null;
    this.mainAudio = null;
    this.mainAudioPlayer = null;
    this.mainAudioStreamingTimeout = null;
    this.mainAudioCounter = 0;
    this.mainAudioFallbackCounter = 0;

    this.micPlayer = null;

    this.playlistSecondary = null;
    this.secondaryAudio = null;
    this.secondaryAudioPlayer = null;
    this.secondaryAudioStreamingTimeout = null;
    this.secondaryAudioCounter = 0;
    this.secondaryAudiosWaiting = [];

    this.cacheSize = 5;
    this.cachedAudios = [];

    this.playlistOffline = null;
    this.circuitBreaker = new CircuitBreaker();

    this.preloadTime = 20;
    this.preloadStarted = false;
    this.preloadedAudio = null;
    this.preloadedAudioPlayer = null;

    this.fadeStarted = false;
    this.fadeAudioPlayer = null;

    this.playerStuckCounter = 0;
    this.lastPlayerPosition = 0;

    this.concurrencyRetryCount = 0;
    this.socketDisconnectedCounter = 0;

    console.log(
      `Player initiated: v${window.APP_VERSION} [${window.APP_SERVER}]`,
    );
    console.timeLog('player:start');
  }

  registerPlayerStuckMonitor() {
    clearInterval(this.playerStuckMonitor);
    this.playerStuckMonitor = setInterval(() => {
      const activePlayer = [
        this.mainAudioPlayer,
        this.secondaryAudioPlayer,
        this.fadeAudioPlayer,
        this.micPlayer,
      ].find((player) => player && player.playing());
      const activePlayerPosition = activePlayer
        ? this.getAudioPosition(activePlayer)
        : 0;

      if (
        !this.isPaused &&
        this.circuitBreaker.is(circuitBreakerStates.GREEN) &&
        this.online &&
        activePlayerPosition === this.lastPlayerPosition
      ) {
        this.playerStuckCounter += 1;
      } else {
        this.playerStuckCounter = 0;
      }

      this.lastPlayerPosition = activePlayerPosition;

      if (this.playerStuckCounter > 8) {
        logger.info(
          `[PLAYER] Player is stuck ${this.playerStuckCounter} ${this.lastPlayerPosition}`,
        );

        this.unregisterPlayerStuckMonitor();
        window.location.reload();
      }
    }, 5000);
  }

  unregisterPlayerStuckMonitor() {
    clearInterval(this.playerStuckMonitor);
    this.playerStuckCounter = 0;
    this.lastPlayerPosition = 0;
  }

  registerSocketConnectionMonitor() {
    this.socketConnectionMonitor = setInterval(() => {
      if (!this.socket?.connected) {
        this.socketDisconnectedCounter += 1;
      } else {
        this.socketDisconnectedCounter = 0;
      }
      if (this.socketDisconnectedCounter > 2 && this.online) {
        logger.info(`[PLAYER] Player socket disconnected from monitor`);
        window.location.reload();
      }
    }, 5000);
  }

  unregisterSocketConnectionMonitor() {
    clearInterval(this.socketConnectionMonitor);
    this.socketDisconnectedCounter = 0;
  }

  /**
   * Log warn messages.
   */
  _warn(message, meta) {
    console.warn(`[PLAYER]${message}`, meta);
  }

  /**
   * Execute an event.
   */
  _executeEvent(eventName, params) {
    const self = this;
    const eventCallbacks = this._events[eventName] || [];
    eventCallbacks.forEach((callback) => callback({ player: self, ...params }));
  }

  /**
   * Initialize socket.
   */
  _initSocket() {
    const socket = io(SOCKET_IO_URL, {
      path: SOCKET_IO_PATH,
      transports: ['websocket'],
      auth: { token: this._accessToken },
      query: {
        sessionkey: this._sessionKey,
      },
    });
    this.socket = socket;

    socket.on('connect', async () => {
      this._socketId = socket.id;
      console.log('[SOCKETIO] Connected', this._socketId, new Date());

      if (!this.playerStarted) {
        // Init player should be executed once. The socket might get
        // restarted occasionally.
        await this._initPlayer();
      }
    });

    socket.on('connect_error', (error) => {
      console.log(
        '[SOCKETIO] Error',
        this._socketId,
        error,
        error.message,
        error.data,
        new Date(),
      );
      this._executeEvent('socket:connect_error', { error });

      if (error.message === 'ALREADY_LOGGED_IN') {
        setTimeout(() => {
          if (this.concurrencyRetryCount < 3) {
            this.init();
            this.concurrencyRetryCount += 1;
          }
        }, 10000);
      }
    });

    socket.on('player:turnoff', () => {
      this._executeEvent('player:disconnect');
      this.disconnect();
    });

    socket.on('player:refresh', () => {
      window.location.reload();
    });

    socket.on('update_settings:media_types', (mediaTypes) => {
      this._mediaTypes = mediaTypes;
      this.playlist?.reset(this._mediaTypes);
      logger.info(`[PLAYER] Updated media types`, { mediaTypes });
    });

    socket.on('play:secondary', (audios) => {
      if (!audios.length) {
        return;
      }
      this.startSecondary(audios);
    });

    socket.on('player:play', () => {
      this.playMain();
    });

    socket.on('player:pause', () => {
      this.pauseMain();
      this._executeEvent('player:pause_main');
    });

    socket.on('message:new', async () => {
      this._executeEvent('message:new');
    });
  }

  /**
   * Initialize the main audio player.
   */
  async _initPlayer() {
    console.timeLog('player:start');
    let data;

    try {
      data = await apiClient.initPlayer();
    } catch (error) {
      setTimeout(() => {
        window.location.reload();
      }, 5000);
      return;
    }

    this._mediaTypes = data.mediaTypes;

    this.playerStarted = true;
    this._executeEvent('player:init', data);

    this.registerPlayerStuckMonitor();
    // this.registerSocketConnectionMonitor();

    if (this._mediaTypes.length === 0) {
      return;
    }

    this.playlist = new Playlist(this._mediaTypes);
    await this.loadNextAudio();
  }

  /**
   * Initialize a player instance:
   *  - Init socket
   *  - Check concurrency
   *  - Init player
   *  - Start main player flow
   */
  init() {
    this._initSocket();
  }

  /**
   * Register callback events.
   */
  on(eventName, callback) {
    if (!(eventName in this._events)) {
      console.warn(`[Player] Unsupported event type: ${eventName}`);
      return;
    }

    this._events[eventName].push(callback);
  }

  /**
   * Re-connect the socket.
   */
  connect() {
    this.socket.connect();
  }

  /**
   * Disconnect the player entirely.
   */
  disconnect() {
    console.log('[SOCKETIO] Disconnected', this._socketId, new Date());
    this.socket?.disconnect();

    this.mainAudioPlayer?.stop();
    this.mainAudioPlayer?.unload();

    this.secondaryAudioPlayer?.stop();
    this.secondaryAudioPlayer?.unload();

    this.fadeAudioPlayer?.stop();
    this.fadeAudioPlayer?.unload();

    this.preloadedAudioPlayer?.stop();
    this.preloadedAudioPlayer?.unload();

    this.micPlayer?.stop();
    this.micPlayer?.unload();

    this.clearStreamingTimeouts();
    this.unregisterPlayerStuckMonitor();
    this.unregisterSocketConnectionMonitor();
  }

  /**
   * Clear player session.
   */
  async clearSession() {
    await apiClient.clearSession();
  }

  /**
   * Fetches the next main audio.
   */
  async loadNextAudio() {
    this.clearStreamingTimeouts();

    let mediaType;
    let audio;

    if (this.online) {
      if (!this.socket?.connected) {
        logger.info(`[PLAYER] Player socket disconnected from load next`);
        window.location.reload();
        return;
      }

      this.playlistOffline = null;

      mediaType = this.playlist.next();

      if (this.preloadedAudio) {
        // Use preloaded audio
        audio = this.preloadedAudio;
        this.clearPreload();
      } else {
        try {
          audio = await apiClient.loadAudio(mediaType);
        } catch (error) {
          this._warn(
            `[PLAY_ERROR][MAIN]: Failed to fetch next audio. ${error}.`,
            {
              mediaType,
            },
          );
          await this.handleErrorLoadNextAudio(error);
          return;
        }
      }
    } else if (this.cachedAudios.length > 0) {
      // Starts offline playlist
      if (!this.playlistOffline) {
        this.playlistOffline = new Playlist(this.cachedAudios);
      }

      audio = this.playlistOffline.next();
      mediaType = audio.mediaType;
    }

    if (!audio || !audio.mp3) {
      const error = new Error(
        `[PLAY_ERROR][MAIN]: Failed to load a valid audio file for "${mediaType}".`,
      );
      this._warn(error.message);
      await this.handleErrorLoadNextAudio(error);
      return;
    }

    this.startMainAudio(audio, mediaType);
    this.clearPreload();
  }

  /**
   * Init and play a main audio.
   * @param audio
   * @param {String} mediaType - the media type (playlist): a1, a2, ...
   */
  startMainAudio(audio, mediaType) {
    if (this.mainAudioPlayer) {
      this.mainAudioPlayer.stop();
    }

    this.mainAudio = { mediaType, ...audio };
    if (this.fadeAudioPlayer) {
      // Cleanup fadeEvents
      this.fadeAudioPlayer.off();

      this.mainAudioPlayer = this.fadeAudioPlayer;
      this.endFadeMainAudio();
      this.handlePlayMain();
    } else {
      this.mainAudioPlayer = new Howl({
        src: [audio.mp3],
        format: 'mp3',
        html5: true,
        autoplay: true,
        preload: true,
        volume: calculateVolume(audio.volume, this.volume),
      });
    }

    this.mainAudioPlayer.on('load', () => {
      this._executeEvent('player:load_main', {
        audio: this.mainAudio,
        audioMeta: getSongMeta(this.mainAudio),
      });
    });

    this.mainAudioPlayer.on('end', async () => {
      this.addToCache(this.mainAudio);
      await this.loadNextAudio();
    });

    this.mainAudioPlayer.on('play', () => {
      this.handlePlayMain();
    });

    this.mainAudioPlayer.on('loaderror', async (_, error) => {
      this._warn(`[LOAD_ERROR][MAIN]: ${error}.`, {
        title: audio.title,
        file: audio.mp3,
        mediaType,
      });

      await this.handleErrorLoadMainAudio(
        audio,
        mediaType,
        `loaderror: ${error}`,
      );
    });

    this.mainAudioPlayer.on('playerror', async (_, error) => {
      this._warn(`[PLAY_ERROR][MAIN]: ${error}.`, {
        title: audio.title,
        file: audio.mp3,
        mediaType,
      });

      if (error.startsWith('Playback was unable to start')) {
        this.isPaused = true;
        this._executeEvent('player:play_blocked_main');
      } else {
        await this.handleErrorLoadMainAudio(
          audio,
          mediaType,
          `playerror: ${error}`,
        );
      }
    });
  }

  handlePlayMain() {
    this.isPaused = false;
    this.mainAudioCounter += 1;
    this.mainAudioFallbackCounter = 0;
    this.circuitBreaker.onSuccess();

    this._executeEvent('player:play_main', {
      audio: this.mainAudio,
      audioMeta: getSongMeta(this.mainAudio),
    });

    // Start timed song
    if (isStreaming(this.mainAudio)) {
      this.mainAudioStreamingTimeout = setTimeout(
        () => this.loadNextAudio(),
        this.mainAudio.time * 1000,
      );
    }

    // Start audio position update
    // requestAnimationFrame(this.stepMainAudio.bind(this));

    // Hack to use timeupdate event callback
    const audioNode = this.mainAudioPlayer?._sounds[0]?._node;
    if (audioNode) {
      audioNode.removeEventListener(
        'timeupdate',
        this.stepMainAudio.bind(this),
      );
      audioNode.addEventListener('timeupdate', this.stepMainAudio.bind(this));
    }

    // Play waiting secondaries
    if (this.secondaryAudiosWaiting.length > 0) {
      this.startSecondary(this.secondaryAudiosWaiting);
    }
  }

  /**
   * Handle load errors from the main player.
   */
  async handleErrorLoadMainAudio(audio, mediaType, error) {
    const { fallbackSrc = [] } = audio;
    if (
      fallbackSrc.length > 0 &&
      this.mainAudioFallbackCounter < fallbackSrc.length
    ) {
      // Retry with fallbacks
      const nextFallbackSrc = fallbackSrc[this.mainAudioFallbackCounter];
      this.mainAudioFallbackCounter += 1;
      this.startMainAudio({ ...audio, mp3: nextFallbackSrc }, mediaType);
    } else {
      this.mainAudioFallbackCounter = 0;
      await this.handleErrorLoadNextAudio(
        new Error(
          `[PLAYER] Failed to load audio "${fallbackSrc.join(',')}". ${error}`,
        ),
      );
    }
  }

  /**
   * Handle load errors from the main player.
   */
  async handleErrorLoadNextAudio(error) {
    this.circuitBreaker.onFailure();

    if (this.circuitBreaker.is(circuitBreakerStates.RED)) {
      await logger.info(`[PLAYER][RELOAD] Circuit breaker got to RED`, {
        totalErrors: this.circuitBreaker.failureCountTotals,
      });
      window.location.reload();
      return;
    }

    if (
      this.circuitBreaker.is(
        circuitBreakerStates.YELLOW,
        circuitBreakerStates.RED,
      )
    ) {
      this._executeEvent('player:recovery_mode');
      setTimeout(async () => {
        this._warn(
          `[PLAY_ERROR][MAIN]: Circuit breaker in bad state "${this.circuitBreaker.state}".`,
        );
        logger.info(
          `[PLAYER] Recovery mode ${this.circuitBreaker.state} ${this.circuitBreaker.failureCount} - ${this.circuitBreaker.failureCountTotal}`,
          { error: error ? error.message : null },
        );
        await this.loadNextAudio();
      }, this.circuitBreaker.delay);
    } else {
      await this.loadNextAudio();
    }
  }

  /**
   * Update playback position of the main audio.
   */
  async stepMainAudio() {
    const position = this.mainAudioPlayer.seek() || 0;
    const duration = this.getAudioDuration(
      this.mainAudio,
      this.mainAudioPlayer,
    );
    const remainingTime = duration - position;

    this._executeEvent('player:step_main', {
      duration,
      position,
    });

    // if (this.mainAudioPlayer.playing()) {
    //   requestAnimationFrame(this.stepMainAudio.bind(this));
    // }

    if (
      duration > 0 &&
      remainingTime > 0 &&
      remainingTime <= this.preloadTime &&
      !this.preloadStarted
    ) {
      await this.preloadNextMainAudio();
    }

    const fadeDuration = getFadeDuration(this.mainAudio);
    if (
      duration > 0 &&
      remainingTime > 0 &&
      remainingTime <= fadeDuration &&
      !this.fadeStarted
    ) {
      if (
        this.preloadedAudio &&
        this.canFade(this.mainAudio, this.preloadedAudio)
      ) {
        await this.startFadeMainAudio(fadeDuration);
      }
    }
  }

  /**
   * Check if the player can start fade transition.
   * @returns {bool} - if the audio is streaming.
   */
  canFade() {
    const currentFadeDuration = getFadeDuration(this.mainAudio);
    const nextFadeDuration = getFadeDuration(this.preloadedAudio);

    const currentAudioDuration = this.getAudioDuration(
      this.mainAudio,
      this.mainAudioPlayer,
    );
    const nextAudioDuration = this.getAudioDuration(
      this.preloadedAudio,
      this.preloadedAudioPlayer,
    );

    return (
      !isStreaming(this.preloadedAudio) &&
      currentFadeDuration > 0 &&
      nextFadeDuration > 0 &&
      currentAudioDuration > currentFadeDuration &&
      nextAudioDuration > nextFadeDuration
    );
  }

  /**
   * Return the audio duration.
   */
  getAudioDuration(audio, player) {
    const duration = isStreaming(audio) ? audio?.time : player?.duration();
    return duration || 0;
  }

  /**
   * Return the audio seek position.
   */
  getAudioPosition(player) {
    return player?.seek() || 0;
  }

  /**
   * Start next audio preload.
   */
  async preloadNextMainAudio() {
    this.preloadStarted = true;
    const mediaType = this.playlist.getNext();

    if (mediaType === 'a8') {
      return;
    }

    let audio;
    try {
      audio = await apiClient.loadAudio(mediaType);
    } catch (error) {
      return;
    }

    if (!audio.mp3 || isStreaming(audio)) {
      return;
    }

    this.setPreloadedMainAudio({ mediaType, ...audio });
  }

  /**
   * Try to get the next available audio to preload.
   */
  setPreloadedMainAudio(audio) {
    this.preloadedAudioPlayer = new Howl({
      src: [audio.mp3],
      format: 'mp3',
      html5: true,
      volume: 0,
      onload: () => {
        console.info('Preloaded audio', audio);
        this.preloadedAudio = audio;
      },
    });
    this.preloadedAudioPlayer.load();
  }

  /**
   * Cleanup preload.
   */
  clearPreload() {
    this.preloadStarted = false;
    this.preloadedAudio = null;
    this.preloadedAudioPlayer = null;
  }

  /**
   * Start fade of the main audio.
   */
  startFadeMainAudio(fadeDuration) {
    this.fadeStarted = true;

    console.info('Start fade', this.preloadedAudio);

    const duration = fadeDuration * 1000;
    this.fadeAudioPlayer = new Howl({
      src: [this.preloadedAudio.mp3],
      format: 'mp3',
      html5: true,
      preload: true,
      onplay: () => {
        this._executeEvent('player:fading_main', {
          fadeDuration,
          nextAudio: this.preloadedAudio,
        });
      },
    });
    this.fadeAudioPlayer.play();

    // Fade in next audio
    this.fadeAudioPlayer.fade(
      0,
      calculateVolume(this.preloadedAudio.volume, this.volume),
      duration,
    );

    // Fade out current audio
    this.mainAudioPlayer.fade(this.mainAudioPlayer.volume, 0, duration);
  }

  /**
   * End fade of the main audio.
   */
  endFadeMainAudio() {
    this.fadeStarted = false;
    this.fadeAudioPlayer = null;
  }

  /**
   * Seek playback position of the main audio.
   * @param {Number} position - the position number in seconds to seek.
   */
  seekMainAudio(position) {
    this.mainAudioPlayer?.seek(position);
  }

  /**
   * Start secondary player with the given audio list.
   * @param audios - the list of audio files.
   */
  startSecondary(audios) {
    if (this.secondaryAudioPlayer?.playing()) {
      // Append audios to the playlist if it's already playing
      this.playlistSecondary.merge(audios);
      return;
    }
    if (isStreaming(this.mainAudio) || this.fadeStarted) {
      this.secondaryAudiosWaiting = [...this.secondaryAudiosWaiting, ...audios];
      return;
    }

    this.playlistSecondary = new Playlist(audios);
    this.pauseMain();

    this._executeEvent('player:play_secondary', { audios });

    this.loadNextSecondaryAudio();
  }

  /**
   * Stop secondary player.
   */
  stopSecondary() {
    this.secondaryAudioPlayer.stop();
    this.secondaryAudioPlayer.unload();
    this.playMain();

    this.playlistSecondary = null;
    this.secondaryAudio = null;
    this.secondaryAudioPlayer = null;
    this.secondaryAudiosWaiting = [];

    this.clearStreamingTimeouts();
  }

  /**
   * Get the next secondary audio.
   */
  loadNextSecondaryAudio() {
    this.clearStreamingTimeouts();

    const audio = this.playlistSecondary.next();
    this.playSecondaryAudio(audio);
  }

  /**
   * Init and play a secondary audio.
   * @param audio
   */
  playSecondaryAudio(audio) {
    if (this.secondaryAudioPlayer) {
      this.secondaryAudioPlayer.stop();
    }

    this.secondaryAudio = audio;
    this.secondaryAudioPlayer = new Howl({
      src: [audio.mp3],
      format: 'mp3', // to be able to execute audios from Locutor Virtual
      html5: true,
      autoplay: true,
      preload: true,
      volume: calculateVolume(audio.volume, this.volume),
      onplay: () => {
        this.isPaused = false;
        this.secondaryAudioCounter += 1;

        // Start timed song
        if (isStreaming(this.secondaryAudio)) {
          this.secondaryAudioStreamingTimeout = setTimeout(
            () => this.onEndSecondary(),
            this.secondaryAudio.time * 1000,
          );
        }
      },
      onend: () => this.onEndSecondary(),
      onloaderror: (_, error) => {
        this._warn(`[LOAD_ERROR][SEC]: ${error}.`, {
          title: audio.title,
          file: audio.mp3,
        });
        this.onEndSecondary();
      },
      onplayerror: (_, error) => {
        this._warn(`[PLAYER_ERROR][SEC]: ${error}.`, {
          title: audio.title,
          file: audio.mp3,
        });
        this.onEndSecondary();
      },
    });
  }

  /**
   * Executed when a secondary audio finishes.
   */
  onEndSecondary() {
    if (this.playlistSecondary.isLast()) {
      this.stopSecondary();
    } else {
      this.loadNextSecondaryAudio();
    }
  }

  /**
   * Clear all streaming timeouts.
   */
  clearStreamingTimeouts() {
    clearTimeout(this.mainAudioStreamingTimeout);
    clearTimeout(this.secondaryAudioStreamingTimeout);

    this.mainAudioStreamingTimeout = null;
    this.secondaryAudioStreamingTimeout = null;
  }

  /**
   * Change global/players volume.
   * @param {Number} - number from 0-100.
   * @returns {Number} - the new calculated volume 0-1.
   */
  setVolume(volume) {
    this.volume = volume / 100;
    window.localStorage.setItem('volume', this.volume);

    this.mainAudioPlayer?.volume(
      calculateVolume(this.mainAudio.volume, this.volume),
    );

    return this.volume;
  }

  /**
   * Play the mic audio interruption. It's used to pause the player's
   * main execution and let the user speak on the mic.
   */
  playMic() {
    this.mainAudioPlayer.pause();
    this.micPlayer =
      this.micPlayer ||
      new Howl({
        src: [SIGN_AUDIO_URL],
        html5: true,
        autoplay: true,
        preload: true,
        onend: this.stopMic,
      });
    this.micPlayer.play();
  }

  /**
   * Stop the mic audio interruption resuming the main execution.
   */
  stopMic() {
    this.mainAudioPlayer.play();
    this.micPlayer.stop();
  }

  /**
   * Play the main audio.
   */
  playMain() {
    this.mainAudioPlayer.play();
    this.isPaused = false;
  }

  /**
   * Pause the main audio.
   */
  pauseMain() {
    this.mainAudioPlayer.pause();
    this.isPaused = true;
  }

  /**
   * Restart the current main audio.
   */
  skipPrev() {
    this.mainAudioPlayer.stop();
    this.mainAudioPlayer.play();
  }

  /**
   * Load the next main audio.
   */
  async skipNext() {
    this.mainAudioPlayer.stop();
    await this.loadNextAudio();
  }

  /**
   * Add audio to the cache.
   */
  addToCache(audio) {
    if (!audio.cache || this.inCache(audio)) return;

    if (this.cachedAudios.length >= this.cacheSize) {
      this.cachedAudios.shift();
    }
    this.cachedAudios.push(audio);
  }

  /**
   * Check if the audio is already in the cache.
   */
  inCache(audio) {
    return (
      this.cachedAudios.findIndex(
        (cachedAudio) => cachedAudio.mp3 === audio.mp3,
      ) > -1
    );
  }

  /**
   * Update online status.
   */
  setOnline(online) {
    this.online = online;
  }
}

class Playlist {
  constructor(list) {
    this.list = list;
    this.index = null;
    this.current = null;
    this.cycles = 0;
  }

  _getNextIndex() {
    return this.index === null || this.isLast() ? 0 : this.index + 1;
  }

  isFirst() {
    return this.index === 0;
  }

  isLast() {
    return this.index === this.list.length - 1;
  }

  next() {
    this.index = this._getNextIndex();

    if (this.isLast()) {
      this.cycles += 1;
    }

    this.current = this.list[this.index];
    return this.current;
  }

  getNext() {
    const nextIndex = this._getNextIndex();
    return this.list[nextIndex];
  }

  reset(list) {
    this.list = list;
    this.index = null;
    this.current = null;
    this.cycles = 0;
  }

  merge(list) {
    this.list = [...this.list, ...list];
  }
}
