import { useState, useEffect } from 'react';
import * as apiClient from '../apiClient';

function useAuthPersonalizations(urlPerson) {
  const [isLoading, setIsLoading] = useState(false);
  const [userNotFound, setUserNotFound] = useState(false);
  const [personalizations, setPersonalizations] = useState({
    logo: '',
    bgLogin: '',
    name: '',
    showRepresentative: false,
    segments: [],
    displaySignUpInfo: false,
    signUpHeadTags: '',
    signUpBodyTags: '',
  });

  useEffect(() => {
    const fetchPersonalizations = async () => {
      let userPerson = null;
      setIsLoading(true);
      try {
        userPerson = await apiClient.getPersonalizations(urlPerson);
        setPersonalizations(userPerson);
      } catch (error) {
        setUserNotFound(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (urlPerson) {
      fetchPersonalizations();
    }
  }, [urlPerson]);

  return { personalizations, userNotFound, isLoading };
}

export default useAuthPersonalizations;
