import { useEffect } from 'react';
import PropTypes from 'prop-types';

function Snackbar({ children, open, onClose }) {
  useEffect(() => {
    if (!onClose) {
      return;
    }

    const closeTimeout = setTimeout(() => {
      onClose();
    }, 7000);

    return () => clearTimeout(closeTimeout);
  }, [onClose]);

  if (!open) {
    return null;
  }

  return (
    <div className="fixed z-50 flex justify-center top-3 right-3">
      {children}
    </div>
  );
}

Snackbar.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default Snackbar;
