export const circuitBreakerStates = {
  GREEN: 'GREEN',
  YELLOW: 'YELLOW',
  RED: 'RED',
};

class CircuitBreaker {
  constructor() {
    this.config = {
      maxFailures: 10,
      stateDelays: {
        [circuitBreakerStates.GREEN]: 0,
        [circuitBreakerStates.YELLOW]: 500, // 0.5s
        [circuitBreakerStates.RED]: 30000, // 30s
      },
    };

    this.state = circuitBreakerStates.GREEN;
    this.failureCount = 0;
    this.failureCountTotal = 0;
    this.delay = this.config.stateDelays[this.state];
  }

  log() {
    // console.table({
    //   State: this.state,
    //   Failures: this.failureCount,
    //   FailuresTotal: this.failureCountTotal,
    //   Delay: this.delay,
    // });
  }

  reset() {
    this.state = circuitBreakerStates.GREEN;
    this.failureCount = 0;
    this.failureCountTotal = 0;
    this.delay = this.config.stateDelays[this.state];
  }

  onSuccess() {
    this.reset();
    this.log();
  }

  onFailure() {
    this.failureCount += 1;
    this.failureCountTotal += 1;

    if (
      this.state === circuitBreakerStates.GREEN &&
      this.failureCount > this.config.maxFailures
    ) {
      this.state = circuitBreakerStates.YELLOW;
      this.failureCount = 0;
      this.delay = this.config.stateDelays[this.state];
    } else if (
      this.state === circuitBreakerStates.YELLOW &&
      this.failureCount > this.config.maxFailures * 2
    ) {
      this.state = circuitBreakerStates.RED;
      this.failureCount = 0;
      this.delay = this.config.stateDelays[this.state];
    }
    this.log();
  }

  is(...states) {
    return states.includes(this.state);
  }
}

export default CircuitBreaker;
