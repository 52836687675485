import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { getMenuItems } from './menuItems';
import * as apiClient from '../apiClient';

const initialSettings = {
  logo: '',
  bgLogin: '',
  bgPlayer: '',
  name: '',
  userName: '',
  mediaTypes: [],
  mediaTypesConfig: [],
  genres: [],
  ownGenres: [],
  contents: [],
  ownContents: [],
  favoritesEnabled: false,
  favoritesOnlyEnabled: false,
  enabledModules: [],
  requireModuleAuth: false,
};

const RadioContext = React.createContext({
  settingsReady: false,
  settings: initialSettings,
  totalUnreadMessages: 0,
  updateSettings: () => {},
  loadUnreadMessages: () => {},
  reloadSettings: () => {},
});

export function RadioContextProvider({ children }) {
  const [ready, setReady] = useState(false);
  const [settings, setSettings] = useState(initialSettings);
  const [isLoadingSettings, setIsLoadingSettings] = useState(false);

  const [totalUnreadMessages, setTotalUnreadMessages] = useState(0);
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [error, setError] = useState(null);

  const loadSettings = useCallback(async () => {
    setIsLoadingSettings(true);
    try {
      const data = await apiClient.getRadio();
      setSettings((currentSettings) => ({ ...currentSettings, ...data }));
      setReady(true);
    } catch (error) {
      setError('Failed to load setting.');
    } finally {
      setIsLoadingSettings(false);
    }
  }, []);

  const reloadSettings = useCallback(async () => {
    setReady(false);
    loadSettings();
  }, [loadSettings]);

  const loadUnreadMessages = useCallback(async () => {
    setIsLoadingMessages(true);
    try {
      const result = await apiClient.getMessages({ read: false });
      setTotalUnreadMessages(result.total);
    } catch (error) {
      setError('Failed to load messages.');
    } finally {
      setIsLoadingMessages(false);
    }
  }, []);

  const loadInitialData = useCallback(async () => {
    await loadSettings();
    await loadUnreadMessages();
  }, [loadSettings, loadUnreadMessages]);

  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  async function updateSettings(newSettings) {
    setSettings((currentSettings) => ({
      ...currentSettings,
      ...newSettings,
    }));
    await apiClient.updateRadioSettings(newSettings);
  }

  const [menuItems, enabledPaths, enabledControls] = useMemo(() => {
    const items = getMenuItems(settings);
    const paths = new Set();
    const controls = new Set(
      settings.enabledModules
        .filter((module) => module.type === 'control')
        .map((module) => module.id),
    );

    items.forEach((rootItem) => {
      if (rootItem.path) {
        paths.add(rootItem.path);
        if (rootItem.subModules) {
          rootItem.subModules.forEach((subModule) => {
            paths.add('/' + subModule.id);
          });
        }
      }
      if (rootItem.children) {
        rootItem.children.forEach((childItem) => {
          if (childItem.path) {
            paths.add(childItem.path);

            if (childItem.subModules) {
              childItem.subModules.forEach((subModule) => {
                paths.add('/' + subModule.id);
              });
            }
          }
        });
      }
    });
    return [items, paths, controls];
  }, [settings]);

  const findMenuItemByPath = (path) => {
    let root;
    let item;

    menuItems.forEach((rootItem) => {
      if (rootItem.path && rootItem.path === path) {
        root = rootItem;
      }
      if (rootItem.children) {
        rootItem.children.forEach((childItem) => {
          if (childItem.path && childItem.path === path) {
            root = rootItem;
            item = childItem;
          }
        });
      }
    });

    if (!root && !item) {
      return null;
    }

    return { root, item };
  };

  const isModuleEnabled = (path) => {
    return enabledPaths.has(path);
  };

  const isControlEnabled = (controlId) => {
    return enabledControls.has(controlId);
  };

  const context = {
    ready,
    error,
    settings,
    updateSettings,
    loadSettings,
    reloadSettings,
    isLoadingSettings,
    menuItems,
    findMenuItemByPath,
    isModuleEnabled,
    isControlEnabled,
    totalUnreadMessages,
    loadUnreadMessages,
    isLoadingMessages,
  };

  return (
    <RadioContext.Provider value={context}>{children}</RadioContext.Provider>
  );
}

export function useRadio() {
  const context = React.useContext(RadioContext);
  if (context === undefined) {
    throw new Error('useCount must be used within a RadioContextProvider');
  }
  return context;
}

export default RadioContext;
