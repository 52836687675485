import * as Yup from 'yup';
import { isDate } from 'date-fns';
import { toDate } from './datetime';

const YupCustom = {
  date: () =>
    Yup.date()
      .typeError('Informe uma data no formato dd/mm/aaaa.')
      .nullable()
      .transform((curr, orig) => {
        if (orig === '') return null;
        return isDate(orig) ? orig : toDate(orig, { withTime: false });
      }),
  time: () =>
    Yup.string()
      .matches(/\d{2}:\d{2}/, 'Informe um horário no formato hh:mm.')
      .test('time', 'Não é um horário válido.', (value) => {
        if (!value) {
          return false;
        }
        const [hour, min] = value.split(':').map((v) => parseInt(v, 10));
        return hour >= 0 && hour < 24 && min >= 0 && min < 60;
      }),
};

export default YupCustom;
