import CheckboxSquareIcon from '../../../icons/CheckboxSquare';
import CheckboxCheckedIcon from '../../../icons/CheckboxChecked';

function Checkbox({ checked, ...rest }) {
  const Icon = checked ? CheckboxCheckedIcon : CheckboxSquareIcon;

  return (
    <span className="relative inline-flex items-center w-5 h-5">
      <input
        type="checkbox"
        className="absolute top-0 left-0 w-full h-full p-0 m-0 opacity-0 cursor-pointer"
        {...rest}
      />
      <Icon
        className="w-full text-primary"
        aria-hidden="true"
        focusable="false"
      />
    </span>
  );
}

export default Checkbox;
