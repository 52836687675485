import { useEffect, useRef } from 'react';

function useIntersectionObserver({
  onIntersect,
  root = null,
  rootMargin = '0px',
  threshold = 0.99,
}) {
  const ref = useRef();

  useEffect(() => {
    let current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && onIntersect) {
          onIntersect();
        }
      },
      {
        root,
        rootMargin,
        threshold,
      },
    );
    if (ref.current) {
      current = ref.current;
      observer.observe(current);
    }

    return () => {
      if (!current) return;
      observer.unobserve(current);
    };
  }, [ref, onIntersect, root, rootMargin, threshold]);

  return ref;
}

export default useIntersectionObserver;
