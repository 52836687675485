import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { animated, useTransition } from 'react-spring';

import useClickOutside from '../../../hooks/useClickOutside';

import usePageRoute from '../../../hooks/usePageRoute';

const slideTransitions = {
  down: {
    from: { transform: 'translateY(100%)' },
    enter: { transform: 'translateY(0%)' },
    leave: { transform: 'translateY(100%)' },
  },
  up: {
    from: { transform: 'translateY(-100%)' },
    enter: { transform: 'translateY(0%)' },
    leave: { transform: 'translateY(-100%)' },
  },
  right: {
    from: { transform: 'translateX(100%)' },
    enter: { transform: 'translateX(0%)' },
    leave: { transform: 'translateX(100%)' },
  },
  left: {
    from: { transform: 'translateX(-100%)' },
    enter: { transform: 'translateX(0%)' },
    leave: { transform: 'translateX(-100%)' },
  },
};

const fadeTransition = {
  from: { opacity: 0 },
  enter: { opacity: 1 },
  leave: { opacity: 0 },
};

const Modal = React.forwardRef((props, ref) => {
  const {
    children,
    open = false,
    fullScreen = false,
    size = 'medium',
    slideDirection = 'down',
    className,
    onClose,
  } = props;

  const rootRef = useRef(null);
  useClickOutside(rootRef, onClose);

  const slideUp = useTransition(
    open,
    slideTransitions[slideDirection] || slideTransitions.down,
  );
  const fade = useTransition(open, fadeTransition);
  const transitions = fullScreen ? slideUp : fade;

  const handleBackdropClick = (event) => {
    if (event.target !== event.currentTarget) {
      return;
    }
    if (onClose) {
      onClose(event);
    }
  };

  const { getBottomClasses } = usePageRoute();
  const bottonClasses = fullScreen ? getBottomClasses() : '';

  return transitions(
    (styles, item) =>
      item && (
        <>
          {ReactDOM.createPortal(
            <animated.div
              className={clsx(
                'fixed z-100 inset-0 focus:outline-none',
                bottonClasses,
                className,
              )}
              role="presentation"
              tabIndex={0}
              style={styles}
              ref={ref || rootRef}
            >
              {fullScreen ? (
                <div className="flex items-center justify-center h-full">
                  <div
                    className="flex flex-col w-full h-full bg-white dark:bg-graySecondary"
                    role="dialog"
                    aria-modal="true"
                  >
                    {children}
                  </div>
                </div>
              ) : (
                <div className="block h-full text-center">
                  <div
                    className="fixed inset-0 transition-opacity"
                    aria-hidden="true"
                  >
                    <div
                      className="absolute inset-0 bg-black opacity-90"
                      onClick={handleBackdropClick}
                    ></div>
                  </div>
                  {!fullScreen && (
                    <span
                      className="inline-block h-full align-middle"
                      aria-hidden="true"
                    >
                      &#8203;
                    </span>
                  )}
                  <div
                    className={clsx(
                      'inline-block align-middle shadow-xl rounded-lg bg-white dark:bg-gray-1 text-left transform transition-all overflow-y-auto m-4 md:w-full',
                      size === 'small' && 'max-w-xl',
                      size === 'medium' && 'max-w-2xl',
                      size === 'large' && 'max-w-4xl',
                    )}
                    role="dialog"
                    aria-modal="true"
                    style={{ maxHeight: 'calc(100% - 2rem)' }}
                  >
                    {children}
                  </div>
                </div>
              )}
            </animated.div>,
            document.body,
          )}
        </>
      ),
  );
});

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  fullScreen: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default Modal;
