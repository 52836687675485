import React, { useRef } from 'react';
import { animated, useTransition } from '@react-spring/web';
import useClickOutside from '../../../hooks/useClickOutside';

function Menu({ open, onClose, children }) {
  const menuRef = useRef(null);
  useClickOutside(menuRef, onClose);

  const transitions = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return transitions(
    (styles, item) =>
      item && (
        <animated.div
          style={styles}
          className="absolute right-0 z-10 text-left rounded shadow-lg top-10 bg-blackSecondary w-36"
          ref={menuRef}
        >
          <ul role="menu" className="py-1">
            {children}
          </ul>
        </animated.div>
      ),
  );
}

export default Menu;
