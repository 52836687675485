import { useState, useEffect, useContext, useMemo, useRef } from 'react';
import clsx from 'clsx';
import Fuse from 'fuse.js';
import { Link } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { FaSearch, FaFilter } from 'react-icons/fa';

import RadioContext from '../RadioContext';

import PageHeader from '../common/PageHeader';
import Tab from '../common/Tab';

import Spinner from '../common/Spinner';
import Menu from '../common/Menu';
import MenuItem from '../common/MenuItem';
import Button from '../common/Button';

import CheckIcon from '../../icons/Check';
import ChevronLeftIcon from '../../icons/ChevronLeft';
import ChevronRightIcon from '../../icons/ChevronRight';

import usePageRoute from '../../hooks/usePageRoute';

import { addOrRemove } from '../../utils/list';

import * as apiClient from '../../apiClient';

const sideScroll = (element, speed, distance, step) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    element.scrollLeft += step;
    scrollAmount += Math.abs(step);
    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
    }
  }, speed);
};

function groupPlaylistsBySection(playlists) {
  return playlists
    .sort((a, b) => {
      const { order: orderA } = a.section || { order: 999 };
      const { order: orderB } = b.section || { order: 999 };
      if (orderA > orderB) {
        return 1;
      }
      if (orderA < orderB) {
        return -1;
      }
      return 0;
    })
    .reduce((curr, playlist) => {
      const section = playlist.section || { name: 'Playlists variadas' };
      const sectionName = section.name;

      const sectionPlaylist = curr.has(sectionName)
        ? curr.get(sectionName)
        : [];
      curr.set(sectionName, [...sectionPlaylist, playlist]);

      return curr;
    }, new Map());
}

function usePlaylists({
  type,
  selectedGenres,
  selectedOwnGenres,
  filters,
  search,
}) {
  const [loadedPlaylists, setLoadedPlaylists] = useState([]);
  const [playlists, setPlaylists] = useState([]);
  const [filteredPlaylists, setFilteredPlaylists] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadPlaylists = async () => {
      setIsLoading(true);
      const result = await apiClient.getPlaylists({ type });
      setLoadedPlaylists(result.items);
      setIsLoading(false);
    };

    loadPlaylists();
  }, [type]);

  useEffect(() => {
    const selectedPlaylistIds = [
      'playlist',
      'genre',
      'artist',
      'custom',
    ].includes(type)
      ? selectedGenres
      : selectedOwnGenres;

    setPlaylists(
      loadedPlaylists.map((playlist) => ({
        ...playlist,
        selected: selectedPlaylistIds.includes(playlist.id),
      })),
    );
  }, [type, selectedGenres, selectedOwnGenres, loadedPlaylists]);

  useEffect(() => {
    let filtered = null;

    if (filters && filters.display === 'selected') {
      filtered = playlists.filter((playlist) => playlist.selected);
    }

    if (search) {
      const fuse = new Fuse(filtered || playlists, {
        includeScore: true,
        includeMatches: true,
        threshold: 0.4,
        keys: ['name'],
      });
      const searchResults = fuse.search(search);
      filtered = searchResults.map((result) => result.item);
    }

    setFilteredPlaylists(filtered);
  }, [filters, search, playlists]);

  return {
    isLoading,
    playlists: filteredPlaylists ? filteredPlaylists : playlists,
  };
}

function ScrollNavButton({ className, direction, onClick }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={clsx(
        'focus:outline-none text-grayAlt px-1 md:hover:text-gray-400',
        className,
      )}
    >
      {direction === 'left' ? (
        <ChevronLeftIcon className="w-3 md:w-4" />
      ) : (
        <ChevronRightIcon className="w-3 md:w-4" />
      )}
    </button>
  );
}

function SectionItem(props) {
  const {
    title,
    imageUrl,
    frame = 'square',
    scrollable = false,
    isActive = false,
    onClick,
  } = props;

  const [over, setOver] = useState(false);
  // const [loaded, setLoaded] = useState(false);
  // const handleLoad = () => {
  //   setLoaded(true);
  // };

  return (
    <div
      className={clsx(
        'text-center cursor-pointer',
        scrollable && 'w-32 md:w-44 2xl:w-52 mr-4',
      )}
      onClick={onClick}
      onMouseEnter={() => setOver(true)}
      onMouseLeave={() => setOver(false)}
    >
      <div
        className={clsx('relative overflow-hidden w-full', {
          'rounded-3xl': frame === 'rounded',
          'rounded-full': frame === 'circle',
        })}
      >
        <img
          src={imageUrl}
          width="208"
          height="208"
          loading="lazy"
          className={clsx(
            'w-full transition-opacity',
            // loaded ? "opacity-100" : "opacity-0"
          )}
          alt={title}
          title={title}
          // onLoad={handleLoad}
        />
        {isActive && (
          <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-primary bg-opacity-70">
            <CheckIcon className="z-10 w-12 h-12 shadow-sm" />
          </div>
        )}
        {over && (
          <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-grayAlt bg-opacity-70"></div>
        )}
      </div>
      <span className="block pt-2 text-sm md:font-medium">{title}</span>
    </div>
  );
}

function SectionList({ title, children, scrollable = false }) {
  const scrollableRef = useRef(null);
  const [showScrollNav, setShowScrollNav] = useState(false);

  const handleScrollLeft = () => {
    sideScroll(scrollableRef.current, 10, 100, -200);
  };

  const handleScrollRight = () => {
    sideScroll(scrollableRef.current, 10, 100, 200);
  };

  const checkSizes = useDebouncedCallback(() => {
    if (!scrollableRef.current) return;
    const containerWidth = scrollableRef.current.offsetWidth;
    const items = scrollableRef.current.children[0].children;
    const totalItems = items.length;
    const itemWidth = items[0].offsetWidth;
    if (totalItems * (itemWidth + 16) - 16 > containerWidth) {
      setShowScrollNav(true);
    } else {
      setShowScrollNav(false);
    }
  });

  useEffect(() => {
    if (!scrollable) return;

    checkSizes();
    window.addEventListener('resize', checkSizes);

    return () => window.removeEventListener('resize', checkSizes);
  }, [scrollable, checkSizes]);

  useEffect(() => {
    if (!scrollable) return;

    checkSizes();
  }, [scrollable, checkSizes, children.length]);

  return (
    <section className="pt-6 border-b md:py-6 last:border-b-0 border-grayAlt">
      {title && (
        <div className="flex items-center mb-3">
          <h2 className="flex-1 text-xl md:text-2xl">{title}</h2>
          {showScrollNav && (
            <nav className="h-full">
              <ScrollNavButton direction="left" onClick={handleScrollLeft} />
              <ScrollNavButton direction="right" onClick={handleScrollRight} />
            </nav>
          )}
        </div>
      )}
      {scrollable ? (
        <div
          className="flex py-4 overflow-x-scroll hide-scroll-bar md:py-0"
          ref={scrollableRef}
        >
          <div className="flex flex-nowrap">{children}</div>
        </div>
      ) : (
        <div className="section-list-grid md:section-list-grid-md">
          {children}
        </div>
      )}
    </section>
  );
}

function Filters({ onChangeFilters }) {
  const [open, setOpen] = useState(false);
  const [filterDisplay, setFilterDisplay] = useState('all');

  const handleClickFilters = () => {
    setOpen((current) => !current);
  };

  const handleCloseFilters = () => {
    setOpen(false);
  };

  const handleClickFilter = (value) => () => {
    setFilterDisplay(value);
    handleCloseFilters();
    onChangeFilters(value);
  };

  return (
    <div className="relative flex items-center justify-center w-10">
      <button
        type="button"
        className={clsx(
          'p-3 rounded-full focus:outline-none hover:bg-grayAlt flex items-center justify-center',
          filterDisplay === 'selected' && 'text-primary',
        )}
        onClick={handleClickFilters}
      >
        <FaFilter />
      </button>
      <Menu open={open} onClose={handleCloseFilters}>
        <MenuItem onClick={handleClickFilter('all')}>Todos</MenuItem>
        <MenuItem onClick={handleClickFilter('selected')}>
          Selecionados
        </MenuItem>
      </Menu>
    </div>
  );
}

function Songs() {
  const {
    settings: radioSettings,
    updateSettings,
    isModuleEnabled,
  } = useContext(RadioContext);
  const { modulePath } = usePageRoute();

  const enabledTabs = new Set(
    ['system', 'custom', 'songfolder'].filter((tabName) =>
      isModuleEnabled(`${modulePath}/${tabName}`),
    ),
  );

  let initialTab;
  if (enabledTabs.has('system')) {
    initialTab = 'playlist';
  } else if (enabledTabs.has('custom')) {
    initialTab = 'custom';
  } else {
    initialTab = 'songfolder';
  }

  const selectedGenres = radioSettings.genres;
  const selectedOwnGenres = radioSettings.ownGenres;

  const [filters, setFilters] = useState();
  const [search, setSearch] = useState('');
  const [activeTab, setActiveTab] = useState(initialTab);
  const { isLoading, playlists } = usePlaylists({
    type: activeTab,
    selectedGenres,
    selectedOwnGenres,
    filters,
    search,
  });

  const handleSelect = (playlist) => () => {
    let newGenres = [...selectedGenres];
    let newOwnGenres = [...selectedOwnGenres];

    if (playlist.type === 'songfolder') {
      newOwnGenres = addOrRemove(newOwnGenres, playlist.id);
    } else {
      newGenres = addOrRemove(newGenres, playlist.id);
    }

    updateSettings({ genres: newGenres, ownGenres: newOwnGenres });
  };

  const handleClickTab = (tabId) => () => {
    setActiveTab(tabId);
  };

  const handleChangeFilters = (filterDisplay) => {
    setFilters({ display: filterDisplay });
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  return (
    <div>
      <PageHeader title="Músicas" />
      <div className="relative">
        <div className="p-6 md:px-10 md:pb-8 md:pt-0">
          <div className="flex flex-col justify-between md:flex-row md:items-center">
            <nav className="flex-1">
              {enabledTabs.has('system') && (
                <>
                  <Tab
                    active={activeTab === 'playlist'}
                    onClick={handleClickTab('playlist')}
                  >
                    Playlists
                  </Tab>
                  <Tab
                    active={activeTab === 'genre'}
                    onClick={handleClickTab('genre')}
                  >
                    Gêneros
                  </Tab>
                  <Tab
                    active={activeTab === 'artist'}
                    onClick={handleClickTab('artist')}
                  >
                    Artistas
                  </Tab>
                </>
              )}
              {enabledTabs.has('custom') && (
                <Tab
                  active={activeTab === 'custom'}
                  onClick={handleClickTab('custom')}
                >
                  Playlists Personalizadas
                </Tab>
              )}
              {enabledTabs.has('songfolder') && (
                <Tab
                  active={activeTab === 'songfolder'}
                  onClick={handleClickTab('songfolder')}
                >
                  Pastas de Músicas
                </Tab>
              )}
            </nav>
            <div className="flex items-center md:w-72">
              <div className="flex items-center flex-1 w-full border-b border-grayAlt md:ml-5 md:mx-3">
                <FaSearch className="ml-2 text-gray-400" />
                <input
                  type="text"
                  id="search"
                  name="search"
                  placeholder="Buscar..."
                  autoComplete="off"
                  className="bg-transparent font-light outline-none w-full py-0.5 px-3"
                  value={search}
                  onChange={handleChangeSearch}
                />
              </div>
              <Filters onChangeFilters={handleChangeFilters} />
            </div>
          </div>
          {isLoading ? (
            <div className="flex items-center justify-center h-64">
              <Spinner className="w-12 h-12" />
            </div>
          ) : (
            <>
              {playlists.length === 0 && (
                <p className="mt-4 text-lg">Nenhum resultado encontrado.</p>
              )}
              {activeTab === 'playlist' && (
                <Playlists playlists={playlists} onSelect={handleSelect} />
              )}
              {activeTab === 'genre' && (
                <Genres playlists={playlists} onSelect={handleSelect} />
              )}
              {activeTab === 'artist' && (
                <Artists playlists={playlists} onSelect={handleSelect} />
              )}
              {activeTab === 'custom' && (
                <CustomPlaylists
                  playlists={playlists}
                  onSelect={handleSelect}
                />
              )}
              {activeTab === 'songfolder' && (
                <SongFolders playlists={playlists} onSelect={handleSelect} />
              )}
            </>
          )}
        </div>
        {radioSettings.favoritesOnlyEnabled && (
          <div className="absolute inset-0 bg-black opacity-90 z-100">
            <div className="flex justify-center mt-52">
              <div className="text-center px-6 md:max-w-lg">
                <p className="mt-2 text-lg">
                  Para selecionar outras playlists desabilite o modo{' '}
                  <span className="font-bold">Tocar apenas favoritas</span>.
                  Clique no botão abaixo para configurar.
                </p>
                <div className="mt-3 text-lg">
                  <Link to="/player/configuracoes/musicas-favoritas">
                    <Button color="primary">Ok</Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function Playlists({ playlists, onSelect }) {
  const playlistBySection = useMemo(
    () => groupPlaylistsBySection(playlists),
    [playlists],
  );

  return [...playlistBySection.entries()].map(
    ([sectionName, sectionPlaylists]) => (
      <SectionList
        scrollable
        title={sectionName}
        key={`section-${sectionName}`}
      >
        {sectionPlaylists.map((item) => (
          <SectionItem
            key={item.id}
            imageUrl={item.cover}
            title={item.name}
            onClick={onSelect(item)}
            isActive={item.selected}
            scrollable
          />
        ))}
      </SectionList>
    ),
  );
}

function Genres({ playlists, onSelect }) {
  return (
    <SectionList>
      {playlists.map((item) => (
        <SectionItem
          key={item.id}
          imageUrl={item.cover}
          title={item.name}
          onClick={onSelect(item)}
          isActive={item.selected}
          frame="rounded"
        />
      ))}
    </SectionList>
  );
}

function Artists({ playlists, onSelect }) {
  return (
    <SectionList>
      {playlists.map((item) => (
        <SectionItem
          key={item.id}
          imageUrl={item.cover}
          title={item.name}
          onClick={onSelect(item)}
          isActive={item.selected}
          frame="circle"
        />
      ))}
    </SectionList>
  );
}

function CustomPlaylists({ playlists, onSelect }) {
  const playlistBySection = useMemo(
    () => groupPlaylistsBySection(playlists),
    [playlists],
  );

  return [...playlistBySection.entries()].map(
    ([sectionName, sectionPlaylists]) => (
      <SectionList
        scrollable
        title={sectionName}
        key={`section-${sectionName}`}
      >
        {sectionPlaylists.map((item) => (
          <SectionItem
            key={item.id}
            imageUrl={item.cover}
            title={item.name}
            onClick={onSelect(item)}
            isActive={item.selected}
            scrollable
          />
        ))}
      </SectionList>
    ),
  );
}

function SongFolders({ playlists, onSelect }) {
  return (
    <>
      <SectionList>
        {playlists.map((item) => (
          <SectionItem
            key={item.id}
            imageUrl={item.cover}
            title={item.name}
            onClick={onSelect(item)}
            isActive={item.selected}
            frame="rounded"
          />
        ))}
      </SectionList>
    </>
  );
}

export default Songs;
