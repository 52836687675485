import { useState, useEffect, useMemo } from 'react';

import PageHeader from '../common/PageHeader';
import Button from '../common/Button';
import IconButton from '../common/IconButton';
import { List, ListItem, ListItemActions, ListItemText } from '../common/List';
import SongSearchDialog from '../common/SongSearchDialog';

import BanIcon from '../../icons/Ban';
import TrashIcon from '../../icons/Trash';

import { getSongMeta } from '../../utils/songFile';

import * as apiClient from '../../apiClient';

function SongLocksCall() {
  const [locks, setLocks] = useState([]);
  const [isAddLockOpen, setIsAddLockOpen] = useState(false);

  const loadLocks = async () => {
    const response = await apiClient.getSongLocks();
    setLocks(response.items);
  };

  useEffect(() => {
    loadLocks();
  }, []);

  const handleRemoveLock = (lockId) => async () => {
    await apiClient.unlockSong(lockId);
    setLocks((locks) => locks.filter((lock) => lock.id !== lockId));
  };

  const handleAddLock = (song) => async () => {
    const newLock = await apiClient.lockSong({
      songId: song.id,
      collection: song.collection,
    });
    setLocks((locks) => [...locks, newLock]);
  };

  const handleOpenAddLock = () => {
    setIsAddLockOpen(true);
  };

  const handleCloseAddLock = () => {
    setIsAddLockOpen(false);
    loadLocks();
  };

  const locksMap = useMemo(() => {
    const lockMap = new Map();
    locks.forEach((lock) => {
      lockMap.set(lock.songId, lock.id);
    });
    return lockMap;
  }, [locks]);

  return (
    <div>
      <PageHeader title="Bloqueio de Músicas" />
      <div className="p-6 md:px-10 md:pb-10 md:pt-0">
        <SongSearchDialog
          open={isAddLockOpen}
          onClose={handleCloseAddLock}
          renderActions={(song) =>
            locksMap.has(song.id) ? (
              <IconButton
                title="Remover bloqueio"
                color="primary"
                onClick={handleRemoveLock(locksMap.get(song.id))}
              >
                <BanIcon />
              </IconButton>
            ) : (
              <IconButton
                title="Adicionar bloqueio"
                onClick={handleAddLock(song)}
              >
                <BanIcon />
              </IconButton>
            )
          }
        />

        <div className="flex flex-col justify-between pb-5 border-b-2 md:flex-row md:items-center border-grayAlt">
          <div className="flex-1 mb-5 md:mb-0">
            <h2 className="text-2xl font-semibold">Seus bloqueios</h2>
            <p className="text-gray-400">{locks.length} bloqueadas</p>
          </div>

          <Button color="primary" onClick={handleOpenAddLock}>
            Adicionar
          </Button>
        </div>

        <div className="flex-1 overflow-y-auto">
          {locks.length === 0 ? (
            <p className="py-4">Nenhum bloqueio encontrado.</p>
          ) : (
            <List>
              {locks.map((lock) => {
                const { category, title, artist } = getSongMeta(lock);
                const secondaryText = artist
                  ? `${artist} • ${category}`
                  : category;

                return (
                  <ListItem key={`lock-${lock.id}`} title={lock.fileName}>
                    <ListItemText
                      primary={title}
                      secondary={secondaryText}
                      className="line-through"
                    />
                    <ListItemActions>
                      <IconButton onClick={handleRemoveLock(lock.id)}>
                        <TrashIcon />
                      </IconButton>
                    </ListItemActions>
                  </ListItem>
                );
              })}
            </List>
          )}
        </div>
      </div>
    </div>
  );
}

export default SongLocksCall;
