import { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { animated, useTransition } from '@react-spring/web';

import ScreenLayer from '../../common/ScreenLayer';

import ProfileIcon from '../../../icons/Profile';
import LogoutIcon from '../../../icons/Logout';

import { GrTextAlignFull } from 'react-icons/gr';

import useClickOutside from '../../../hooks/useClickOutside';
import { useAuth } from '../../../hooks/useAuth';

import logoImg from '../../../images/logo.svg';

function HomeHeader({ showLogo = false, rootPath = '/player' }) {
  const { user } = useAuth();
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isProfileOpenMobile, setIsProfileOpenMobile] = useState(false);

  const profileRef = useRef(null);
  useClickOutside(profileRef, () => setIsProfileOpen(false));

  const handleToggleProfile = () => {
    setIsProfileOpen((open) => !open);
  };

  const handleToggleProfileMobile = () => {
    setIsProfileOpenMobile((open) => !open);
  };

  return (
    <div className="absolute z-10 flex items-center justify-between w-full p-6">
      <div className="relative flex items-center">
        <Link to={rootPath} className={!showLogo ? 'md:hidden' : null}>
          <img src={logoImg} alt="RadioSrv" width="28" height="34" />
        </Link>
      </div>
      <div className="flex items-center">
        <div className="relative" ref={profileRef}>
          <button
            className="items-center hidden max-w-lg p-1 bg-black rounded-full focus:outline-none md:flex"
            onClick={handleToggleProfile}
          >
            {user && (
              <span className="px-3 truncate">
                {user.isSimulation && '[Simulação]'} {user.name}
              </span>
            )}
            <ProfileIcon className="w-8 md:w-10" />
          </button>
          <ProfileMenu open={isProfileOpen} rootPath={rootPath} />

          <button
            className="flex items-center focus:outline-none md:hidden"
            onClick={handleToggleProfileMobile}
          >
            <ProfileIcon className="w-8 md:w-10" />
          </button>
          <ScreenLayer
            slideDirection="left"
            title="Minha rádio"
            open={isProfileOpenMobile}
            onClose={() => setIsProfileOpenMobile(false)}
          >
            <ProfileContent rootPath={rootPath} />
          </ScreenLayer>
        </div>
      </div>
    </div>
  );
}

function ProfileMenu({ open, rootPath }) {
  const transitions = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return transitions(
    (styles, item) =>
      item && (
        <animated.div
          style={styles}
          className="absolute right-0 text-left rounded shadow-lg top-14 bg-blackSecondary w-60"
        >
          <ProfileContent rootPath={rootPath} />
        </animated.div>
      ),
  );
}

function ProfileContent({ rootPath }) {
  const { user, signout } = useAuth();
  return (
    <>
      <div className="px-6 py-4 border-b md:px-4 md:py-3 border-grayAlt">
        <span className="block mb-1 text-sm font-light">Licenciado para:</span>
        <span className="font-normal">{user.name}</span>
      </div>
      <div className="flex flex-col px-6 py-4 font-light md:px-4 md:py-3">
        <ProfileMenuItem title="Sobre" to={`${rootPath}/sobre`}>
          <GrTextAlignFull />
        </ProfileMenuItem>
        <ProfileMenuItem title="Sair" to="#" onClick={signout}>
          <LogoutIcon />
        </ProfileMenuItem>
      </div>
    </>
  );
}

function ProfileMenuItem({ children, title, ...rest }) {
  return (
    <Link className="flex items-center py-2" {...rest}>
      {children && <div className="w-4 mr-3">{children}</div>}
      <span>{title}</span>
    </Link>
  );
}

export default HomeHeader;
