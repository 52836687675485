import ReactDOM from 'react-dom';
import { useState, useRef } from 'react';
import clsx from 'clsx';
import { animated, useTransition } from 'react-spring';

import VolumeUpIcon from '../../../icons/VolumeUp';
import VolumeMuteIcon from '../../../icons/VolumeMute';
import MicIcon from '../../../icons/Mic';
import StopIcon from '../../../icons/Stop';

import Slider from '../../common/Slider';
import Pulsate from '../../common/Pulsate';

import PlayerProgressBar from './PlayerProgressBar';
import PlayerControls, { PlayerControlsCompact } from './PlayerControls';

import useClickOutside from '../../../hooks/useClickOutside';
import { useRadio } from '../../RadioContext';
import { useAudioPlayer } from './Player.context';
import { PLAYER_STATUSES } from './Player.constants';

function PlayerBar() {
  const {
    playerState: { volume, status: playerStatus, mainAudio },
    togglePlayPause,
    toggleMuteVolume,
    toggleMic,
    setVolume,
    skipPrev,
    skipNext,
    stopSecondary,
  } = useAudioPlayer();

  const { isControlEnabled } = useRadio();
  const [isCompact, setIsCompact] = useState(true);

  const rootRef = useRef(null);
  useClickOutside(rootRef, () => setIsCompact(true));

  const isPlayingMain = playerStatus === PLAYER_STATUSES.PLAYING_MAIN;
  const isFading = playerStatus === PLAYER_STATUSES.FADING_MAIN;
  const isStreaming = mainAudio.streaming;
  const isPlayingMic = playerStatus === PLAYER_STATUSES.PLAYING_MIC;
  const isPlayingSecondary = playerStatus === PLAYER_STATUSES.PLAYING_SECONDARY;
  const displayVoiceOver = isControlEnabled('mic');
  const displayVolume = isControlEnabled('volume');

  const isDisabled =
    isPlayingSecondary || isPlayingMic || isFading || isStreaming;
  const isVolumeDisabled = isPlayingSecondary || isPlayingMic || isFading;

  let disabledMessage = null;
  if (isPlayingSecondary) {
    disabledMessage = 'Reproduzindo chamada...';
  }
  if (isPlayingMic) {
    disabledMessage = 'Reproduzindo locução...';
  }

  const handleClickMobile = () => {
    setIsCompact((compact) => !compact);
  };

  const handleClickMic = (event) => {
    if (isFading || isStreaming) {
      return;
    }
    toggleMic(event);
  };

  const transitions = useTransition(!isCompact, {
    from: { transform: 'translateY(100%)' },
    enter: { transform: 'translateY(0%)' },
    leave: { transform: 'translateY(100%)' },
  });

  const fullBar = (
    <>
      <div className="max-w-4xl md:flex-1 md:order-2 md:flex md:flex-col md:mb-7 md:mx-12 md:min-w-0">
        <AudioTitle
          title={mainAudio.title}
          subtitle={mainAudio.artist}
          disabledMessage={disabledMessage}
        />
        <PlayerProgressBar disabled={isDisabled || mainAudio.streaming} />
      </div>
      <div className="flex justify-center flex-1 md:flex-initial md:order-1 md:mr-8">
        <PlayerControls
          isPlaying={isPlayingMain}
          onTogglePlayPause={togglePlayPause}
          onClickPrev={skipPrev}
          onClickNext={skipNext}
          disabled={isDisabled}
        />
      </div>
      <div className="hidden md:block md:order-3 md:ml-8">
        {displayVoiceOver && displayVolume && (
          <div className="flex items-center justify-end w-full h-full md:w-80">
            {displayVolume && (
              <VolumeControl
                volume={volume}
                onChange={setVolume}
                onToggleMute={toggleMuteVolume}
                disabled={isVolumeDisabled}
              />
            )}
            {isPlayingSecondary ? (
              <StopSecondaryButton onClick={stopSecondary} />
            ) : (
              displayVoiceOver && (
                <VoiceOverControl
                  active={isPlayingMic}
                  onClick={handleClickMic}
                />
              )
            )}
          </div>
        )}
      </div>
    </>
  );

  return (
    <div className="fixed left-0 w-full h-20 bottom-16 bg-blackSecondary z-80 md:z-90 md:bottom-0 md:h-24">
      {/* Desktop bar */}
      <div
        className={
          'hidden h-full flex-col p-3 justify-between md:flex md:px-10 md:pt-7 md:pb-3 md:flex-row md:items-center'
        }
      >
        {fullBar}
      </div>
      {/* Mobile compact bar */}
      <div className="h-full md:hidden" onClick={handleClickMobile}>
        <PlayerProgressBar
          disabled={isDisabled || mainAudio.streaming}
          compact
        />
        <div className="flex items-center h-full px-5">
          <div className="mr-5">
            <PlayerControlsCompact
              isPlaying={isPlayingMain}
              onTogglePlayPause={togglePlayPause}
              disabled={isDisabled}
            />
          </div>
          <div className="flex-1 min-w-0">
            <AudioTitle
              disabledMessage={disabledMessage}
              title={mainAudio.title}
              subtitle={mainAudio.artist}
              compact
            />
          </div>
          <div className="ml-5">
            {isPlayingSecondary ? (
              <StopSecondaryButton onClick={stopSecondary} size="small" />
            ) : (
              displayVoiceOver && (
                <VoiceOverControl
                  active={isPlayingMic}
                  onClick={handleClickMic}
                  size="small"
                />
              )
            )}
          </div>
        </div>
      </div>
      {/* Mobile expanded bar */}
      {transitions(
        (styles, item) =>
          item && (
            <animated.div
              className={
                'fixed left-0 bottom-16 w-full h-48 flex bg-black z-90 flex-col p-3 justify-between'
              }
              ref={rootRef}
              style={styles}
            >
              {ReactDOM.createPortal(
                <div
                  className="fixed inset-0 z-10 transition-opacity"
                  aria-hidden="true"
                >
                  <div className="absolute inset-0 bg-black opacity-75"></div>
                </div>,
                document.body,
              )}
              {fullBar}
            </animated.div>
          ),
      )}
    </div>
  );
}

function AudioTitle({ title, subtitle, compact, disabledMessage }) {
  return (
    <div
      className={clsx(
        'font-normal w-full h-10 my-2 max-w-4xl flex items-center md:h-auto md:mb-1 md:justify-start',
        compact ? 'justify-start' : 'justify-center text-center md:text-left',
      )}
    >
      <>
        {disabledMessage ? (
          <p>{disabledMessage || 'Aguardando...'}</p>
        ) : (
          <div className="min-w-0 md:flex md:items-center">
            <div className="truncate md:order-2 md:flex-1">
              {title || 'Carregando...'}
            </div>
            {subtitle && (
              <div className="text-xs text-gray-400 truncate md:order-1 md:text-base md:max-w-xs md:mr-2">
                {subtitle}
              </div>
            )}
          </div>
        )}
      </>
    </div>
  );
}

function VolumeControl({ volume, onChange, onToggleMute, disabled }) {
  return (
    <div className="flex items-center w-full md:w-5/6">
      <button
        className={clsx(
          'mr-6 focus:outline-none flex items-center',
          disabled && 'cursor-default',
        )}
        onClick={!disabled ? onToggleMute : null}
      >
        <Pulsate active={volume === 0}>
          {volume === 0 ? (
            <VolumeMuteIcon className="text-gray-600 w-7" />
          ) : (
            <VolumeUpIcon
              className={clsx(
                'w-7',
                disabled
                  ? 'text-gray-600'
                  : 'text-primary hover:text-primary-hover',
              )}
            />
          )}
        </Pulsate>
      </button>
      <Slider
        value={volume * 100}
        min={0}
        max={100}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
}

function VoiceOverControl({ onClick, active, size }) {
  return (
    <button
      className="md:ml-14 focus:outline-none md:w-12 md:h-12"
      onClick={onClick}
      title={active ? 'Parar locução' : 'Iniciar locução'}
    >
      <Pulsate active={active} size={size}>
        <MicIcon
          className={clsx(
            'w-5',
            active ? 'text-primary hover:text-primary-hover' : 'text-gray-600',
          )}
        />
      </Pulsate>
    </button>
  );
}

function StopSecondaryButton({ onClick, size }) {
  return (
    <button
      className="focus:outline-none md:ml-14 md:w-12 md:h-12"
      onClick={onClick}
      title="Terminar chamada"
    >
      <Pulsate size={size} active>
        <StopIcon className="w-8 text-primary hover:text-primary-hover" />
      </Pulsate>
    </button>
  );
}

export default PlayerBar;
