import { useContext } from 'react';
import Badge from '../Badge';

import RadioContext from '../../RadioContext';

function MessagesBadge() {
  const { totalUnreadMessages } = useContext(RadioContext);

  if (totalUnreadMessages === 0) {
    return null;
  }

  return (
    <Badge
      className={
        'absolute transform translate-x-1/2 -translate-y-1/2 md:top-0 md:right-7'
      }
    >
      {totalUnreadMessages}
    </Badge>
  );
}

export default MessagesBadge;
