import { useField } from 'formik';

import FormField from '../FormField';
import Label from '../Label';
import Toggle from '../Toggle';
import ErrorText from '../ErrorText';

function ToggleField(props) {
  const { label, noMargin, ...toggleProps } = props;
  const [field, meta] = useField(props);
  const hasError = Boolean(meta.touched && meta.error);

  return (
    <FormField noMargin={noMargin}>
      <Label
        htmlFor={toggleProps.id || toggleProps.name}
        className="flex items-center"
      >
        <Toggle
          {...field}
          {...toggleProps}
          error={hasError}
          checked={field.value}
        />
        {label && <span className="ml-3">{label}</span>}
      </Label>

      {hasError ? <ErrorText>{meta.error}</ErrorText> : null}
    </FormField>
  );
}

export default ToggleField;
