import Overlay from '../Overlay';

function Offline() {
  return (
    <Overlay open>
      <div className="text-center">
        <p className="font-bold text-4xl lg:text-4.5xl">
          Sem conexão com a internet!
        </p>
        <p className="mt-2 text-lg">Tentando reconectar...</p>
      </div>
    </Overlay>
  );
}

export default Offline;
