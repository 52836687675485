import clsx from 'clsx';
import PlayIcon from '../../../icons/Play';
import PauseIcon from '../../../icons/Pause';
import PrevIcon from '../../../icons/SkipPrev';
import NextIcon from '../../../icons/SkipNext';

import { useRadio } from '../../RadioContext';

function PlayerControls({
  isPlaying,
  onTogglePlayPause,
  onClickPrev,
  onClickNext,
  disabled,
}) {
  const { isControlEnabled } = useRadio();
  const isPlayerNavEnabled = isControlEnabled('playernav');

  return (
    <div
      className={clsx(
        'flex items-center',
        disabled ? 'text-gray-600' : 'text-primary',
      )}
    >
      {isPlayerNavEnabled && (
        <button
          className={clsx(
            'w-7 h-7 drop-shadow focus:outline-none',
            disabled ? 'cursor-default' : 'hover:text-primary-hover',
          )}
          onClick={disabled ? null : onClickPrev}
        >
          <PrevIcon />
        </button>
      )}
      <button
        className={clsx(
          'w-12 h-12 drop-shadow mx-8 focus:outline-none',
          disabled ? 'cursor-default' : 'hover:text-primary-hover',
        )}
        onClick={disabled ? null : onTogglePlayPause}
      >
        {isPlaying ? <PauseIcon /> : <PlayIcon />}
      </button>
      {isPlayerNavEnabled && (
        <button
          className={clsx(
            'w-7 h-7 drop-shadow focus:outline-none',
            disabled ? 'cursor-default' : 'hover:text-primary-hover',
          )}
          onClick={disabled ? null : onClickNext}
        >
          <NextIcon />
        </button>
      )}
    </div>
  );
}

export function PlayerControlsCompact({
  isPlaying,
  onTogglePlayPause,
  disabled,
}) {
  return (
    <div
      className={clsx(
        'flex items-center',
        disabled ? 'text-gray-600' : 'text-primary',
      )}
    >
      <button
        className={clsx(
          'w-8 h-8 drop-shadow focus:outline-none',
          disabled && 'cursor-default',
        )}
        onClick={disabled ? null : onTogglePlayPause}
      >
        {isPlaying ? <PauseIcon /> : <PlayIcon />}
      </button>
    </div>
  );
}

export default PlayerControls;
