import clsx from 'clsx';

function TextArea({ className, error = false, ...props }) {
  return (
    <textarea
      {...props}
      id={props.id || props.name}
      className={clsx(
        'w-full p-3 border-2 rounded-md focus:outline-none focus:border-gray-500 dark:bg-transparent dark:border-gray-400 dark:focus:border-gray-200',
        {
          'border-red-600 hover:border-red-600 focus:border-red-600 dark:border-red-600 dark:hover:border-red-600 dark:focus:border-red-600':
            error,
        },
        className,
      )}
    ></textarea>
  );
}

export default TextArea;
