import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';

import MainMenu from '../../common/MainMenu';
import Home from './Home';

import ModuleRoutes from '../../modules/ModuleRoutes';
import { RadioContextProvider } from '../../RadioContext';

import { useAuth } from '../../../hooks/useAuth';

function RemoteControl() {
  const { user } = useAuth();
  const { path } = useRouteMatch();

  useEffect(() => {
    document.title = `${user.name} - Controle Remoto`;
  }, [user]);

  return (
    <RadioContextProvider>
      <div className="w-full h-full max-w-full overflow-hidden bg-graySecondary">
        <div className="flex flex-wrap w-full h-full pb-16 md:pb-0">
          <aside className="h-full">
            <MainMenu />
          </aside>
          <main className="relative flex flex-col flex-1 h-full overflow-hidden">
            <div className="h-full max-h-full overflow-y-auto">
              <ModuleRoutes path={path}>
                <Home />
              </ModuleRoutes>
            </div>
          </main>
        </div>
      </div>
    </RadioContextProvider>
  );
}

export default RemoteControl;
