import { API_URL } from '../conf';
import { getUser } from './auth';

const LOGS_URL = `${API_URL}/api/logs`;

export function info(message, extra = {}) {
  const userAuth = getUser();

  const body = JSON.stringify({ message, ...extra });
  return fetch(LOGS_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': userAuth ? userAuth.accessToken : '',
    },
    body,
  }).catch((rejectionReason) => {
    console.error(
      `${rejectionReason}. Request to ${LOGS_URL} failed. Request payload: ${body}.`,
    );
  });
}
