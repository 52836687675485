function isDev() {
  return process.env.NODE_ENV !== 'production';
}

const HOST = process.env.REACT_APP_API_HOST || 'app.radios.srv.br';

export const API_URL = isDev()
  ? 'http://localhost:3001/'
  : `https://${HOST}/g/`;

export const SOCKET_IO_URL = isDev()
  ? 'http://localhost:3001'
  : `https://${HOST}`;

export const SOCKET_IO_PATH = isDev() ? '/socket.io' : '/g/socket.io';

export const SIGN_AUDIO_URL = 'https://radios.srv.br/music/sinalLongo.m4a';

export const ONLINE_URL = '/online.jpg';
