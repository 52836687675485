import PropTypes from 'prop-types';

function ErrorText({ children }) {
  return <span className="text-sm text-red-600">{children}</span>;
}

ErrorText.propTypes = {
  children: PropTypes.node,
};

export default ErrorText;
