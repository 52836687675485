import { useState, useEffect, useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { FaSearch } from 'react-icons/fa';

import Modal from '../Modal';
import Button from '../Button';
import Spinner from '../Spinner';
import { List, ListItem, ListItemActions, ListItemText } from '../List';

import CancelIcon from '../../../icons/Cancel';

import { getSongMeta } from '../../../utils/songFile';

import { useDebounce } from 'use-debounce';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';

import * as apiClient from '../../../apiClient';

const perPage = 20;

function SongSearchDialog({ open, onClose, renderActions }) {
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);
  const [isSearching, setIsSearching] = useState(false);
  const [suggestions, setSuggestions] = useState(null);
  const [page, setPage] = useState(1);
  const [hasNext, setHasNext] = useState(false);

  const searchSongs = async (search) => {
    setIsSearching(true);
    const response = await apiClient.searchAudios({
      search,
      page: 1,
      perPage,
    });

    setPage(1);
    setSuggestions(response.items);
    setHasNext(response.hasNext);
    setIsSearching(false);
  };

  const paginate = useCallback(async () => {
    if (!hasNext) return;
    const newPage = page + 1;

    const response = await apiClient.searchAudios({
      search,
      page: newPage,
      perPage,
    });

    setPage(newPage);
    setHasNext(response.hasNext);
    setSuggestions((currentItems) => [...currentItems, ...response.items]);
  }, [hasNext, page, search]);

  const scrollRef = useIntersectionObserver({
    onIntersect: paginate,
  });

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleCleanSearch = () => {
    setSearch('');
  };

  useEffect(() => {
    if (open) {
      // Clean up state when openning
      setSearch('');
      setSuggestions(null);
    }
  }, [open]);

  useEffect(() => {
    if (debouncedSearch) {
      searchSongs(debouncedSearch);
    } else {
      setSuggestions(null);
      setHasNext(false);
      setIsSearching(false);
    }
  }, [debouncedSearch]);

  // TODO: use tailwind config
  const fullScreen = useMediaQuery({ query: '(max-width: 640px)' });

  return (
    <Modal
      open={open}
      slideDirection="right"
      onClose={onClose}
      fullScreen={fullScreen}
    >
      <div className="h-16 bg-black">
        <div className="flex items-center justify-between h-full p-4 py-3">
          <div className="flex items-center w-full h-full bg-gray-1 rounded-md">
            <FaSearch className="ml-2 text-gray-400" />
            <input
              type="text"
              id="search"
              name="search"
              placeholder="Buscar..."
              className="flex-1 w-full px-2 py-3 font-light bg-transparent outline-none md:text-xl md:py-4 md:px-3"
              autoComplete="off"
              onChange={handleSearchChange}
              value={search}
            />
            {search && (
              <button
                className="flex justify-end pr-2 w-7 focus:outline-none"
                onClick={handleCleanSearch}
              >
                <CancelIcon className="text-gray-400" width="24" />
              </button>
            )}
          </div>
          <Button className="ml-2" onClick={onClose}>
            Ok
          </Button>
        </div>
      </div>
      <div className="overflow-y-auto sm:h-96">
        {isSearching && (
          <div className="flex flex-col items-center justify-center h-full">
            <Spinner className="w-10 mb-2" />
            <p className="text-gray-400">Buscando resultados...</p>
          </div>
        )}
        {!isSearching && suggestions && suggestions.length === 0 && (
          <div className="flex flex-col items-center justify-center h-full">
            <p className="text-gray-400">Nenhum resultado encontrado.</p>
          </div>
        )}
        {suggestions && suggestions.length > 0 && (
          <>
            <List>
              {suggestions.map((song) => {
                const { category, title, artist } = getSongMeta(song);
                const secondaryText = artist
                  ? `${artist} • ${category}`
                  : category;

                return (
                  <ListItem
                    key={`songs-suggest-${song.id}`}
                    title={song.fileName}
                  >
                    <ListItemText primary={title} secondary={secondaryText} />
                    {renderActions && (
                      <ListItemActions>{renderActions(song)}</ListItemActions>
                    )}
                  </ListItem>
                );
              })}
            </List>
            {hasNext && (
              <div ref={scrollRef} className="flex justify-center">
                <Spinner className="w-6 my-6" />
              </div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
}

export default SongSearchDialog;
