import { useState, useEffect, useContext } from 'react';

import RadioContext from '../RadioContext';

import PageHeader from '../common/PageHeader';
import Tab from '../common/Tab';
import Spinner from '../common/Spinner';
import Toggle from '../common/Toggle';
import Label from '../common/Label';
import { List, ListItem, ListItemText, ListItemActions } from '../common/List';

import usePageRoute from '../../hooks/usePageRoute';

import { addOrRemove } from '../../utils/list';

import * as apiClient from '../../apiClient';

function useFolders({ type }) {
  const [folders, setFolders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadFolders = async () => {
      setIsLoading(true);
      const result = await apiClient.getContentFolders({ type });
      setFolders(result.items);
      setIsLoading(false);
    };

    loadFolders();
  }, [type, setFolders]);

  return { isLoading, folders };
}

function Content() {
  const {
    settings: radioSettings,
    updateSettings,
    isModuleEnabled,
  } = useContext(RadioContext);
  const { modulePath } = usePageRoute();

  const isSubModuleEnabled = (key) => {
    return isModuleEnabled(modulePath + `/${key}`);
  };

  const [activeTab, setActiveTab] = useState(
    isSubModuleEnabled('audiopack') ? 'audiopack' : 'content',
  );
  const { isLoading, folders } = useFolders({ type: activeTab });

  const handleClickTab = (tabId) => () => {
    setActiveTab(tabId);
  };

  const handleSelect = (folder) => () => {
    let newContents = [...radioSettings.contents];
    let newOwnContents = [...radioSettings.ownContents];

    if (folder.type === 'audiopack') {
      newContents = addOrRemove(newContents, folder.id);
    } else {
      newOwnContents = addOrRemove(newOwnContents, folder.id);
    }

    updateSettings({ contents: newContents, ownContents: newOwnContents });
  };

  const isChecked = (folder) => {
    if (folder.type === 'audiopack') {
      return radioSettings.contents.includes(folder.id);
    }
    return radioSettings.ownContents.includes(folder.id);
  };

  return (
    <div>
      <PageHeader title="Conteúdos" />
      <div className="p-6 md:px-10 md:pb-10 md:pt-0">
        <nav>
          {isSubModuleEnabled('audiopack') && (
            <Tab
              active={activeTab === 'audiopack'}
              onClick={handleClickTab('audiopack')}
            >
              Programetes
            </Tab>
          )}
          {isSubModuleEnabled('content') && (
            <Tab
              active={activeTab === 'content'}
              onClick={handleClickTab('content')}
            >
              Pastas de Conteúdos
            </Tab>
          )}
        </nav>
        {isLoading ? (
          <div className="flex items-center justify-center h-64">
            <Spinner className="w-12 h-12" />
          </div>
        ) : (
          <>
            {folders.length === 0 && (
              <p className="mt-5 text-lg">Nenhum resultado encontrado.</p>
            )}
            <List>
              {folders.map((folder) => (
                <ListItem key={`${folder.type}-${folder.id}`}>
                  <ListItemText>{folder.name}</ListItemText>
                  <ListItemActions>
                    <Label>
                      <Toggle
                        id={`${folder.type}-${folder.id}`}
                        onChange={handleSelect(folder)}
                        checked={isChecked(folder)}
                      />
                    </Label>
                  </ListItemActions>
                </ListItem>
              ))}
            </List>
          </>
        )}
      </div>
    </div>
  );
}

export default Content;
