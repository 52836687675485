import React from 'react';
import clsx from 'clsx';
import CancelIcon from '../../../icons/Cancel';
import MoveIcon from '../../../icons/Move';

const Chip = React.forwardRef(
  ({ label, onDelete, className, isDraggable }, ref) => {
    return (
      <div
        ref={ref}
        className={clsx(
          'inline-flex items-center justify-between w-full px-3 py-3.5 bg-primary text-white font-medium rounded-md',
          className,
        )}
      >
        {isDraggable && <MoveIcon className="w-5" />}
        <span className="flex-1 px-6">{label}</span>
        {onDelete && (
          <div
            className="w-6 p-1 bg-red-700 rounded-full cursor-pointer hover:bg-red-800 transition-colors"
            onClick={onDelete}
          >
            <CancelIcon />
          </div>
        )}
      </div>
    );
  },
);

export default Chip;
