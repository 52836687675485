import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useRouteMatch, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import PageHeader from '../common/PageHeader';
import ToggleField from '../common/ToggleField';
import SelectField from '../common/SelectField';
import Button from '../common/Button';
import Alert from '../common/Alert';
import Snackbar from '../common/Snackbar';

import { parseRequestError } from '../../utils/errors';
import * as apiClient from '../../apiClient';

function BirthdayCall() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [files, setFiles] = useState([]);
  const [submitError, setSubmitError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(null);

  const redirectToHome = () => {
    history.push(`/${path.split('/')[1]}`);
  };

  useEffect(() => {
    const loadFiles = async () => {
      const response = await apiClient.getBirthdayFiles();
      setFiles(response.items);
    };

    loadFiles();
  }, []);

  const handleSubmit = async (values, { setErrors, resetForm }) => {
    setSubmitError(null);

    try {
      await apiClient.realtimeBirthdayCall(values);
      setSubmitSuccess('Chamada enviada com sucesso.');
      resetForm();
      redirectToHome();
    } catch (error) {
      const [errorMessage, errorDetails] = parseRequestError(error);
      if (errorDetails) {
        setErrors(errorDetails);
      } else {
        setSubmitError(errorMessage);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setSubmitSuccess(null);
  };

  const nameFileOptions = [];
  const callFileOptions = [];
  files.forEach((file) => {
    if (file.type === 'aN') {
      nameFileOptions.push({ value: file.file, label: file.fileName });
    }
    if (file.type === 'aF') {
      callFileOptions.push({ value: file.file, label: file.fileName });
    }
  });

  return (
    <div>
      <PageHeader title="Aniversariantes" />
      <div className="p-6">
        <Snackbar open={Boolean(submitSuccess)} onClose={handleCloseSnackbar}>
          <Alert type="success" className="shadow-md">
            {submitSuccess}
          </Alert>
        </Snackbar>
        <div className="max-w-2xl mx-auto">
          <Formik
            initialValues={{
              name: '',
              call: '',
              repeat: false,
            }}
            validationSchema={Yup.object({
              name: Yup.string().required('Campo obrigatório.'),
              call: Yup.string().required('Campo obrigatório.'),
              repeat: Yup.boolean().required('Campo obrigatório.'),
            })}
            onSubmit={handleSubmit}
          >
            <Form noValidate autoComplete="off">
              <SelectField name="name">
                <option value="">Selecione o nome</option>
                {nameFileOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </SelectField>
              <SelectField name="call">
                <option value="">Selecione a chamada</option>
                {callFileOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </SelectField>
              <div className="flex items-center justify-between mb-5">
                <ToggleField name="repeat" label="Repetir aviso" noMargin />
                <Button type="submit" color="primary">
                  Chamar
                </Button>
              </div>
              <div className="mb-5">
                {submitError ? <Alert type="error">{submitError}</Alert> : null}
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default BirthdayCall;
