import clsx from 'clsx';
import PropTypes from 'prop-types';

import InputMask from 'react-input-mask';

function TextInput(props) {
  const {
    id,
    name,
    type = 'text',
    error = false,
    className,
    mask,
    ...rest
  } = props;

  const inputProps = {
    type,
    name,
    id: id || name,
    className: clsx(
      'w-full p-3 border-2 rounded-md focus:ring-indigo-500 focus:outline-none focus:border-gray-500 dark:bg-transparent dark:border-gray-400 dark:focus:border-gray-200',
      {
        'border-red-600 hover:border-red-600 focus:border-red-600 dark:border-red-600 dark:hover:border-red-600 dark:focus:border-red-600':
          error,
      },
      className,
    ),
    ...rest,
  };

  return mask ? (
    <InputMask mask={mask} {...inputProps} />
  ) : (
    <input {...inputProps} />
  );
}

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  error: PropTypes.bool,
};

export default TextInput;
