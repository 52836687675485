import { useEffect, useState } from 'react';

import { FaVolumeUp, FaLock, FaLockOpen } from 'react-icons/fa';

import PageHeader from '../common/PageHeader';
import Tab from '../common/Tab';
import IconButton from '../common/IconButton';
import { List, ListItem, ListItemActions, ListItemText } from '../common/List';
import Alert from '../common/Alert';
import Snackbar from '../common/Snackbar';

import * as apiClient from '../../apiClient';

const tabs = [
  { label: 'Vinhetas', type: '1' },
  { label: 'Comercial', type: '2' },
  { label: 'Institucional', type: '3' },
  { label: 'Programetes', type: '4' },
  { label: 'Jingles', type: '5' },
  { label: 'Especiais', type: '8' },
  { label: 'Sazonais', type: '9' },
  { label: 'Avisos', type: '19' },
  { label: 'Outros', type: 'another' },
];

function AudioManager() {
  const [activeType, setActiveType] = useState(tabs[0].type);
  const [audios, setAudios] = useState([]);
  const handleClickTab = (tabId) => () => {
    setActiveType(tabId);
  };
  const [error, setError] = useState(null);

  const loadAudios = async (type) => {
    const response = await apiClient.getAudios(type);
    setAudios(response.items);
  };

  useEffect(() => {
    loadAudios(activeType);
  }, [activeType]);

  const handleToggleLock = (index) => async () => {
    const newAudios = [...audios];
    const audio = newAudios[index];
    setError(null);

    try {
      if (audio.status === 1) {
        await apiClient.lockAudio(audio.id);
        audio.status = 0;
      } else {
        await apiClient.unlockAudio(audio.id);
        audio.status = 1;
      }
    } catch (error) {
      setError('Não foi possível atualizar o áudio.');
    }

    setAudios(newAudios);
  };

  const handlePlayAudio = (index) => async () => {
    const audio = audios[index];
    setError(null);

    try {
      await apiClient.realtimePlayAudio({ audioId: audio.id });
    } catch (error) {
      setError('Não foi possível reproduzir o áudio.');
    }
  };

  const handleCloseSnackbar = () => {
    setError(null);
  };

  return (
    <div>
      <PageHeader title="Gerenciar Áudios" />
      <Snackbar open={Boolean(error)} onClose={handleCloseSnackbar}>
        <Alert type="success" className="shadow-md">
          {error}
        </Alert>
      </Snackbar>
      <div className="h-full p-6 md:px-10 md:pb-10 md:pt-0">
        <nav>
          {tabs.map((tab) => (
            <Tab
              key={tab.type}
              active={activeType === tab.type}
              onClick={handleClickTab(tab.type)}
            >
              {tab.label}
            </Tab>
          ))}
        </nav>
        {audios.length === 0 ? (
          <p className="py-4">Nenhum áudio nesta pasta.</p>
        ) : (
          <List>
            {audios.map((audio, index) => (
              <ListItem key={`audio-${audio.id}`} title={audio.file}>
                <ListItemText
                  primary={audio.file}
                  className={audio.status !== 1 ? 'line-through' : null}
                />
                <ListItemActions>
                  {audio.status === 1 ? (
                    <IconButton
                      title="Bloquear áudio"
                      onClick={handleToggleLock(index)}
                    >
                      <FaLock />
                    </IconButton>
                  ) : (
                    <IconButton
                      title="Desbloquear áudio"
                      onClick={handleToggleLock(index)}
                      color="primary"
                    >
                      <FaLockOpen />
                    </IconButton>
                  )}
                  <IconButton
                    title="Reproduzir áudio"
                    onClick={handlePlayAudio(index)}
                  >
                    <FaVolumeUp />
                  </IconButton>
                </ListItemActions>
              </ListItem>
            ))}
          </List>
        )}
      </div>
    </div>
  );
}

export default AudioManager;
