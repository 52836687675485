function MenuItem({ children, onClick }) {
  return (
    <li
      className="flex items-center justify-start px-3 py-2 cursor-pointer hover:bg-grayAlt"
      onClick={onClick}
    >
      {children}
    </li>
  );
}

export default MenuItem;
