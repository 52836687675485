import { parse, format, startOfDay, endOfDay } from 'date-fns';

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function formatDate(dateStr) {
  return format(new Date(dateStr), "dd/MM/yyyy',' 'ás' HH:mm:ss");
}

export function toDate(dateString, options = {}) {
  const { format, withTime = true, firstHour = true } = options;

  if (withTime) {
    const dateFormat = format || 'dd/MM/yyyy HH:mm:ss';
    return parse(dateString, dateFormat, new Date());
  }

  const dateFormat = format || 'dd/MM/yyyy';
  const date = parse(dateString, dateFormat, new Date());
  return firstHour ? startOfDay(date) : endOfDay(date);
}
