import { useState, useCallback, useEffect } from 'react';
import Slider from '../../common/Slider';
import ProgressBar from '../../common/ProgressBar';

import { useAudioPosition } from './Player.context';

function leftPadZero(number) {
  return `${number < 10 ? 0 : ''}${number}`;
}

function formatTime(secs) {
  const minutes = Math.floor(secs / 60) || 0;
  const seconds = secs - minutes * 60 || 0;
  return `${leftPadZero(minutes)}:${leftPadZero(seconds)}`;
}

function getTimeNumber(num) {
  return parseInt(num, 10) || 0;
}

function PlayerProgressBar({ compact = false, disabled }) {
  const { position, duration, seek } = useAudioPosition();

  const audioDuration = getTimeNumber(duration);
  const [audioProgress, setAudioProgress] = useState(getTimeNumber(position));
  const [isChangingProgress, setIsChangingProgress] = useState(false);

  const handleChangeProgress = useCallback(
    (value) => {
      setAudioProgress(getTimeNumber(value));
      setIsChangingProgress(true);
    },
    [setAudioProgress],
  );

  const handleFinalChangeProgress = useCallback(
    (value) => {
      seek(value);
      setIsChangingProgress(false);
    },
    [seek],
  );

  useEffect(() => {
    if (!isChangingProgress) {
      setAudioProgress(getTimeNumber(position));
    }
  }, [isChangingProgress, position, duration]);

  if (compact) {
    return (
      <ProgressBar
        min={0}
        max={audioDuration}
        value={audioProgress}
        size="small"
        disabled={disabled}
      />
    );
  }

  return (
    <div className="flex flex-wrap text-xs font-normal md:items-center md:flex-nowrap">
      <div className="flex-1 order-2 w-11 md:order-none md:flex-initial">
        {formatTime(audioProgress)}
      </div>
      <Slider
        min={0}
        max={audioDuration}
        value={audioProgress}
        onChange={handleChangeProgress}
        onFinalChange={handleFinalChangeProgress}
        className="order-1 md:mx-2 md:order-none"
        disabled={disabled}
      />
      <div className="flex-1 order-3 text-right w-11 md:order-none md:flex-initial">
        {formatTime(audioDuration)}
      </div>
    </div>
  );
}

export default PlayerProgressBar;
