import { useEffect, useState } from 'react';
import { format } from 'date-fns';

function Clock() {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const clockInterval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(clockInterval);
    };
  }, []);

  return (
    <span className="text-3xl font-bold lg:text-5xl">
      {format(currentTime, 'H:mm')}
    </span>
  );
}

export default Clock;
