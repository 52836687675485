import clsx from 'clsx';
import PropTypes from 'prop-types';

function Progressbar({
  value = 0,
  min = 0,
  max = 100,
  size = 'medium',
  rounded = false,
  disabled = false,
}) {
  const width = (value / max) * 100;

  return (
    <div className={clsx('bg-white bg-opacity-30', { 'rounded-sm': rounded })}>
      <div
        className={clsx('relative group hover:bg-gray-700', {
          'h-1.5': size === 'medium',
          'h-1': size === 'small',
          'rounded-sm': rounded,
          'bg-primary': !disabled,
          'bg-gray-600': disabled,
        })}
        style={{ width: `${width}%` }}
        role="progressbar"
        aria-valuenow={value}
        aria-valuemin={min}
        aria-valuemax={max}
      ></div>
    </div>
  );
}

Progressbar.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  size: PropTypes.oneOf(['small', 'medium']),
  rounded: PropTypes.bool,
};

export default Progressbar;
