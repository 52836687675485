import { useEffect, useReducer, useRef } from 'react';

import { useHistory } from 'react-router-dom';

import useOnline from '../../../hooks/useOnline';
import { useRadio } from '../../RadioContext';
import { useAuth } from '../../../hooks/useAuth';

import { Player, isStreaming } from './Player.helpers';
import {
  PLAYER_STATUSES,
  PLAYER_ACTIONS,
  PLAYER_ERRORS,
} from './Player.constants';

const initialState = {
  ready: false,
  error: null,
  status: PLAYER_STATUSES.STOPPED,
  volume: 1,
  previousVolume: 1,
  mainBlocked: false,
  mainAudio: {
    artist: '',
    category: '',
    title: '',
    streaming: false,
  },
};

function reducer(state, action) {
  switch (action.type) {
    case PLAYER_ACTIONS.ERROR:
      return {
        ...state,
        error: action.error,
        ready: false,
        status: PLAYER_STATUSES.STOPPED,
      };
    case PLAYER_ACTIONS.READY:
      return {
        ...state,
        error: null,
        ready: true,
        volume: action.volume,
        status: PLAYER_STATUSES.READY,
      };
    case PLAYER_ACTIONS.RECONNECT:
      return {
        ...state,
        error: null,
        ready: false,
        status: PLAYER_STATUSES.STOPPED,
      };
    case PLAYER_ACTIONS.LOADED_MAIN:
      return {
        ...state,
        mainAudio: action.audio,
      };
    case PLAYER_ACTIONS.PLAY_MAIN:
      return {
        ...state,
        status: PLAYER_STATUSES.PLAYING_MAIN,
        mainAudio: action.audio || state.mainAudio,
        mainBlocked: false,
      };
    case PLAYER_ACTIONS.PLAY_BLOCKED_MAIN:
      return {
        ...state,
        mainBlocked: true,
      };
    case PLAYER_ACTIONS.PAUSE_MAIN:
      return {
        ...state,
        status: PLAYER_STATUSES.PAUSED,
      };
    case PLAYER_ACTIONS.SET_VOLUME:
      return {
        ...state,
        previousVolume: state.volume,
        volume: action.volume,
      };
    case PLAYER_ACTIONS.PLAY_MIC:
      return {
        ...state,
        status: PLAYER_STATUSES.PLAYING_MIC,
      };
    case PLAYER_ACTIONS.PLAY_SECONDARY:
      return {
        ...state,
        status: PLAYER_STATUSES.PLAYING_SECONDARY,
      };
    case PLAYER_ACTIONS.START_RECOVERY_MODE:
      return {
        ...state,
        status: PLAYER_STATUSES.RECOVERY_MODE,
      };
    case PLAYER_ACTIONS.FADE_MAIN:
      return {
        ...state,
        status: PLAYER_STATUSES.FADING_MAIN,
      };
    default:
      throw new Error(`Action ${action.type} unknown`);
  }
}

/**
 * This is the player initialization hook. This hook should be used once.
 */
export function usePlayer() {
  const history = useHistory();
  const {
    ready: radioIsReady,
    loadUnreadMessages,
    reloadSettings,
  } = useRadio();
  const playerRef = useRef(null);
  const { online } = useOnline();
  const { user, signout } = useAuth();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const playerTitle = `${user.name} - Player`;
    document.title = playerTitle;

    if (
      [PLAYER_STATUSES.PLAYING_MAIN, PLAYER_STATUSES.FADING_MAIN].includes(
        state.status,
      )
    ) {
      const { artist, title } = state.mainAudio;
      document.title = artist ? `${artist} · ${title}` : title;
    }

    return () => {
      document.title = playerTitle;
    };
  }, [user, state.status, state.mainAudio]);

  useEffect(() => {
    if (!radioIsReady) {
      return;
    }

    if (playerRef.current) {
      console.warn('[PLAYER][HOOK] Player already exists.');
      playerRef.current?.disconnect();
    }

    const player = new Player(user.accessToken);
    playerRef.current = player;
    window._nhPlayer = player;

    player.on('socket:connect_error', ({ error }) => {
      if (error.message === 'ALREADY_LOGGED_IN') {
        dispatch({
          type: PLAYER_ACTIONS.ERROR,
          error: PLAYER_ERRORS.CONCURRENT_SESSION,
        });
      }
    });

    player.on('player:disconnect', () => {
      dispatch({
        type: PLAYER_ACTIONS.ERROR,
        error: PLAYER_ERRORS.DISCONNECTED,
      });
    });

    player.on('player:init', ({ mediaTypes, timerDemo }) => {
      if (mediaTypes.length === 0) {
        dispatch({
          type: PLAYER_ACTIONS.ERROR,
          error: PLAYER_ERRORS.EMPTY_PLAYLIST,
        });
        return;
      }

      if (timerDemo > 0) {
        setTimeout(() => {
          signout();
          history.push(`/contato`, { radioId: user.id });
        }, timerDemo);
      }

      dispatch({
        type: PLAYER_ACTIONS.READY,
        volume: player.volume,
      });
      console.timeEnd('player:start');
    });

    player.on('player:load_main', ({ audio, audioMeta }) => {
      dispatch({
        type: PLAYER_ACTIONS.LOADED_MAIN,
        audio: {
          ...audioMeta,
          streaming: isStreaming(audio),
        },
      });
    });

    player.on('player:play_main', ({ audio, audioMeta }) => {
      dispatch({
        type: PLAYER_ACTIONS.PLAY_MAIN,
        audio: {
          ...audioMeta,
          streaming: isStreaming(audio),
        },
      });
    });

    player.on('player:pause_main', () => {
      dispatch({
        type: PLAYER_ACTIONS.PAUSE_MAIN,
      });
    });

    player.on('player:play_blocked_main', () => {
      dispatch({
        type: PLAYER_ACTIONS.PLAY_BLOCKED_MAIN,
      });
    });

    player.on('player:play_secondary', () => {
      dispatch({
        type: PLAYER_ACTIONS.PLAY_SECONDARY,
      });
    });

    player.on('player:recovery_mode', () => {
      dispatch({
        type: PLAYER_ACTIONS.START_RECOVERY_MODE,
      });
    });

    player.on('player:fading_main', () => {
      dispatch({
        type: PLAYER_ACTIONS.FADE_MAIN,
      });
    });

    player.on('message:new', async () => {
      await loadUnreadMessages();
    });

    player.init();
  }, [radioIsReady, user, history, signout, loadUnreadMessages]);

  useEffect(() => {
    playerRef.current?.setOnline(online);
  }, [online]);

  useEffect(() => {
    return () => {
      playerRef.current?.disconnect();
    };
  }, []);

  async function clearSession() {
    await playerRef.current.clearSession();
    window.location.reload();
  }

  function reConnect() {
    dispatch({
      type: PLAYER_ACTIONS.RECONNECT,
    });
    reloadSettings();
  }

  function setVolume(volume) {
    // Volume is a number: 0-100.
    const newVolume = playerRef.current.setVolume(volume);
    dispatch({
      type: PLAYER_ACTIONS.SET_VOLUME,
      volume: newVolume,
    });
  }

  function muteVolume() {
    playerRef.current.setVolume(0);
    dispatch({
      type: PLAYER_ACTIONS.SET_VOLUME,
      volume: 0,
    });
  }

  function unMuteVolume() {
    const newVolume = playerRef.current.setVolume(
      state.previousVolume * 100 || 100,
    );
    dispatch({
      type: PLAYER_ACTIONS.SET_VOLUME,
      volume: newVolume,
    });
  }

  function toggleMuteVolume() {
    if (state.volume === 0) {
      unMuteVolume();
    } else {
      muteVolume();
    }
  }

  function toggleMic(event) {
    event.stopPropagation();
    if (state.status === PLAYER_STATUSES.PLAYING_MIC) {
      playerRef.current.stopMic();
      dispatch({
        type: PLAYER_ACTIONS.PLAY_MAIN,
      });
    } else {
      playerRef.current.playMic();
      dispatch({
        type: PLAYER_ACTIONS.PLAY_MIC,
      });
    }
  }

  function togglePlayPause(event) {
    event.stopPropagation();
    if (state.status === PLAYER_STATUSES.PLAYING_MAIN) {
      playerRef.current.pauseMain();
      dispatch({
        type: PLAYER_ACTIONS.PAUSE_MAIN,
      });
    } else {
      playerRef.current.playMain();
      dispatch({
        type: PLAYER_ACTIONS.PLAY_MAIN,
      });
    }
  }

  function playMain() {
    playerRef.current.playMain();
  }

  function skipPrev() {
    playerRef.current.skipPrev();
  }

  async function skipNext() {
    await playerRef.current.skipNext();
  }

  function stopSecondary(event) {
    event.stopPropagation();
    playerRef.current.stopSecondary();
  }

  return {
    state,
    player: playerRef.current,
    online,
    clearSession,
    reConnect,
    signout,
    setVolume,
    muteVolume,
    unMuteVolume,
    toggleMuteVolume,
    toggleMic,
    togglePlayPause,
    skipPrev,
    skipNext,
    stopSecondary,
    playMain,
  };
}
