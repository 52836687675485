import { FaSearch } from 'react-icons/fa';
import { MdCancel } from 'react-icons/md';

function SearchInput({ value, onClear, ...rest }) {
  return (
    <div className="flex items-center w-full h-full rounded-md bg-gray-1">
      <FaSearch className="w-4 ml-4 text-gray-400" />
      <input
        {...rest}
        type="text"
        className="flex-1 w-full p-3 font-light bg-transparent outline-none"
        autoComplete="off"
        value={value}
      />
      {value && (
        <button
          className="flex justify-end pr-2 focus:outline-none"
          onClick={onClear}
        >
          <MdCancel className="w-4 mr-2 text-gray-400" />
        </button>
      )}
    </div>
  );
}

export default SearchInput;
