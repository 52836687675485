export class RequestError extends Error {}

export function parseRequestError(error) {
  const errorDetails = {};
  (error.details || [])
    .filter((errorDetail) => errorDetail.path)
    .forEach((errorDetail) => {
      errorDetails[errorDetail.path] = errorDetail.message;
    });
  return [error.message, error.details ? errorDetails : null];
}
