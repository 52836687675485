import { ONLINE_URL } from '../conf';

async function checkOnline() {
  const now = new Date().getTime();
  try {
    const resp = await fetch(`${ONLINE_URL}?_=${now}`);
    return resp.status >= 200 && resp.status < 300;
  } catch (err) {
    return false;
  }
}

export default checkOnline;
