import { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { useRouteMatch, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import PageHeader from '../common/PageHeader';
import TextAreaField from '../common/TextAreaField';
import ToggleField from '../common/ToggleField';
import SelectField from '../common/SelectField';
import Button from '../common/Button';
import Alert from '../common/Alert';
import Snackbar from '../common/Snackbar';
import IconButton from '../common/IconButton';
import ConfirmDialog from '../common/ConfirmDialog';
import { List, ListItem, ListItemActions, ListItemText } from '../common/List';

import VolumeIcon from '../../icons/VolumeUp';
import TrashIcon from '../../icons/Trash';

import { parseRequestError } from '../../utils/errors';
import * as apiClient from '../../apiClient';

function useSavedCalls() {
  const [total, setTotal] = useState(0);
  const [savedCalls, setSavedCalls] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadSavedCalls();
  }, []);

  async function loadSavedCalls() {
    try {
      const result = await apiClient.getVocalizerCalls();
      setSavedCalls(result.items);
      setTotal(result.total);
      setError(null);
    } catch (error) {
      setError(`Falha ao buscar Textos salvos. Tente novamente.`);
    } finally {
      setIsLoading(false);
    }
  }

  async function remove(callId) {
    try {
      await apiClient.deleteVocalizerCall(callId);
      setError(null);
      await loadSavedCalls();
    } catch (error) {
      setError(`Falha ao remover Texto. Tente novamente.`);
    }
  }

  async function replayCall(virtualCall) {
    return apiClient.realtimeVirtualVoiceCall({
      text: virtualCall.text,
      voice: virtualCall.voice,
      language: virtualCall.language,
      repeat: virtualCall.repeat,
    });
  }

  return { savedCalls, total, isLoading, error, remove, replayCall };
}

function VirtualVoiceCall() {
  const [showSavedCalls, setShowSavedCalls] = useState(false);

  const handleClickBack = () => {
    setShowSavedCalls(false);
  };

  const handleClickShowSavedCalls = () => {
    setShowSavedCalls(true);
  };

  return (
    <div>
      <PageHeader title="Locutor Virtual" />
      <div className="p-6">
        <div className="max-w-2xl mx-auto">
          {showSavedCalls ? (
            <SavedCalls onClickBack={handleClickBack} />
          ) : (
            <VirtualVoiceCallForm
              onClickShowSavedCalls={handleClickShowSavedCalls}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function VirtualVoiceCallForm({ onClickShowSavedCalls }) {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [submitError, setSubmitError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(null);

  const redirectToHome = () => {
    history.push(`/${path.split('/')[1]}`);
  };

  const handleSubmit = async (values, { setErrors, resetForm }) => {
    setSubmitError(null);

    try {
      await apiClient.realtimeVirtualVoiceCall(values);
      setSubmitSuccess('Chamada enviada com sucesso.');
      resetForm();
      redirectToHome();
    } catch (error) {
      const [errorMessage, errorDetails] = parseRequestError(error);
      if (errorDetails) {
        setErrors(errorDetails);
      } else {
        setSubmitError(errorMessage);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setSubmitSuccess(null);
  };

  return (
    <Formik
      initialValues={{
        text: '',
        voice: '2',
        language: 'pt-BR',
        repeat: false,
        save: false,
      }}
      validationSchema={Yup.object({
        text: Yup.string().required('Campo obrigatório.'),
        voice: Yup.string().required('Campo obrigatório.'),
        language: Yup.string().required('Campo obrigatório.'),
        repeat: Yup.boolean().required('Campo obrigatório.'),
        save: Yup.boolean().required('Campo obrigatório.'),
      })}
      onSubmit={handleSubmit}
    >
      <Form noValidate autoComplete="off">
        <Snackbar open={Boolean(submitSuccess)} onClose={handleCloseSnackbar}>
          <Alert type="success" className="shadow-md">
            {submitSuccess}
          </Alert>
        </Snackbar>
        <TextAreaField name="text" placeholder="Digite o texto" rows="10" />
        <SelectField name="voice">
          <option value="" disabled>
            Selecione a voz
          </option>
          <option value="1">Voz masculina</option>
          <option value="2">Voz feminina</option>
        </SelectField>
        <SelectField name="language">
          <option value="" disabled>
            Selecione o idioma
          </option>
          <option value="pt-BR">Português - Brasil</option>
          <option value="en">English</option>
          <option value="es">Espanol</option>
          <option value="fr">Français</option>
        </SelectField>
        <div className="flex items-center justify-between mb-5">
          <ToggleField name="save" label="Salvar texto" noMargin />
          <Button color="transparent" onClick={onClickShowSavedCalls}>
            Visualizar textos salvos
          </Button>
        </div>
        <div className="flex items-center justify-between mb-5">
          <ToggleField name="repeat" label="Repetir aviso" noMargin />
          <Button type="submit" color="primary">
            Chamar
          </Button>
        </div>
        <div className="mb-5">
          {submitError ? <Alert type="error">{submitError}</Alert> : null}
        </div>
      </Form>
    </Formik>
  );
}

function SavedCalls({ onClickBack }) {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { savedCalls, total, remove, replayCall } = useSavedCalls();

  const redirectToHome = () => {
    history.push(`/${path.split('/')[1]}`);
  };

  const handleReplayCall = (virtualCall) => async () => {
    await replayCall(virtualCall);
    redirectToHome();
  };

  const handleRemoveCall = (callId) => async () => {
    await remove(callId);
  };

  return (
    <div>
      <div className="flex flex-col justify-between pb-5 border-b-2 md:flex-row md:items-center border-grayAlt">
        <div className="flex-1 mb-5 md:mb-0">
          <h2 className="text-2xl font-semibold">Textos Salvos</h2>
          <p className="text-gray-400">{total} salvos</p>
        </div>

        <Button color="transparent" onClick={onClickBack}>
          Voltar
        </Button>
      </div>
      <div className="flex-1 overflow-y-auto">
        {savedCalls.length === 0 ? (
          <p className="py-4">Nenhum texto salvo.</p>
        ) : (
          <List>
            {savedCalls.map((savedCall) => {
              return (
                <ListItem
                  key={`saved-call-${savedCall.id}`}
                  title={savedCall.text}
                >
                  <ListItemText
                    primary={savedCall.text}
                    secondary={savedCall.repeat}
                  />
                  <ListItemActions>
                    <IconButton
                      onClick={handleReplayCall(savedCall)}
                      title="Executar locutor virtual salvo"
                    >
                      <VolumeIcon />
                    </IconButton>
                    <ConfirmDialog
                      title="Remover texto salvo"
                      message={
                        <>
                          <p className="mb-3">
                            Tem certeza que deseja remover o texto?
                          </p>
                          <p>
                            <strong>{savedCall.text}</strong>
                          </p>
                        </>
                      }
                      onConfirm={handleRemoveCall(savedCall.id)}
                    >
                      <IconButton>
                        <TrashIcon />
                      </IconButton>
                    </ConfirmDialog>
                  </ListItemActions>
                </ListItem>
              );
            })}
          </List>
        )}
      </div>
    </div>
  );
}

export default VirtualVoiceCall;
