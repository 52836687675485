import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import Spinner from '../common/Spinner';

import { useAuth } from '../../hooks/useAuth';
import useQueryString from '../../hooks/useQueryString';

function Home() {
  const [loading, setLoading] = useState(true);
  const { quickAccess } = useAuth();
  const query = useQueryString();
  const quickAccessCode = query.get('r');

  useEffect(() => {
    (async () => {
      if (!quickAccessCode) {
        setLoading(false);
      } else {
        await quickAccess(quickAccessCode);
        setLoading(false);
      }
    })();
  }, [quickAccess, quickAccessCode]);

  return !loading ? (
    <Redirect to="/player" />
  ) : (
    <div className="flex items-center justify-center h-full">
      <div className="text-center">
        <Spinner className="inline-block w-8" />
        <div className="mt-5">Redirecionando...</div>
      </div>
    </div>
  );
}

export default Home;
