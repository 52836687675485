import { useField } from 'formik';

import FormField from '../FormField';
import Label from '../Label';
import TextArea from '../TextArea';
import ErrorText from '../ErrorText';

function TextAreaField(props) {
  const { label, ...textAreaProps } = props;
  const [field, meta] = useField(props);
  const hasError = Boolean(meta.touched && meta.error);

  return (
    <FormField>
      {label && (
        <Label htmlFor={textAreaProps.id || textAreaProps.name}>{label}</Label>
      )}
      <TextArea {...field} {...textAreaProps} error={hasError} />
      {hasError ? <ErrorText>{meta.error}</ErrorText> : null}
    </FormField>
  );
}

export default TextAreaField;
