import { useState, useContext, createContext, useCallback } from 'react';

import * as apiClient from '../apiClient';
import { getUser, saveUser, deleteUser } from '../utils/auth';

const authContext = createContext();

function useProvideAuth() {
  const [user, setUser] = useState(getUser());

  async function signin({ login, password }) {
    const { accessToken, ...userData } = await apiClient.signin({
      login,
      password,
    });
    saveUser(accessToken);
    setUser(getUser());
    return userData;
  }

  const quickAccess = useCallback(async (quickAccessCode) => {
    setUser(null);
    deleteUser();

    try {
      const { accessToken } = await apiClient.quickAccess(quickAccessCode);
      saveUser(accessToken);
      setUser(getUser());
    } catch (error) {
      setUser(null);
      deleteUser();
      console.warn('[Quick access] Credenciais inválidas.');
    }
  }, []);

  const simulationAccess = useCallback(async (simulationToken) => {
    setUser(null);
    deleteUser();

    try {
      const [quickAccessCode, generationDateCode] = simulationToken.split(',');
      const { accessToken } = await apiClient.simulationAccess(
        quickAccessCode,
        generationDateCode,
      );
      saveUser(accessToken);
      setUser(getUser());
    } catch (error) {
      setUser(null);
      deleteUser();
      throw error;
    }
  }, []);

  async function signout() {
    setUser(null);
    deleteUser();
  }

  return {
    user,
    signin,
    signout,
    quickAccess,
    simulationAccess,
  };
}

export const useAuth = () => {
  return useContext(authContext);
};

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
