import clsx from 'clsx';

function Tab({ children, active = false, onClick }) {
  return (
    <button
      onClick={onClick}
      className={clsx(
        'focus:outline-none font-bold text-sm md:text-base mr-5 py-2 hover:text-primary-hover transition-colors last:mr-0',
        {
          'text-primary': active,
        },
      )}
    >
      <span className="block">{children}</span>
      {active && (
        <div className="hidden md:block w-8/12 h-0.5 mt-1 bg-primary mx-auto" />
      )}
    </button>
  );
}

export default Tab;
