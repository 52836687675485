import clsx from 'clsx';

function Pulsate({ active = true, children, size = 'default' }) {
  const commonClasses =
    'border border-primary flex full rounded-full animate-pulsate absolute z-20';

  return (
    <div className="relative inline-flex items-center justify-center">
      {active ? (
        <>
          <div
            className={clsx(commonClasses, {
              'w-14 h-14': size === 'default',
              'w-11 h-11': size === 'small',
            })}
          ></div>
          <div
            className={clsx(commonClasses, {
              'w-16 h-16': size === 'default',
              'w-12 h-12': size === 'small',
            })}
          ></div>
          <span className="z-20">{children}</span>
        </>
      ) : (
        children
      )}
    </div>
  );
}

export default Pulsate;
