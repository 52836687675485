import { useField } from 'formik';

import Checkbox from '../Checkbox';
import FormField from '../FormField';
import Label from '../Label';
import ErrorText from '../ErrorText';

function CheckboxGroupField(props) {
  const { name } = props;
  const { label, placeholder, options = [], ...rest } = props;

  const [field, meta] = useField({ name, type: 'checkbox' });
  const fieldValue = field.value || [];
  const hasError = Boolean(meta.touched && meta.error);

  return (
    <FormField>
      <div className="mb-3">
        {label && (
          <Label htmlFor={rest.id || name} className="font-normal">
            {label}
          </Label>
        )}
        {placeholder && (
          <span className="block mb-4 font-normal">{placeholder}</span>
        )}
      </div>
      <div
        className="overflow-y-scroll max-h-36 rounded-md dark:border-gray-400 dark:focus:border-gray-200 scrollbar"
        role="group"
        aria-labelledby="checkbox-group"
      >
        {options.map((option) => (
          <div
            key={`${name}_${option.value}`}
            className="mr-2 odd:bg-black rounded-md hover:bg-gray-800 transition-colors"
          >
            <label
              className="flex items-center px-4 py-3 cursor-pointer"
              title={option.label}
              alt={option.label}
            >
              <Checkbox
                {...field}
                {...rest}
                value={option.value}
                checked={fieldValue.includes(option.value)}
              />
              <span className="flex-1 ml-3 truncate">{option.label}</span>
            </label>
          </div>
        ))}
      </div>
      {hasError ? <ErrorText>{meta.error}</ErrorText> : null}
    </FormField>
  );
}

export default CheckboxGroupField;
