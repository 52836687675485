import PropTypes from 'prop-types';

function ModalContent({ children }) {
  return <div className="flex-auto p-6 overflow-y-auto">{children}</div>;
}

ModalContent.propTypes = {
  children: PropTypes.node,
};

export default ModalContent;
