import clsx from 'clsx';
import PropTypes from 'prop-types';

function Label({ children, htmlFor, className }) {
  return (
    <label htmlFor={htmlFor} className={clsx('block font-medium', className)}>
      {children}
    </label>
  );
}

Label.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Label;
