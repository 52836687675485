import { API_URL } from './conf';
import { RequestError } from './utils/errors';
import { getUser, deleteUser } from './utils/auth';
import { sleep } from './utils/datetime';
import { removeTrailingSlash } from './utils//url';

const RETRY_MAX = 5;
const RETRY_TIMEOUT = 5000; // 5s

const defaultHeaders = {
  'Content-Type': 'application/json',
};

async function request(apiPath, options = {}) {
  const {
    method = 'GET',
    body,
    params,
    headers: headersParam,
    parseResponseBody = true,
    retry = false,
    retryForever = false,
    retryCount = 0,
  } = options;

  const headers = { ...defaultHeaders, ...headersParam };

  const userAuth = getUser();
  if (userAuth) {
    headers['x-access-token'] = userAuth.accessToken;
    headers['x-radio-id'] = userAuth.id;
  }

  const url = new URL(`${removeTrailingSlash(API_URL)}/api${apiPath}`);
  url.search = new URLSearchParams(params).toString();

  let resp;

  try {
    resp = await fetch(url, {
      method,
      headers,
      params,
      body: body ? JSON.stringify(body) : body,
    });
  } catch (error) {
    if (retry && (retryForever || retryCount < RETRY_MAX)) {
      await sleep(RETRY_TIMEOUT);
      return request(apiPath, { ...options, retryCount: retryCount + 1 });
    }
    throw new RequestError(`${error.message}. URL: ${url}`);
  }

  if (resp.ok) {
    if (!parseResponseBody) {
      return resp;
    }
    const responseBody = await resp.text();
    try {
      return JSON.parse(responseBody);
    } catch (error) {
      return responseBody;
    }
  } else {
    const data = await resp.json();

    const {
      error: apiError = {
        statusCode: resp.status,
        message: 'Oops, ocorreu um erro inesperado! Tente novamente...',
      },
    } = data;

    let errorMessage = apiError.message;
    if (apiError.statusCode === 500) {
      errorMessage = 'Oops, ocorreu um erro inesperado! Tente novamente...';
    } else if (apiError.statusCode === 401) {
      // TODO: Use router/state management to disconnect and display a message
      errorMessage = apiError.message || 'Oops, sua sessão foi expirada!';
      deleteUser();

      window.location.href = `/login`;
      return;
    }

    const requestError = new RequestError(errorMessage);
    if (apiError.details) {
      requestError.details = apiError.details;
    }

    throw requestError;
  }
}

export async function signin({ login, password }) {
  return request('/auth/signin', {
    method: 'POST',
    body: { login, password },
  });
}

export async function signup({ urlPerson, formValues }) {
  return request(`/auth/signup/${urlPerson}`, {
    method: 'POST',
    body: formValues,
  });
}

export async function quickAccess(quickAccessCode) {
  return request('/auth/quick-access', {
    method: 'POST',
    body: { quickAccessCode },
  });
}

export async function simulationAccess(quickAccessCode, generationDateCode) {
  return request('/auth/simulation-access', {
    method: 'POST',
    body: { quickAccessCode, generationDateCode },
  });
}

export async function getPersonalizations(urlPerson) {
  return request(`/auth/personalizations/${urlPerson}`);
}

export async function recoverPassword({ login }) {
  return request('/auth/recover-password', {
    method: 'POST',
    body: { login },
  });
}

export async function getPlayerStatus(body) {
  return request('/player/status');
}

export async function turnOffPlayer() {
  return request('/player/turnoff', {
    method: 'PUT',
  });
}

export async function initPlayer() {
  return request('/player/init');
}

export async function getRadio() {
  return request('/radio', { retry: true, retryForever: true });
}

export async function updateRadioSettings(body) {
  return request('/radio/settings', {
    method: 'PUT',
    body,
  });
}

export async function loadAudio(mediaType) {
  return request('/player/audio', { params: { mediaType } });
}

export async function searchAudios({ search, page, perPage }) {
  return request('/player/audio/search', {
    params: { q: search, page, perPage },
  });
}

export async function getAudios(type) {
  return request('/audios', { params: { type } });
}

export async function lockAudio(audioId) {
  return request(`/audios/${audioId}/lock`, { method: 'PUT' });
}

export async function unlockAudio(audioId) {
  return request(`/audios/${audioId}/unlock`, { method: 'PUT' });
}

export async function getEmployeeFiles() {
  return request('/employee');
}

export async function getBirthdayFiles() {
  return request('/birthday');
}

export async function getInstantFiles() {
  return request('/instant');
}

export async function getClassesFiles() {
  return request('/classes');
}

export async function getBusFiles() {
  return request('/bus');
}

export async function realtimeEmployeeCall(body) {
  return request('/realtime/employee-call', {
    method: 'POST',
    body,
  });
}

export async function realtimeParkingCall(body) {
  return request('/realtime/parking-call', {
    method: 'POST',
    body,
  });
}

export async function realtimeBirthdayCall(body) {
  return request('/realtime/birthday-call', {
    method: 'POST',
    body,
  });
}

export async function realtimeInstantCall(body) {
  return request('/realtime/instant-call', {
    method: 'POST',
    body,
  });
}

export async function realtimeVirtualVoiceCall(body) {
  return request('/realtime/virtual-voice-call', {
    method: 'POST',
    body,
  });
}

export async function realtimeClassCall(body) {
  return request('/realtime/class-call', {
    method: 'POST',
    body,
  });
}

export async function realtimeBusCall(body) {
  return request('/realtime/bus-call', {
    method: 'POST',
    body,
  });
}

export async function realtimePlayAudio(body) {
  return request('/realtime/play-audio', {
    method: 'POST',
    body,
  });
}

export async function getMessages(params) {
  return request('/messages', { params });
}

export async function readMessage(messageId) {
  return request(`/messages/${messageId}/read`, { method: 'PUT' });
}

export async function sendMessage(body) {
  return request(`/messages`, { method: 'POST', body });
}

export async function replyMessage(messageId, body) {
  return request(`/messages/${messageId}/reply`, { method: 'POST', body });
}

export async function sendContactMessage(body) {
  return request(`/messages/contact`, { method: 'POST', body });
}

export async function deleteMessage(messageId) {
  return request(`/messages/${messageId}`, { method: 'DELETE' });
}

export async function getPlaylists(params) {
  return request('/playlists', { params });
}

export async function getFavorites(params) {
  return request('/favorites', { params });
}

export async function addFavorite(body) {
  return request('/favorites', { method: 'POST', body });
}

export async function removeFavorite(favId) {
  return request(`/favorites/${favId}`, { method: 'DELETE' });
}

export async function getSongLocks(params) {
  return request('/song-locks', { params });
}

export async function lockSong(body) {
  return request('/song-locks', { method: 'POST', body });
}

export async function unlockSong(lockId) {
  return request(`/song-locks/${lockId}`, { method: 'DELETE' });
}

export async function requestSong(body) {
  return request(`/song-requests`, { method: 'POST', body });
}

export async function removeSongRequest(songRequestId) {
  return request(`/song-requests/${songRequestId}`, { method: 'DELETE' });
}

export async function getHistory(params) {
  return request(`/history`, { params });
}

export async function downloadHistoryReport(params) {
  const resp = await request(`/history/export-report`, {
    params,
    headers: {
      Accept: 'application/pdf',
      'Content-Type': 'application/pdf',
    },
    parseResponseBody: false,
  });

  if (resp && resp.ok) {
    const blob = await resp.blob();
    const file = window.URL.createObjectURL(blob);
    window.open(file, '_blank');
  }
}

export async function getContentFolders(params) {
  return request(`/content-folders`, { params });
}

export async function getEvents(params) {
  return request(`/events`, { params });
}

export async function createEvents(body) {
  return request(`/events`, { method: 'POST', body });
}

export async function updateEvent(eventId, body) {
  return request(`/events/${eventId}`, {
    method: 'PUT',
    body,
  });
}

export async function deleteEvent(eventId) {
  return request(`/events/${eventId}`, { method: 'DELETE' });
}

export async function clearSession() {
  return request(`/player/clear-session`, {
    method: 'POST',
  });
}

export async function signinModule(body) {
  return request('/auth/signin-module', {
    method: 'POST',
    body,
  });
}

export async function getVocalizerCalls(params) {
  return request(`/vacalizer`, { params });
}

export async function deleteVocalizerCall(callId) {
  return request(`/vacalizer/${callId}`, { method: 'DELETE' });
}
