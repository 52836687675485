import { useEffect, useState } from 'react';

import Button from '../../common/Button';
import HomeHeader from '../../common/HomeHeader';

import { useAuth } from '../../../hooks/useAuth';
import { formatDate } from '../../../utils/datetime';

import * as apiClient from '../../../apiClient';

function Home() {
  const { user } = useAuth();
  const [playerStatus, setPlayerStatus] = useState(null);

  useEffect(() => {
    const fetchStatus = async () => {
      const status = await apiClient.getPlayerStatus();
      setPlayerStatus(status);
    };
    fetchStatus();
  }, []);

  async function handleTurnOff() {
    await apiClient.turnOffPlayer();
    setPlayerStatus({ on: false });
  }

  return (
    <>
      <HomeHeader rootPath="/controle" />
      <div className="p-6 pt-40">
        <div className="my-2 text-center md:mt-32 mb-14">
          <h1 className="text-4xl font-bold md:text-6xl">Controle Remoto</h1>
          <h2 className="mt-2 text-lg text-gray-500 dark:text-gray-200">
            Utilize as opções do menu para acionar chamadas e interagir com o
            player.
          </h2>
        </div>
        {playerStatus && (
          <div className="w-full max-w-2xl mx-auto my-24">
            <div className="flex flex-col justify-between md:flex-row">
              <div>
                <span className="text-xl font-semibold">{user.name}</span>
                {playerStatus.on && (
                  <div className="flex items-center mt-1 text-base text-gray-400 dark:text-gray-200">
                    Inicializada em {formatDate(playerStatus.startedAt)}
                  </div>
                )}
              </div>
              {playerStatus.on ? (
                <div className="relative flex items-center font-semibold text-green-400 rounded-full">
                  <span className="relative top-0 right-0 inline-flex w-3 h-3 -mt-1 -mr-1">
                    <span className="absolute inline-flex w-full h-full bg-green-300 rounded-full opacity-75 animate-ping"></span>
                    <span className="relative inline-flex w-3 h-3 bg-green-400 rounded-full"></span>
                  </span>
                  <span className="ml-3">Ligada</span>
                </div>
              ) : (
                <div className="relative flex items-center font-semibold text-red-400 rounded-full">
                  <span className="relative top-0 right-0 inline-flex w-3 h-3 -mt-1 -mr-1">
                    <span className="absolute inline-flex w-full h-full bg-red-300 rounded-full opacity-75 animate-ping"></span>
                    <span className="relative inline-flex w-3 h-3 bg-red-400 rounded-full"></span>
                  </span>
                  <span className="ml-3">Desligada</span>
                </div>
              )}
            </div>
            {playerStatus.on && (
              <div className="mt-6">
                <Button color="primary" onClick={handleTurnOff}>
                  Desligar
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default Home;
