import clsx from 'clsx';
import PropTypes from 'prop-types';

function Link(props) {
  const { children, href = '#', size = 'base', onClick, className } = props;
  return (
    <a
      href={href}
      className={clsx(`text-${size} hover:underline`, className)}
      onClick={onClick}
    >
      {children}
    </a>
  );
}

Link.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default Link;
