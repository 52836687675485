import Modal from '../Modal';
import BackIcon from '../../../icons/ChevronLeft';

function ScreenLayer({ open, title, children, onClose, slideDirection }) {
  return (
    <Modal
      open={open}
      slideDirection={slideDirection}
      onClose={onClose}
      fullScreen
    >
      <div className="h-16 bg-black">
        <div className="flex items-center h-full px-4 py-3">
          <button className="focus:outline-none" onClick={onClose}>
            <BackIcon className="w-3 mr-6" />
          </button>
          <div className="text-xl font-normal">{title}</div>
        </div>
      </div>
      <div className="overflow-y-auto">{children}</div>
    </Modal>
  );
}

export default ScreenLayer;
