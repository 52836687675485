function Pause(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <path d="M5.74512 3C4.77862 3 3.99512 3.7835 3.99512 4.75V19.25C3.99512 20.2165 4.77862 21 5.74512 21H9.24512C10.2116 21 10.9951 20.2165 10.9951 19.25V4.75C10.9951 3.7835 10.2116 3 9.24512 3H5.74512Z" />
      <path d="M14.7451 3C13.7786 3 12.9951 3.7835 12.9951 4.75V19.25C12.9951 20.2165 13.7786 21 14.7451 21H18.2451C19.2116 21 19.9951 20.2165 19.9951 19.25V4.75C19.9951 3.7835 19.2116 3 18.2451 3H14.7451Z" />
    </svg>
  );
}

export default Pause;
