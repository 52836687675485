export const PLAYER_STATUSES = {
  STOPPED: 'STOPPED',
  READY: 'READY',
  PAUSED: 'PAUSED',
  PLAYING_MAIN: 'PLAYING_MAIN',
  PLAYING_MIC: 'PLAYING_MIC',
  PLAYING_SECONDARY: 'PLAYING_SECONDARY',
  RECOVERY_MODE: 'RECOVERY_MODE',
  FADING_MAIN: 'FADING_MAIN',
};

export const PLAYER_ACTIONS = {
  ERROR: 'ERROR',
  READY: 'READY',
  RECONNECT: 'RECONNECT',
  LOADED_MAIN: 'LOADED_MAIN',
  PLAY_MAIN: 'PLAY_MAIN',
  PLAY_BLOCKED_MAIN: 'PLAY_BLOCKED_MAIN',
  PAUSE_MAIN: 'PAUSE_MAIN',
  SET_VOLUME: 'SET_VOLUME',
  PLAY_MIC: 'PLAY_MIC',
  PLAY_SECONDARY: 'PLAY_SECONDARY',
  START_RECOVERY_MODE: 'START_RECOVERY_MODE',
  FADE_MAIN: 'FADE_MAIN',
};

export const PLAYER_ERRORS = {
  CONCURRENT_SESSION: 'CONCURRENT_SESSION',
  DISCONNECTED: 'DISCONNECTED',
  EMPTY_PLAYLIST: 'EMPTY_PLAYLIST',
};
