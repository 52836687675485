import { useRouteMatch, useLocation } from 'react-router-dom';

function usePageRoute() {
  const { path } = useRouteMatch();
  const location = useLocation();

  let modulePath =
    path.split('/').length === 1
      ? location.pathname.replace(path, '')
      : location.pathname.replace(/^(\/player(-v2)?|\/controle)/, '');

  if (!modulePath) {
    modulePath = '/';
  }

  function getRoutePath(routePath) {
    return `${path}${routePath}`;
  }

  function getBottomClasses() {
    return path.startsWith('/player')
      ? 'bottom-36 md:bottom-24'
      : 'bottom-16 md:bottom-0';
  }

  return {
    path,
    modulePath,
    getRoutePath,
    getBottomClasses,
  };
}

export default usePageRoute;
