import clsx from 'clsx';
import { Range } from 'react-range';

function Slider({
  value = 0,
  min = 0,
  max = 100,
  onChange,
  onFinalChange,
  className,
  disabled = false,
}) {
  return (
    <Range
      min={min}
      max={max || 100}
      values={[value]}
      disabled={disabled}
      onFinalChange={onFinalChange}
      onChange={(newValues) =>
        onChange && !disabled ? onChange(newValues[0]) : null
      }
      renderTrack={({ props, children }) => {
        const width = max > 0 ? (value / max) * 100 : 0;
        return (
          <span
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            className={clsx(
              'inline-block relative w-full h-1 py-3 box-content group',
              className,
            )}
            ref={props.ref}
            style={props.style}
          >
            <span className="absolute block w-full h-1 bg-white bg-opacity-30"></span>
            <span
              className={clsx(
                'w-full h-1 block absolute',
                disabled
                  ? 'bg-gray-600'
                  : 'bg-primary group-hover:bg-primary-hover',
              )}
              style={{ width: `${width}%` }}
            ></span>
            {children}
          </span>
        );
      }}
      renderThumb={({ props }) => (
        <span
          {...props}
          className={clsx(
            'w-3 h-3 flex outline-none rounded-full',
            disabled
              ? 'bg-gray-600'
              : 'bg-primary group-hover:bg-primary-hover',
          )}
        ></span>
      )}
    />
  );
}

export default Slider;
