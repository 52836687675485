import clsx from 'clsx';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

function Overlay(props) {
  const {
    children,
    open = false,
    onClose,
    bgImage,
    alignMiddle = true,
  } = props;

  if (!open) {
    return null;
  }

  const handleBackdropClick = (event) => {
    if (event.target !== event.currentTarget) {
      return;
    }
    if (onClose) {
      onClose(event);
    }
  };

  return ReactDOM.createPortal(
    <div
      className="fixed inset-0 overflow-y-auto bg-center bg-no-repeat bg-cover z-100 focus:outline-none"
      role="presentation"
      tabIndex={0}
      style={{
        backgroundImage: bgImage ? `url(${bgImage})` : null,
      }}
    >
      <div className="block h-full text-center">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div
            className="absolute inset-0 bg-black opacity-90"
            onClick={handleBackdropClick}
          ></div>
        </div>
        <span
          className={clsx('inline-block h-full', alignMiddle && 'align-middle')}
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          className="inline-block w-full max-w-3xl p-4 text-left align-middle transform transition-all"
          role="dialog"
          aria-modal="true"
        >
          {children}
        </div>
      </div>
    </div>,
    document.body,
  );
}

Overlay.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default Overlay;
