import { useState, useContext, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import PageHeader from '../common/PageHeader';
import Select from '../common/Select';
import Chip from '../common/Chip';
import Button from '../common/Button';
import Alert from '../common/Alert';
import Snackbar from '../common/Snackbar';

import RadioContext from '../RadioContext';

import { parseRequestError } from '../../utils/errors';

const mediaTypeOptions = [
  { label: 'Pastas de Músicas', value: 'a0' }, // totalPastasMusicas > 0
  { label: 'Músicas do Sistema', value: 'a1' },
  { label: 'AudioPack', value: 'a9' }, // habOpcionais = 1
  { label: 'Conteúdos', value: 'a10' }, // totalPastasConteudos > 0
  { label: 'Vinheta', value: 'a2' },
  { label: 'Comercial ', value: 'a3' },
  { label: 'Institucional', value: 'a4' },
  { label: 'Programete', value: 'a5' },
  { label: 'Jingle', value: 'a6' },
  { label: 'Especial', value: 'a11' },
  { label: 'Sazonal', value: 'a12' },
  { label: 'Avisos', value: 'a22' },
  { label: 'Outro', value: 'a7' },
  { label: 'Hora Certa', value: 'a8' },
];

const mediaTypesMap = mediaTypeOptions.reduce((p, c) => {
  p[c.value] = c.label;
  return p;
}, {});

function reorderItems(items, startIndex, endIndex) {
  const result = Array.from(items);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

function PlaylistSequence() {
  const bottomRef = useRef(null);
  const { settings: radioSettings, updateSettings } = useContext(RadioContext);

  const [hasNewItem, setHasNewItem] = useState(false);
  const [selectedMediaType, setSelectedMediaType] = useState('');
  const [mediaTypes, setMediaTypes] = useState(radioSettings.mediaTypesConfig);
  const [actionMessage, setActionMessage] = useState(null);

  const handleChangeMediaType = (event) => {
    const selectedValue = event.target.value;
    if (!selectedValue) {
      setSelectedMediaType(selectedValue);
      return;
    }

    setMediaTypes([...mediaTypes, selectedValue]);
    setHasNewItem(true);
    setSelectedMediaType('');

    // Scroll to the bottom
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const handleDelete = (index) => () => {
    const newSequence = [...mediaTypes];
    newSequence.splice(index, 1);
    setMediaTypes(newSequence);
    setHasNewItem(false);
  };

  const handleChangeOrder = (result) => {
    if (!result.destination) {
      return;
    }

    const newSequence = reorderItems(
      mediaTypes,
      result.source.index,
      result.destination.index,
    );
    setMediaTypes(newSequence);
    setHasNewItem(false);
  };

  const handleSaveChanges = async () => {
    setActionMessage(null);

    try {
      await updateSettings({ mediaTypes });
      setActionMessage({
        type: 'success',
        message: 'Sequência salva com sucesso.',
      });
    } catch (error) {
      const [errorMessage] = parseRequestError(error);
      setActionMessage({
        type: 'error',
        message: errorMessage,
      });
    }
  };

  const handleCleanChanges = () => {
    setMediaTypes(radioSettings.mediaTypesConfig);
    setHasNewItem(false);
  };

  const handleCloseSnackbar = () => {
    setActionMessage(null);
  };

  useEffect(() => {
    setMediaTypes(radioSettings.mediaTypesConfig);
  }, [radioSettings.mediaTypesConfig]);

  useEffect(() => {
    if (hasNewItem) {
      const clearNewItemTimeout = setTimeout(() => {
        setHasNewItem(false);
      }, 7000);

      return () => clearTimeout(clearNewItemTimeout);
    }
  }, [hasNewItem]);

  return (
    <div className="flex flex-col justify-between h-full">
      <PageHeader title="Playlist/Programação" />
      {actionMessage && (
        <Snackbar open onClose={handleCloseSnackbar}>
          <Alert type={actionMessage.type} className="shadow-md">
            {actionMessage.message}
          </Alert>
        </Snackbar>
      )}

      <div className="flex-1 p-6 overflow-y-auto hide-scroll-bar">
        <div className="max-w-2xl mx-auto">
          <div className="mb-5">
            <div className="mb-2 text-xl font-normal">
              Sequência de reprodução
            </div>
            <Select onChange={handleChangeMediaType} value={selectedMediaType}>
              <option value="">Adicione um tipo de mídia</option>
              {mediaTypeOptions.map((mediaType) => (
                <option
                  key={`media-type-${mediaType.value}`}
                  value={mediaType.value}
                >
                  {mediaType.label}
                </option>
              ))}
            </Select>
          </div>
          <SequenceList
            mediaTypes={mediaTypes}
            hasNewItem={hasNewItem}
            onChangeOrder={handleChangeOrder}
            onDelete={handleDelete}
          />
          <div className="my-5 text-right" ref={bottomRef}>
            <Button onClick={handleCleanChanges}>Cancelar</Button>
            <Button
              color="primary"
              onClick={handleSaveChanges}
              className="ml-3"
            >
              Salvar
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

function SequenceList({ mediaTypes, onChangeOrder, onDelete, hasNewItem }) {
  if (mediaTypes.length === 0) return null;

  return (
    <DragDropContext onDragEnd={onChangeOrder}>
      <Droppable droppableId="playlist-sequence-droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={clsx(
              'rounded-md p-3 transition-colors duration-75',
              snapshot.isDraggingOver ? 'bg-gray-700' : 'bg-gray-1',
            )}
          >
            {mediaTypes.map((mediaTypeId, index) => (
              <SequenceItem
                key={`playlist-sequence-${mediaTypeId}-${index}`}
                index={index}
                hasNewItem={hasNewItem}
                mediaTypeId={mediaTypeId}
                totalMediaTypes={mediaTypes.length}
                onDelete={onDelete}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

function SequenceItem({
  mediaTypeId,
  index,
  totalMediaTypes,
  onDelete,
  hasNewItem,
}) {
  const isLast = index === totalMediaTypes - 1;
  const canDelete = totalMediaTypes > 1;
  return (
    <Draggable
      index={index}
      draggableId={`playlist-sequence-${mediaTypeId}-${index}`}
    >
      {(provided, snapshot) => (
        <div
          className={clsx('text-black', !isLast && 'mb-3')}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Chip
            label={mediaTypesMap[mediaTypeId]}
            isDraggable
            onDelete={canDelete ? onDelete(index) : null}
            className={clsx(
              snapshot.isDragging && 'shadow-lg',
              isLast && hasNewItem && 'font-bold border-2 border-white',
            )}
          />
        </div>
      )}
    </Draggable>
  );
}

export default PlaylistSequence;
