import { useState } from 'react';

import useLocalStorage from '../../../hooks/useLocalStorage';
import * as apiClient from '../../../apiClient';

function useSongRequest() {
  const [songRequests, setSongRequests] = useState([]); // [ id, songId, collection ]
  const [latestSongRequests, setLatestSongRequests] = useLocalStorage(
    'song-requests',
    [],
  );

  const addSongRequest = (song) => async () => {
    const newSongRequest = {
      songId: song.id,
      collection: song.collection,
    };
    const { id: songRequestId } = await apiClient.requestSong(newSongRequest);
    newSongRequest.id = songRequestId;

    setSongRequests((requests) => [...requests, newSongRequest]);

    // Add the new song to the latest requests
    setLatestSongRequests((latestRequests) => {
      // Remove the new added song from the list to avoid duplications
      let newLatestRequests = [...latestRequests].filter(
        (latestSongs) => latestSongs.id !== song.id,
      );
      newLatestRequests = [song, ...newLatestRequests];
      if (newLatestRequests.length > 20) {
        newLatestRequests.pop();
      }
      return newLatestRequests;
    });
  };

  const removeSongRequest = (songRequest) => async () => {
    await apiClient.removeSongRequest(songRequest.id);

    setSongRequests((requests) =>
      requests.filter((request) => request.id !== songRequest.id),
    );
  };

  const getSongRequest = (song) => {
    return songRequests.find(({ songId }) => songId === song.id);
  };

  return {
    latestSongRequests,
    getSongRequest,
    addSongRequest,
    removeSongRequest,
  };
}

export default useSongRequest;
