import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import InputField from '../common/InputField';
import SelectField from '../common/SelectField';
import Button from '../common/Button';
import Alert from '../common/Alert';

import { useAuth } from '../../hooks/useAuth';
import useAuthPersonalizations from '../../hooks/useAuthPersonalizations';

import bgSignUp from '../../images/bg-signup.jpg';
import { parseRequestError } from '../../utils/errors';
import * as apiClient from '../../apiClient';

function prependHtmlTags(htmlTagsString, targetDocumentTagName) {
  const targetTag = document[targetDocumentTagName];
  const existingNodes = document[targetDocumentTagName].childNodes;

  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlTagsString;
  const newNodes = Array.from(tempDiv.childNodes);

  newNodes.forEach((node) => {
    if (existingNodes.length > 0) {
      targetTag.insertBefore(node, existingNodes[0]);
    } else {
      targetTag.appendChild(node);
    }
  });
}

function SignUp() {
  const history = useHistory();
  const { urlPerson } = useParams();
  const { signin } = useAuth();
  const { personalizations, isLoading, userNotFound } =
    useAuthPersonalizations(urlPerson);
  const [submitError, setSubmitError] = useState(null);
  const [newRadioAccess, setNewRadioAccess] = useState(null);

  useEffect(() => {
    if (userNotFound) history.push('/login');
  }, [history, userNotFound]);

  useEffect(() => {
    if (personalizations.name) {
      document.title = `${personalizations.name} - Tenha sua rádio ambiente. Experimente grátis!`;
    }
    if (personalizations.signUpHeadTags) {
      prependHtmlTags(personalizations.signUpHeadTags, 'head');
    }
    if (personalizations.signUpBodyTags) {
      prependHtmlTags(personalizations.signUpHeadTags, 'body');
    }
  }, [personalizations]);

  const handleSubmit = async (values, { setErrors }) => {
    setSubmitError(null);

    try {
      const accessData = await apiClient.signup({
        urlPerson,
        formValues: values,
      });
      setNewRadioAccess(accessData);
    } catch (error) {
      const [errorMessage, errorDetails] = parseRequestError(error);
      if (errorDetails) {
        setErrors(errorDetails);
      } else {
        setSubmitError(errorMessage);
      }
    }
  };

  const handleClickAccess = (newRadioAccess) => async () => {
    try {
      await signin({
        login: newRadioAccess.login,
        password: newRadioAccess.password,
      });
      history.replace({ pathname: '/player' });
    } catch (error) {
      const [errorMessage] = parseRequestError(error);
      setSubmitError(errorMessage);
    }
  };

  if (isLoading) return null;

  return (
    <div className="flex flex-col justify-between h-full md:flex-row xl:items-center">
      {personalizations.displaySignUpInfo && (
        <div
          className="relative flex justify-center flex-1 order-2 h-full text-white bg-gray-900 bg-center bg-no-repeat bg-cover md:order-1 xl:items-center"
          style={{ backgroundImage: `url(${bgSignUp})` }}
        >
          <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-70"></div>
          <div className="z-10 m-4 text-center md:w-96 md:text-left">
            {personalizations.logo && (
              <img
                src={personalizations.logo}
                className="inline-block border-4 border-white rounded-full w-36"
                alt={`${personalizations.name} logo`}
              />
            )}
            <section className="mt-12">
              <h2 className="text-3xl font-semibold">Aumente suas vendas</h2>
              <p className="mt-6 text-gray-300">
                Aumente o faturamento da sua loja com uma rádio exclusiva e
                personalizada. Divulgue ofertas, promoções e informativos ao seu
                público com a plataforma perfeita para sonorização de
                estabelecimentos comerciais.
              </p>
            </section>
            <section className="mt-12">
              <h2 className="text-3xl font-semibold">
                Melhor custo x benefício
              </h2>
              <p className="mt-6 text-gray-300">
                A ferramenta perfeita para impulsionar suas vendas, com milhões
                de músicas selecionadas, recursos interativos e conceitos de
                marketing sensorial, tudo isso custando menos do que você
                imagina. Cadastre-se e receba uma amostra grátis.
              </p>
            </section>
          </div>
        </div>
      )}

      <div className="flex-1 order-1 md:order-2">
        <div className="flex justify-center px-4 py-12 sm:px-6 lg:px-8 xl:items-center">
          {newRadioAccess ? (
            <div className="w-full">
              <div className="mb-10">
                <h2 className="mb-2 text-3xl font-semibold">
                  Parabéns, você acaba de criar sua rádio experimental!
                </h2>
                <h3 className="mb-10 text-lg text-gray-500">
                  Estes são os dados de acesso para você ouvir a rádio na sua
                  empresa:
                </h3>
                <p>
                  <span className="font-bold">Login:</span>{' '}
                  {newRadioAccess.login}
                </p>
                <p className="mb-10">
                  <span className="font-bold">Senha:</span>{' '}
                  {newRadioAccess.password}
                </p>
                <Button
                  size="large"
                  color="primary"
                  onClick={handleClickAccess(newRadioAccess)}
                  fullWidth
                >
                  Acesse já!
                </Button>
              </div>
            </div>
          ) : (
            <div className="w-full max-w-md">
              <div className="mb-10 text-center">
                <h2 className="mb-10 text-3xl font-semibold">
                  Crie uma rádio experimental
                </h2>
              </div>
              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  email: '',
                  phoneNumber: '',
                  storeName: '',
                  storeSegment: '',
                  ...(personalizations.showRepresentative
                    ? { representativeName: '' }
                    : {}),
                }}
                validationSchema={Yup.object({
                  firstName: Yup.string().required('Este campo é obrigatório.'),
                  lastName: Yup.string().required('Este campo é obrigatório.'),
                  email: Yup.string()
                    .required('Este campo é obrigatório.')
                    .email('E-mail inválido.'),
                  phoneNumber: Yup.string().required(
                    'Este campo é obrigatório.',
                  ),
                  storeName: Yup.string().required('Este campo é obrigatório.'),
                  storeSegment: Yup.string().required(
                    'Este campo é obrigatório.',
                  ),
                  ...(personalizations.showRepresentative
                    ? {
                        representativeName: Yup.string().required(
                          'Este campo é obrigatório.',
                        ),
                      }
                    : {}),
                })}
                onSubmit={handleSubmit}
              >
                <Form noValidate autoComplete="off">
                  <InputField
                    name="firstName"
                    label="Nome"
                    placeholder="Informe o seu nome"
                  />
                  <InputField
                    name="lastName"
                    label="Sobrenome"
                    placeholder="Informe o seu sobrenome"
                  />
                  <InputField
                    type="email"
                    name="email"
                    label="E-mail"
                    placeholder="Ex. exemplo@email.com"
                  />
                  <InputField
                    name="phoneNumber"
                    label="Telefone"
                    placeholder="Ex. 00 0000-0000"
                  />
                  <InputField
                    name="storeName"
                    label="Estabelecimento"
                    placeholder="Informe o nome do estabelecimento"
                  />
                  <SelectField name="storeSegment" label="Segmento">
                    <option value="">Selecione o segmento da loja</option>
                    {personalizations.segments.map((segment) => (
                      <option key={segment.id} value={segment.id}>
                        {segment.name}
                      </option>
                    ))}
                  </SelectField>
                  {personalizations.showRepresentative && (
                    <InputField
                      name="representativeName"
                      label="Representante"
                      placeholder="Informe o nome do representante"
                    />
                  )}
                  <div className="mb-5">
                    {submitError ? (
                      <Alert type="error">{submitError}</Alert>
                    ) : null}
                  </div>
                  <div className="mb-5 text-center">
                    <Button
                      type="submit"
                      size="large"
                      color="primary"
                      fullWidth
                    >
                      Entrar
                    </Button>
                  </div>
                </Form>
              </Formik>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SignUp;
