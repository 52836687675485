import clsx from 'clsx';
import { useState, useRef } from 'react';
import { useField } from 'formik';
import { format as formatDate } from 'date-fns';
import DayPicker from 'react-day-picker';

import useClickOutside from '../../../hooks/useClickOutside';
import { toDate } from '../../../utils/datetime';

import FormField from '../FormField';
import Label from '../Label';
import Input from '../Input';
import ErrorText from '../ErrorText';

import 'react-day-picker/lib/style.css';
import './styles.css';

const MONTHS = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

const WEEKDAYS_LONG = [
  'Domingo',
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
];

const WEEKDAYS_SHORT = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

function DateField(props) {
  const [open, setOpen] = useState(false);
  const [field, meta, helpers] = useField(props);

  const {
    label,
    format = 'dd/MM/yyyy',
    mask = '99/99/9999',
    disabledDays,
    month,
    noMargin,
    ...inputProps
  } = props;
  const hasError = Boolean(meta.touched && meta.error);
  const dateValue = field.value ? toDate(field.value, { format }) : null;

  const handleDateChange = (date, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }

    const newDate = formatDate(date, format);
    helpers.setValue(newDate);
    setOpen(false);
  };

  const handleFocus = () => {
    setOpen(true);
  };

  const elRef = useRef(null);
  useClickOutside(elRef, () => setOpen(false));

  return (
    <FormField noMargin={noMargin}>
      <div ref={elRef}>
        {label && (
          <Label htmlFor={inputProps.id || inputProps.name}>{label}</Label>
        )}
        <Input
          {...field}
          {...inputProps}
          mask={mask}
          error={hasError}
          value={field.value}
          onFocus={handleFocus}
        />
        <div className={clsx('relative', open ? 'visible' : 'hidden')}>
          <DayPicker
            onDayClick={handleDateChange}
            selectedDays={dateValue}
            className="absolute top-0 left-0 z-10 mt-1 text-sm font-normal rounded-md bg-gray-2"
            locale="pt-br"
            months={MONTHS}
            weekdaysLong={WEEKDAYS_LONG}
            weekdaysShort={WEEKDAYS_SHORT}
            disabledDays={disabledDays}
          />
        </div>
      </div>
      {hasError ? <ErrorText>{meta.error}</ErrorText> : null}
    </FormField>
  );
}

export default DateField;
