const TOKEN_KEY = 'jwtToken';

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

export function getUser() {
  const accessToken = localStorage.getItem(TOKEN_KEY);

  if (!accessToken) return null;
  try {
    return { accessToken, ...parseJwt(accessToken) };
  } catch (error) {
    return null;
  }
}

export function saveUser(accessToken) {
  localStorage.setItem(TOKEN_KEY, accessToken);
}

export function deleteUser() {
  localStorage.removeItem(TOKEN_KEY);
}
