import { useState } from 'react';
import { Formik, Form } from 'formik';
import { useRouteMatch, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import PageHeader from '../common/PageHeader';
import SelectField from '../common/SelectField';
import InputField from '../common/InputField';
import ToggleField from '../common/ToggleField';
import Button from '../common/Button';
import Alert from '../common/Alert';
import Snackbar from '../common/Snackbar';

import { parseRequestError } from '../../utils/errors';
import * as apiClient from '../../apiClient';

const vehicleTypes = [
  { label: 'Carro', value: 'carro' },
  { label: 'Moto', value: 'moto' },
  { label: 'Utilitário', value: 'utilitario' },
  { label: 'Caminhão', value: 'caminhao' },
];

const vehicleBrands = [
  { label: 'Agrale', value: 'agrale' },
  { label: 'Aston Martin', value: 'aston martin' },
  { label: 'Audi', value: 'audi' },
  { label: 'Bentley', value: 'bentley' },
  { label: 'BMW', value: 'bmw' },
  { label: 'Changan', value: 'changan' },
  { label: 'Chery', value: 'chery' },
  { label: 'Chevrolet', value: 'chevrolet' },
  { label: 'Chrysler', value: 'chrysler' },
  { label: 'Citroen', value: 'citroen' },
  { label: 'Dafra', value: 'dafra' },
  { label: 'Dodge', value: 'dodge' },
  { label: 'Ducati', value: 'ducati' },
  { label: 'Effa', value: 'effa' },
  { label: 'Ferrari', value: 'ferrari' },
  { label: 'Fiat', value: 'fiat' },
  { label: 'Ford', value: 'ford' },
  { label: 'Geely', value: 'gely' },
  { label: 'Hafei', value: 'hafei' },
  { label: 'Harley-Davidson', value: 'harley' },
  { label: 'Honda', value: 'honda' },
  { label: 'Hyundai', value: 'hyundai' },
  { label: 'Iveco', value: 'iveco' },
  { label: 'Jac Motors', value: 'jac' },
  { label: 'Jaguar', value: 'jaguar' },
  { label: 'Jeep', value: 'jeep' },
  { label: 'Jinbei', value: 'jimbei' },
  { label: 'Kasinski', value: 'kasinski' },
  { label: 'Kawasaki', value: 'kawasaki' },
  { label: 'Kia', value: 'kia' },
  { label: 'Lamborghini', value: 'lamborghini' },
  { label: 'Land Rover', value: 'land rover' },
  { label: 'Lifan', value: 'lifan' },
  { label: 'Mahindra', value: 'mahindra' },
  { label: 'Maserati', value: 'maseratti' },
  { label: 'Mercedes-Benz', value: 'mercedes' },
  { label: 'MG Motors', value: 'mg motors' },
  { label: 'Mini', value: 'mini' },
  { label: 'Mitsubishi', value: 'mitsubishi' },
  { label: 'Nissan', value: 'nissan' },
  { label: 'Peugeot', value: 'peugeot' },
  { label: 'Porsche', value: 'porsche' },
  { label: 'Ram', value: 'ram' },
  { label: 'Renault', value: 'renault' },
  { label: 'Smart', value: 'smart' },
  { label: 'Ssangyong', value: 'ssangyong' },
  { label: 'Subaru', value: 'subaru' },
  { label: 'Sundown', value: 'sundown' },
  { label: 'Suzuki', value: 'suzuki' },
  { label: 'Toyota', value: 'toyota' },
  { label: 'Troller', value: 'troller' },
  { label: 'Volkswagen', value: 'volkswagem' },
  { label: 'Volvo', value: 'volvo' },
  { label: 'Yamaha', value: 'yamaha' },
];

const vehicleColors = [
  { label: 'Amarela', value: 'AMARELA' },
  { label: 'Azul', value: 'AZUL' },
  { label: 'Bege', value: 'BEGE' },
  { label: 'Bordô', value: 'BORDO' },
  { label: 'Branca', value: 'BRANCA' },
  { label: 'Champagne', value: 'CHAMPAGNE' },
  { label: 'Cinza', value: 'CINZA' },
  { label: 'Dourada', value: 'DOURADA' },
  { label: 'Grafite', value: 'GRAFITE' },
  { label: 'Laranja', value: 'LARANJA' },
  { label: 'Marrom', value: 'MARROM' },
  { label: 'Ouro', value: 'OURO' },
  { label: 'Prata', value: 'PRATA' },
  { label: 'Preta', value: 'PRETA' },
  { label: 'Rosa', value: 'ROSA' },
  { label: 'Roxa', value: 'ROXA' },
  { label: 'Verde', value: 'VERDE' },
  { label: 'Vermelha', value: 'VERMELHA' },
];

function ParkingCall() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [submitError, setSubmitError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(null);

  const redirectToHome = () => {
    history.push(`/${path.split('/')[1]}`);
  };

  const handleSubmit = async (values, { setErrors, resetForm }) => {
    setSubmitError(null);

    try {
      await apiClient.realtimeParkingCall(values);
      setSubmitSuccess('Chamada enviada com sucesso.');
      resetForm();
      redirectToHome();
    } catch (error) {
      const [errorMessage, errorDetails] = parseRequestError(error);
      if (errorDetails) {
        setErrors(errorDetails);
      } else {
        setSubmitError(errorMessage);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setSubmitSuccess(null);
  };

  return (
    <div>
      <PageHeader title="Estacionamento" />
      <div className="p-6">
        <Snackbar open={Boolean(submitSuccess)} onClose={handleCloseSnackbar}>
          <Alert type="success" className="shadow-md">
            {submitSuccess}
          </Alert>
        </Snackbar>
        <div className="max-w-2xl mx-auto">
          <Formik
            initialValues={{
              vehicleType: '',
              vehicleBrand: '',
              vehicleColor: '',
              licensePlate: '',
              repeat: false,
            }}
            validationSchema={Yup.object({
              vehicleType: Yup.string().required('Campo obrigatório.'),
              vehicleBrand: Yup.string().required('Campo obrigatório.'),
              vehicleColor: Yup.string().required('Campo obrigatório.'),
              licensePlate: Yup.string().required('Campo obrigatório.'),
              repeat: Yup.boolean().required('Campo obrigatório.'),
            })}
            onSubmit={handleSubmit}
          >
            <Form noValidate autoComplete="off">
              <SelectField name="vehicleType">
                <option value="">Selecione um tipo do veículo</option>
                {vehicleTypes.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </SelectField>
              <SelectField name="vehicleBrand">
                <option value="">Selecione uma marca</option>
                {vehicleBrands.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </SelectField>
              <SelectField name="vehicleColor">
                <option value="">Selecione uma cor</option>
                {vehicleColors.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </SelectField>
              <InputField
                name="licensePlate"
                placeholder="Insira a placa"
                mask="***-****"
              />
              <div className="flex items-center justify-between mb-5">
                <ToggleField name="repeat" label="Repetir aviso" noMargin />
                <Button type="submit" color="primary">
                  Chamar
                </Button>
              </div>
              <div className="mb-5">
                {submitError ? <Alert type="error">{submitError}</Alert> : null}
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ParkingCall;
