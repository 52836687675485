import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useRouteMatch, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import PageHeader from '../common/PageHeader';
import ToggleField from '../common/ToggleField';
import SelectField from '../common/SelectField';
import Button from '../common/Button';
import Alert from '../common/Alert';
import Snackbar from '../common/Snackbar';

import { parseRequestError } from '../../utils/errors';
import * as apiClient from '../../apiClient';

function ClassCall() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [files, setFiles] = useState([]);
  const [submitError, setSubmitError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(null);

  const redirectToHome = () => {
    history.push(`/${path.split('/')[1]}`);
  };

  useEffect(() => {
    const loadFiles = async () => {
      const response = await apiClient.getClassesFiles();
      setFiles(response.items);
    };

    loadFiles();
  }, []);

  const handleSubmit = async (values, { setErrors, resetForm }) => {
    setSubmitError(null);

    try {
      await apiClient.realtimeClassCall(values);
      setSubmitSuccess('Chamada enviada com sucesso.');
      resetForm();
      redirectToHome();
    } catch (error) {
      const [errorMessage, errorDetails] = parseRequestError(error);
      if (errorDetails) {
        setErrors(errorDetails);
      } else {
        setSubmitError(errorMessage);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setSubmitSuccess(null);
  };

  const whenFileOptions = [];
  const typeFileOptions = [];
  const instructorFileOptions = [];
  files.forEach((file) => {
    if (file.type === 'aQ') {
      whenFileOptions.push({ value: file.file, label: file.fileName });
    }
    if (file.type === 'aA') {
      typeFileOptions.push({ value: file.file, label: file.fileName });
    }
    if (file.type === 'aP') {
      instructorFileOptions.push({ value: file.file, label: file.fileName });
    }
  });

  return (
    <div>
      <PageHeader title="Aviso de Aula" />
      <div className="p-6">
        <Snackbar open={Boolean(submitSuccess)} onClose={handleCloseSnackbar}>
          <Alert type="success" className="shadow-md">
            {submitSuccess}
          </Alert>
        </Snackbar>
        <div className="max-w-2xl mx-auto">
          <Formik
            initialValues={{
              when: '',
              type: '',
              instructor: '',
              repeat: false,
            }}
            validationSchema={Yup.object({
              when: Yup.string().required('Campo obrigatório.'),
              type: Yup.string().required('Campo obrigatório.'),
              instructor: Yup.string().required('Campo obrigatório.'),
              repeat: Yup.boolean().required('Campo obrigatório.'),
            })}
            onSubmit={handleSubmit}
          >
            <Form noValidate autoComplete="off">
              <SelectField name="when">
                <option value="">Quando inicia?</option>
                {whenFileOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </SelectField>
              <SelectField name="type">
                <option value="">Qual a modalidade?</option>
                {typeFileOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </SelectField>
              <SelectField name="instructor">
                <option value="">Qual o professor?</option>
                {instructorFileOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </SelectField>
              <div className="flex items-center justify-between mb-5">
                <ToggleField name="repeat" label="Repetir aviso" noMargin />
                <Button type="submit" color="primary">
                  Chamar
                </Button>
              </div>
              <div className="mb-5">
                {submitError ? <Alert type="error">{submitError}</Alert> : null}
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ClassCall;
