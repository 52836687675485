import clsx from 'clsx';

function Badge({ children, className }) {
  return (
    <span
      className={clsx(
        'flex flex-row flex-wrap items-center px-1.5 min-w-5 h-5 place-content-center text-xs font-black text-red-100 bg-red-500 rounded-full leading-none',
        className,
      )}
    >
      {children}
    </span>
  );
}

export default Badge;
