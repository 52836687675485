import React from 'react';
import PropTypes from 'prop-types';

function ModalActions({ children: childrenProp }) {
  const children = React.Children.map(childrenProp, (child) => {
    return React.cloneElement(child, { className: 'ml-3' });
  });
  return <div className="px-4 py-3 text-right">{children}</div>;
}

ModalActions.propTypes = {
  children: PropTypes.node,
};

export default ModalActions;
