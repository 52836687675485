import { useContext } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';

import NotFoundPage from '../pages/NotFound';

import EmployeeCall from './EmployeeCall';
import ParkingCall from './ParkingCall';
import InstantCall from './InstantCall';
import BirthdayCall from './BirthdayCall';
import VirtualVoiceCall from './VirtualVoiceCall';
import ClassCall from './ClassCall';
import BusCall from './BusCall';

import FavoriteSongs from './FavoriteSongs';
import SongLocks from './SongLocks';
import AudioManager from './AudioManager';
import Content from './Content';
import PlaylistSequence from './PlaylistSequence';
import Events from './Events';

import Songs from './Songs';
import History from './History';
import Messages from './Messages';

import About from './About';

import ModuleAuth from './ModuleAuth';
import RadioContext from '../RadioContext';
import usePageRoute from '../../hooks/usePageRoute';

function PrivateModuleRoute({ children, ...rest }) {
  const { modulePath } = usePageRoute();
  const { isModuleEnabled } = useContext(RadioContext);
  return (
    <Route {...rest}>
      {isModuleEnabled(modulePath) ? children : <NotFoundPage />}
    </Route>
  );
}

function ModuleRoutes({ path, children }) {
  const location = useLocation();
  const transitions = useTransition(location, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 200 },
  });

  return transitions((styles, item) => (
    <animated.div className="w-full h-full" style={styles}>
      <Switch location={item}>
        <Route path={path} exact>
          {children}
        </Route>
        <PrivateModuleRoute path={`${path}/chamadas/funcionarios`}>
          <EmployeeCall />
        </PrivateModuleRoute>
        <PrivateModuleRoute path={`${path}/chamadas/aniversariantes`}>
          <BirthdayCall />
        </PrivateModuleRoute>
        <PrivateModuleRoute path={`${path}/chamadas/instantaneas`}>
          <InstantCall />
        </PrivateModuleRoute>
        <PrivateModuleRoute path={`${path}/chamadas/estacionamento`}>
          <ParkingCall />
        </PrivateModuleRoute>
        <PrivateModuleRoute path={`${path}/chamadas/locutor-virtual`}>
          <VirtualVoiceCall />
        </PrivateModuleRoute>
        <PrivateModuleRoute path={`${path}/chamadas/aviso-aula`}>
          <ClassCall />
        </PrivateModuleRoute>
        <PrivateModuleRoute path={`${path}/chamadas/aviso-onibus`}>
          <BusCall />
        </PrivateModuleRoute>
        <PrivateModuleRoute path={`${path}/configuracoes/musicas-favoritas`}>
          <ModuleAuth>
            <FavoriteSongs />
          </ModuleAuth>
        </PrivateModuleRoute>
        <PrivateModuleRoute path={`${path}/configuracoes/bloqueio-musicas`}>
          <ModuleAuth>
            <SongLocks />
          </ModuleAuth>
        </PrivateModuleRoute>
        <PrivateModuleRoute path={`${path}/configuracoes/gerenciar-audios`}>
          <ModuleAuth>
            <AudioManager />
          </ModuleAuth>
        </PrivateModuleRoute>
        <PrivateModuleRoute path={`${path}/configuracoes/conteudos`}>
          <ModuleAuth>
            <Content />
          </ModuleAuth>
        </PrivateModuleRoute>
        <PrivateModuleRoute path={`${path}/configuracoes/playlist-programacao`}>
          <ModuleAuth>
            <PlaylistSequence />
          </ModuleAuth>
        </PrivateModuleRoute>
        <PrivateModuleRoute path={`${path}/configuracoes/eventos`}>
          <ModuleAuth>
            <Events />
          </ModuleAuth>
        </PrivateModuleRoute>
        <PrivateModuleRoute path={`${path}/musicas`}>
          <ModuleAuth>
            <Songs />
          </ModuleAuth>
        </PrivateModuleRoute>
        <PrivateModuleRoute path={`${path}/historico`}>
          <History />
        </PrivateModuleRoute>
        <PrivateModuleRoute path={`${path}/mensagens`}>
          <Messages />
        </PrivateModuleRoute>
        <Route path={`${path}/sobre`}>
          <About />
        </Route>
        <PrivateModuleRoute path="*">
          <NotFoundPage />
        </PrivateModuleRoute>
      </Switch>
    </animated.div>
  ));
}

export default ModuleRoutes;
