import clsx from 'clsx';

export function List({ children }) {
  return <div>{children}</div>;
}

export function ListItem({ children, ...rest }) {
  return (
    <div
      className="flex items-center justify-between p-4 border-b md:px-4 md:py-4 last:border-b-0 border-grayAlt hover:bg-gray-2 transition-colors"
      {...rest}
    >
      {children}
    </div>
  );
}

export function ListItemText({ children, primary, secondary, className }) {
  return (
    <div className={clsx('flex-1', className)}>
      {children ? (
        children
      ) : (
        <>
          <span className="block">{primary}</span>
          {secondary && (
            <span className="block text-gray-400">{secondary}</span>
          )}
        </>
      )}
    </div>
  );
}

export function ListItemActions({ children }) {
  return <div>{children}</div>;
}
