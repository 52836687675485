import PropTypes from 'prop-types';

function FormField({ children, noMargin }) {
  return <div className={noMargin ? null : 'mb-5'}>{children}</div>;
}

FormField.propTypes = {
  children: PropTypes.node,
};

export default FormField;
