import { useState, useEffect, useContext } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import InputField from '../common/InputField';
import Button from '../common/Button';
import Alert from '../common/Alert';
import Snackbar from '../common/Snackbar';

import RadioContext from '../RadioContext';

import { parseRequestError } from '../../utils/errors';

import * as apiClient from '../../apiClient';

function ModuleAuth({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [actionMessage, setActionMessage] = useState(null);

  const {
    settings: { requireModuleAuth },
    loadSettings,
  } = useContext(RadioContext);

  useEffect(() => {
    setIsAuthenticated(false);
    loadSettings();
  }, [loadSettings]);

  if (!requireModuleAuth || isAuthenticated) return children;

  const handleCloseSnackbar = () => {
    setActionMessage(null);
  };

  const handleSubmit = async (values, { setErrors }) => {
    setActionMessage(null);

    try {
      await apiClient.signinModule(values);
      setIsAuthenticated(true);
    } catch (error) {
      setIsAuthenticated(false);
      const [errorMessage, errorDetails] = parseRequestError(error);
      if (errorDetails) {
        setErrors(errorDetails);
      } else {
        setActionMessage({
          type: 'error',
          message: errorMessage,
        });
      }
    }
  };

  return (
    <div className="flex items-center justify-center w-full h-full p-4">
      {actionMessage && (
        <Snackbar open onClose={handleCloseSnackbar}>
          <Alert type={actionMessage.type} className="shadow-md">
            {actionMessage.message}
          </Alert>
        </Snackbar>
      )}
      <Formik
        initialValues={{ password: '' }}
        validationSchema={Yup.object({
          password: Yup.string().required('Informe a senha secundária.'),
        })}
        onSubmit={handleSubmit}
      >
        <Form noValidate autoComplete="off">
          <h2 className="text-xl text-center">
            Este conteúdo é <b>restrito</b>. Para acessá-lo, digite a senha
            secundária.
          </h2>
          <div className="mx-auto mt-5 md:w-2/3">
            <InputField
              name="password"
              type="password"
              placeholder="Digite a senha secundária"
            />
            <div className="mb-5 text-center">
              <Button type="submit" size="large" color="primary" fullWidth>
                Acessar
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default ModuleAuth;
