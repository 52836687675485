import { useEffect } from 'react';

function ErrorFallback({ error }) {
  console.log('Error boundary', error);

  useEffect(() => {
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  }, []);

  return (
    <div className="flex items-center justify-center h-full px-4 py-12 sm:px-6 lg:px-8">
      <div className="text-4xl text-center">
        <div className="mb-4 font-bold">:/</div>
        <p>Descupe! Ocorreu um erro inesperado.</p>
      </div>
    </div>
  );
}

export default ErrorFallback;
