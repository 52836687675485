import { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { format, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import PageHeader from '../common/PageHeader';
import Label from '../common/Label';
import Button from '../common/Button';
import Alert from '../common/Alert';
import DateField from '../common/DateField';
import Spinner from '../common/Spinner';
import { List, ListItem, ListItemText } from '../common/List';

import { parseRequestError } from '../../utils/errors';
import { toDate } from '../../utils/datetime';
import { toTitleCase } from '../../utils/string';
import YupCustom from '../../utils/validations';

import * as apiClient from '../../apiClient';

function formatLogDate(date) {
  const dt = toDate(date, { withTime: false, format: 'yyyy-MM-dd' });
  const options = { locale: ptBR };

  const weekDay = toTitleCase(format(dt, 'EEEE', options));
  const monthDay = format(dt, 'd');
  const month = toTitleCase(format(dt, 'MMMM', options));
  const year = format(dt, 'yyyy');

  return `${weekDay}, ${monthDay} de ${month} de ${year}`;
}

function History() {
  const [history, setHistory] = useState(null);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const [exportReportError, setExportReportError] = useState(null);
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);

  const actionError = submitError || exportReportError;
  const isLoading = isLoadingHistory || isGeneratingReport;
  const disabledDays = {
    before: subDays(new Date(), 60),
    after: new Date(),
  };

  const handleSubmit = async (values, { setErrors }) => {
    setSubmitError(null);
    setIsLoadingHistory(true);
    setHistory([]);

    try {
      const result = await apiClient.getHistory(values);
      setHistory(result.items);
    } catch (error) {
      const [errorMessage, errorDetails] = parseRequestError(error);
      if (errorDetails) {
        setErrors(errorDetails);
      } else {
        setSubmitError(errorMessage);
      }
    } finally {
      setIsLoadingHistory(false);
    }
  };

  const handleExportReport = (values) => async () => {
    setExportReportError(null);
    setIsGeneratingReport(true);

    try {
      await apiClient.downloadHistoryReport(values);
    } catch (error) {
      const [errorMessage] = parseRequestError(error);
      setExportReportError(errorMessage);
    } finally {
      setIsGeneratingReport(false);
    }
  };

  return (
    <div>
      <PageHeader title="Histórico" />
      <div className="p-6">
        <div className="max-w-2xl mx-auto">
          <Formik
            initialValues={{
              dateStart: '',
              dateEnd: '',
            }}
            validationSchema={Yup.object({
              dateStart: YupCustom.date().required('Campo obrigatório.'),
              dateEnd: YupCustom.date()
                .required('Campo obrigatório.')
                .min(
                  Yup.ref('dateStart'),
                  'Data de fim deve ser maior que data de início.',
                ),
            })}
            onSubmit={handleSubmit}
          >
            {({ values }) => (
              <Form noValidate autoComplete="off">
                <div className="mb-5">
                  <Label htmlFor="dateStart">Período</Label>
                  <DateField
                    name="dateStart"
                    placeholder="De"
                    disabledDays={disabledDays}
                  />
                  <DateField
                    name="dateEnd"
                    placeholder="Até"
                    disabledDays={disabledDays}
                  />
                </div>
                <div className="mb-5">
                  {actionError ? (
                    <Alert type="error">{actionError}</Alert>
                  ) : null}
                </div>
                <div className="flex items-center justify-end mb-5">
                  {history && history.length > 0 && (
                    <div className="mr-2">
                      <Button
                        color="transparent"
                        onClick={handleExportReport(values)}
                      >
                        Gerar relatório
                      </Button>
                    </div>
                  )}
                  <div>
                    <Button type="submit" color="primary">
                      Buscar
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="max-w-5xl pt-6 mx-auto">
          {isLoading ? (
            <div className="mb-8 text-center">
              <Spinner className="inline-block w-8" />
            </div>
          ) : (
            history &&
            history.length === 0 && (
              <p>Nenhum registro encontrado para o período informado.</p>
            )
          )}
          {history &&
            history.map(({ date, logs }) => {
              return (
                <div key={date}>
                  <h3 className="px-4 text-lg font-bold">
                    {formatLogDate(date)}
                  </h3>
                  <List>
                    {logs.map((log) => {
                      return (
                        <ListItem key={log.id}>
                          <ListItemText>{log.title}</ListItemText>
                        </ListItem>
                      );
                    })}
                  </List>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default History;
