import { useState, useEffect, useCallback } from 'react';
import { useDebounce } from 'use-debounce';

import * as apiClient from '../../../apiClient';

function useSongSearch() {
  const perPage = 20;
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);
  const [isSearching, setIsSearching] = useState(false);

  const [page, setPage] = useState(1);
  const [hasNext, setHasNext] = useState(false);

  const [results, setResults] = useState(null);

  const searchSongs = async (search) => {
    setIsSearching(true);
    const response = await apiClient.searchAudios({
      search,
      page: 1,
      perPage,
    });

    setPage(1);
    setResults(response.items);
    setHasNext(response.hasNext);
    setIsSearching(false);
  };

  const paginate = useCallback(async () => {
    if (!hasNext) return;
    const newPage = page + 1;

    const response = await apiClient.searchAudios({
      search,
      page: newPage,
      perPage,
    });

    setPage(newPage);
    setHasNext(response.hasNext);
    setResults((currentItems) => [...currentItems, ...response.items]);
  }, [hasNext, page, search]);

  useEffect(() => {
    if (debouncedSearch) {
      searchSongs(debouncedSearch);
    } else {
      setResults(null);
      setHasNext(false);
      setIsSearching(false);
    }
  }, [debouncedSearch]);

  const onChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const onClearSearch = () => {
    setSearch('');
    setPage(1);
    setResults(null);
    setHasNext(false);
    setIsSearching(false);
  };

  return {
    onChangeSearch,
    onClearSearch,
    search,
    isSearching,
    results,
    paginate,
    hasNext,
  };
}

export default useSongSearch;
