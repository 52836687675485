import PropTypes from 'prop-types';
import clsx from 'clsx';

function Alert({ children, type = 'info', className }) {
  return (
    <div
      className={clsx(
        'p-3 rounded text-white',
        {
          'bg-red-600': type === 'error',
          'bg-blue-600': type === 'info',
          'bg-green-600': type === 'success',
          'bg-yellow-600': type === 'warn',
        },
        className,
      )}
    >
      {children}
    </div>
  );
}

Alert.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(['error', 'info', 'success', 'warn']),
  className: PropTypes.string,
};

export default Alert;
