import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { RadioContextProvider } from '../../RadioContext';
import { PlayerContextProvider, useAudioPlayer } from './Player.context';
import { PLAYER_ERRORS, PLAYER_STATUSES } from './Player.constants';

import Overlay from '../../common/Overlay';
import Spinner from '../../common/Spinner';
import Button from '../../common/Button';
import Link from '../../common/Link';
import MainMenu from '../../common/MainMenu';
import Offline from '../../common/Offline';

import ModuleRoutes from '../../modules/ModuleRoutes';

import Home from './Home';
import PlayerBar from './PlayerBar';

function PlayerPage() {
  return (
    <RadioContextProvider>
      <PlayerContextProvider>
        <Player />
      </PlayerContextProvider>
    </RadioContextProvider>
  );
}

function Player() {
  const { path } = useRouteMatch();
  const { online, playerState, clearSession, reConnect, signout, playMain } =
    useAudioPlayer();

  useEffect(() => {
    console.time('player:start');
  }, []);

  if (playerState.error === PLAYER_ERRORS.CONCURRENT_SESSION) {
    return (
      <Overlay open>
        <div className="text-center">
          <h1 className="font-bold text-3xl lg:text-4.5xl">
            A sua rádio está aberta em uma outra janela
          </h1>
          <div className="mt-10">
            <Button color="primary" onClick={clearSession}>
              Abrir nesta janela
            </Button>
          </div>
          <div className="mt-8">
            <Link
              size="sm"
              onClick={(event) => {
                event.preventDefault();
                signout();
              }}
            >
              Voltar ao login
            </Link>
          </div>
        </div>
      </Overlay>
    );
  }

  if (playerState.error === PLAYER_ERRORS.EMPTY_PLAYLIST) {
    return (
      <Overlay open>
        <div className="text-center">
          <h1 className="font-bold text-3xl lg:text-4.5xl">
            Nenhuma playslist configurada para esta rádio.
          </h1>
          <div className="mt-8">
            <Link
              size="sm"
              onClick={(event) => {
                event.preventDefault();
                signout();
              }}
            >
              Voltar ao login
            </Link>
          </div>
        </div>
      </Overlay>
    );
  }

  if (playerState.error === PLAYER_ERRORS.DISCONNECTED) {
    return (
      <Overlay open>
        <div className="text-center">
          <h1 className="font-bold text-4xl lg:text-4.5xl">
            Sua rádio foi desligada
          </h1>
          <p className="mt-2 text-lg">Clique no botão abaixo para reiniciar.</p>
          <div className="mt-10 text-lg">
            <Button color="primary" onClick={reConnect}>
              Reiniciar
            </Button>
          </div>
        </div>
      </Overlay>
    );
  }

  if (!playerState.ready) {
    return (
      <Overlay open>
        <div className="text-center">
          <Spinner className="inline-block w-8" />
          <div className="mt-5">Iniciando player...</div>
        </div>
      </Overlay>
    );
  }

  return (
    <div className="w-full h-full max-w-full overflow-hidden bg-graySecondary">
      {playerState.mainBlocked && (
        <Overlay open>
          <div className="text-center">
            <h1 className="mb-5 text-2xl font-bold">
              Clique no botão abaixo para iniciar o player!
            </h1>
            <Button color="primary" onClick={playMain}>
              Ok
            </Button>
          </div>
        </Overlay>
      )}

      {!online && <Offline />}

      {playerState.status === PLAYER_STATUSES.RECOVERY_MODE && (
        <Overlay open>
          <div className="text-center">
            <Spinner className="inline-block w-8" />
            <div className="mt-5">
              Recuperando sistema. Aguarde um momento...
            </div>
            <div className="mt-10">
              <Button
                color="primary"
                onClick={(event) => {
                  event.preventDefault();
                  signout();
                }}
              >
                Reiniciar sessão
              </Button>
            </div>
          </div>
        </Overlay>
      )}

      <div className="flex flex-wrap w-full h-full pb-36 md:pb-24">
        <aside className="h-full">
          <MainMenu />
        </aside>
        <main className="relative flex flex-col flex-1 h-full overflow-hidden">
          <div className="h-full max-h-full overflow-y-auto">
            <ModuleRoutes path={path}>
              <Home />
            </ModuleRoutes>
          </div>
        </main>
      </div>

      <PlayerBar />
    </div>
  );
}

export default PlayerPage;
