import { useState, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';

import PageHeader from '../common/PageHeader';
import Alert from '../common/Alert';
import Snackbar from '../common/Snackbar';
import IconButton from '../common/IconButton';
import { List, ListItem, ListItemActions, ListItemText } from '../common/List';

import VolumeIcon from '../../icons/VolumeUp';

import * as apiClient from '../../apiClient';

function InstantCall() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [files, setFiles] = useState([]);
  const [submitError, setSubmitError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(null);

  const redirectToHome = () => {
    history.push(`/${path.split('/')[1]}`);
  };

  useEffect(() => {
    const loadFiles = async () => {
      const response = await apiClient.getInstantFiles();
      setFiles(response.items);
    };

    loadFiles();
  }, []);

  const handleClick = (file) => async () => {
    setSubmitError(null);

    try {
      await apiClient.realtimeInstantCall({ file: file.id });
      setSubmitSuccess('Chamada enviada com sucesso.');
      redirectToHome();
    } catch (error) {
      setSubmitError(error.message);
    }
  };

  const handleCloseSnackbar = () => {
    setSubmitSuccess(null);
  };

  return (
    <div>
      <PageHeader title="Instantâneas" />
      <div className="p-6 md:px-10 md:pb-10 md:pt-0">
        <Snackbar open={Boolean(submitSuccess)} onClose={handleCloseSnackbar}>
          <Alert type="success" className="shadow-md">
            {submitSuccess}
          </Alert>
        </Snackbar>
        <Snackbar open={Boolean(submitError)} onClose={handleCloseSnackbar}>
          <Alert type="error" className="shadow-md">
            {submitError}
          </Alert>
        </Snackbar>

        <div className="flex flex-col justify-between pb-5 border-b-2 md:flex-row md:items-center border-grayAlt">
          <div className="flex-1 mb-5 md:mb-0">
            <h2 className="text-2xl font-semibold">Suas mídias instantâneas</h2>
            <p className="text-gray-400">{files.length} disponíveis</p>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto">
          {files.length === 0 ? (
            <p className="py-4">Nenhum áudio adicionado.</p>
          ) : (
            <List>
              {files.map((file) => {
                return (
                  <ListItem key={file.id} title={file.file}>
                    <ListItemText primary={file.fileName} />
                    <ListItemActions>
                      <IconButton
                        onClick={handleClick(file)}
                        title="Acionar áudio"
                      >
                        <VolumeIcon />
                      </IconButton>
                    </ListItemActions>
                  </ListItem>
                );
              })}
            </List>
          )}
        </div>
      </div>
    </div>
  );
}

export default InstantCall;
