import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import * as infoHealth from './infoHealth';
import reportWebVitals from './reportWebVitals';

window.APP_VERSION =
  process.env.NODE_ENV === 'production' ? process.env.REACT_APP_VERSION : 'dev';
window.APP_SERVER =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_SERVER
    : 'local';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

infoHealth.start();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
