import { useEffect } from 'react';
import HomeHeader from '../../common/HomeHeader';
import Spinner from '../../common/Spinner';
import SearchInput from '../../common/SearchInput';
import {
  List,
  ListItem,
  ListItemActions,
  ListItemText,
} from '../../common/List';
import IconButton from '../../common/IconButton';

import LikeIcon from '../../../icons/Like';
import LikeFillIcon from '../../../icons/LikeFill';

import { getSongMeta } from '../../../utils/songFile';
import useSongSearch from './useSongSearch';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import { useAuth } from '../../../hooks/useAuth';
import useSongRequest from './useSongRequest';

import bgPlayerDefault from '../../../images/bg-player-default.jpg';

function SongRequestPage() {
  const { user } = useAuth();

  useEffect(() => {
    document.title = `${user.name} - Pedido Musical`;
  }, [user]);

  return (
    <div
      className="w-full h-full bg-center bg-no-repeat bg-cover bg-clip-border"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.6) 100%), url(${bgPlayerDefault})`,
      }}
    >
      <HomeHeader showLogo rootPath="/pedidomusical" />
      <div className="flex items-center justify-center h-full">
        <div className="w-full max-w-3xl p-4 m-2 rounded-lg shadow-xl bg-gray-2 md:p-10 md:m-0">
          <SongRequest />
        </div>
      </div>
    </div>
  );
}

function SongRequest() {
  const {
    search,
    onChangeSearch,
    onClearSearch,
    isSearching,
    results,
    hasNext,
    paginate,
  } = useSongSearch();

  const showResults = isSearching || results;

  const scrollRef = useIntersectionObserver({
    onIntersect: paginate,
  });

  return (
    <section>
      <div className="text-center">
        <h1 className="text-2xl font-medium">Faça o seu pedido musical</h1>
        <h2 className="text-sm text-gray-200">
          Digite o nome do artista ou música que deseja ouvir
        </h2>
      </div>
      <div className="mt-6">
        <SearchInput
          id="search"
          name="search"
          placeholder="Buscar música..."
          value={search}
          onChange={onChangeSearch}
          onClear={onClearSearch}
        />
      </div>
      <div className="mt-6 overflow-y-auto h-96">
        {showResults ? (
          <SearchResults isSearching={isSearching} results={results} />
        ) : (
          <LatestRequests />
        )}
        {hasNext && (
          <div ref={scrollRef} className="flex justify-center">
            <Spinner className="w-6 my-6" />
          </div>
        )}
      </div>
    </section>
  );
}

function SearchResults({ results, isSearching }) {
  const { getSongRequest, addSongRequest, removeSongRequest } =
    useSongRequest();

  if (isSearching) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <Spinner className="w-8 mb-2" />
        <p className="text-sm text-gray-400">Buscando resultados...</p>
      </div>
    );
  } else if (results.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <p className="text-gray-400">Nenhum resultado encontrado.</p>
      </div>
    );
  }

  return (
    <List>
      {results.map((song) => {
        const { category, title, artist } = getSongMeta(song);
        const secondaryText = artist ? `${artist} • ${category}` : category;
        const songRequest = getSongRequest(song);

        return (
          <ListItem key={`search-result-${song.id}`} title={song.fileName}>
            <ListItemText primary={title} secondary={secondaryText} />
            <ListItemActions>
              {songRequest ? (
                <IconButton
                  title="Remover pedido"
                  onClick={removeSongRequest(songRequest)}
                  color="primary"
                >
                  <LikeFillIcon />
                </IconButton>
              ) : (
                <IconButton title="Pedir música" onClick={addSongRequest(song)}>
                  <LikeIcon />
                </IconButton>
              )}
            </ListItemActions>
          </ListItem>
        );
      })}
    </List>
  );
}

function LatestRequests() {
  const {
    latestSongRequests,
    getSongRequest,
    addSongRequest,
    removeSongRequest,
  } = useSongRequest();

  if (latestSongRequests.length === 0) {
    return null;
  }

  return (
    <div className="mt-8">
      <p className="mb-2 ml-4 text-sm text-gray-200">Últimos pedidos</p>
      <List>
        {latestSongRequests.map((song) => {
          const { category, title, artist } = getSongMeta(song);
          const secondaryText = artist ? `${artist} • ${category}` : category;
          const songRequest = getSongRequest(song);

          return (
            <ListItem key={`latest-requests-${song.id}`} title={song.fileName}>
              <ListItemText primary={title} secondary={secondaryText} />
              <ListItemActions>
                {songRequest ? (
                  <IconButton
                    title="Remover pedido"
                    onClick={removeSongRequest(songRequest)}
                    color="primary"
                  >
                    <LikeFillIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    title="Pedir música"
                    onClick={addSongRequest(song)}
                  >
                    <LikeIcon />
                  </IconButton>
                )}
              </ListItemActions>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}

export default SongRequestPage;
